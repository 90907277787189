import { AnyAction } from 'redux';
import { LobbyActionTypes } from '../actionTypes';
import {
  GameCategoryData,
  GameItemsList,
  LobbyComponentType,
  LobbyState,
  LobbyComponent,
} from '../../interfaces';
import { gamesReducer } from '../../utils';

const initialState: LobbyState = {
  code: '',
  components: [],
  expandedComponentId: '',
  expandedFromWidgetbar: false,
  detailedGame: null,
  detailedGameInfo: null,
  expandedCategoryGames: null,
  gameImageSrc: '',
  favoriteGames: [],
  customGameImages: null,
  translations: {
    updated: 0,
    content: undefined,
  },
};

const getLobbyCategory = (state: LobbyState, categoryCode: string): LobbyComponent | null => (
  state.components
    .find((comp: LobbyComponent) => comp.type === LobbyComponentType.GameCategory
      && comp.code === categoryCode) || null
);

const getExtendedCategoryGames = (state: LobbyState, catCode: string): GameCategoryData | null => {
  if (catCode) {
    const categoryComponent = getLobbyCategory(state, catCode);
    if (categoryComponent) {
      return {
        sourceType: categoryComponent.data!.sourceType,
        code: categoryComponent.data!.code,
        games: {
          items: categoryComponent.data!.games.items.slice(0, 24),
          paging: {
            page: 1,
            pageSize: 24,
            totalElements: categoryComponent.data!.games.paging.totalElements,
            totalPages: Math.ceil(categoryComponent.data!.games.paging.totalElements / 24),
          },
        },
      };
    }
  }
  return null;
};

const getCategoryGames = (category: GameCategoryData | null | undefined): GameItemsList | null => (
  category?.games || null
);

/**
 * Updates lobby store values. In case of an event:
 * - SET_DETAILED_GAME: opens game details for specified game. Player can see details only for one
 * single game. Details data is cleared immediately. Otherwise previous game details will be visible
 * until new game details data arrives from server.
 * - SET_EXPANDED_CATEGORY: opens/closes expanded category. This action closes game details
 * automatically. If the category exists in main lobby then the first 24 games are are taken from
 * there to avoid unnecessary http fetch request.
 * - GET_LOBBY_CATEGORY_GAMES_SUCCESS: updates a single lobby category, used mostly for updating
 * 'recent' or 'favorite' categories after exiting a game.
 * @param state current state of lobby data
 * @param action lobby action
 */
export default (
  state = initialState,
  { type, payload }: AnyAction = { type: LobbyActionTypes.DEFAULT },
) => {
  switch (type) {
    case LobbyActionTypes.GET_LOBBY_CONFIGURATION_SUCCESS: {
      if (JSON.stringify(state.components) === JSON.stringify(payload.components)) {
        return state;
      }
      return {
        ...state,
        ...payload,
      };
    }
    case LobbyActionTypes.SET_EXPANDED_CATEGORY: {
      if (state.expandedComponentId === payload.categoryCode) { // double click on widgetbar
        return state;
      }
      return {
        ...state,
        expandedCategoryGames: getExtendedCategoryGames(state, payload.categoryCode),
        expandedComponentId: payload.categoryCode,
        expandedFromWidgetbar: payload.fromWB,
        detailedGameInfo: null,
        detailedGame: null,
      };
    }
    case LobbyActionTypes.SET_DETAILED_GAME:
      return {
        ...state,
        detailedGame: payload,
        detailedGameInfo: null,
      };
    case LobbyActionTypes.GET_GAME_DETAILS_SUCCESS:
      return {
        ...state,
        detailedGameInfo: payload,
      };
    case LobbyActionTypes.GET_EXPANDED_CATEGORY_GAMES_SUCCESS: {
      const previousGames = getCategoryGames(state.expandedCategoryGames);
      const updatedGamesList = gamesReducer(previousGames, payload.games);
      if (updatedGamesList && state.expandedComponentId === payload.code) {
        return {
          ...state,
          expandedCategoryGames: {
            ...payload,
            games: updatedGamesList,
          },
        };
      }
      return state;
    }
    case LobbyActionTypes.GET_LOBBY_CATEGORY_GAMES_SUCCESS: {
      const singleCategory = getLobbyCategory(state, payload.code);
      const previousGames = getCategoryGames(singleCategory?.data);
      const updatedGamesList = gamesReducer(previousGames, payload.games);
      if (singleCategory && updatedGamesList) {
        // @ts-ignore
        singleCategory.data.games = updatedGamesList;
        return {
          ...state,
          components: [...state.components],
        };
      }
      return state;
    }
    case LobbyActionTypes.SET_GAME_IMAGE_SRC: {
      return {
        ...state,
        gameImageSrc: payload,
      };
    }
    case LobbyActionTypes.SET_CUSTOM_GAME_IMAGES: {
      return {
        ...state,
        customGameImages: payload,
      };
    }
    case LobbyActionTypes.SET_FAVORITE_GAMES:
      return {
        ...state,
        favoriteGames: payload,
      };
    case LobbyActionTypes.UPDATE_TRANSLATIONS: {
      return {
        ...state,
        translations: {
          ...state.translations,
          updated: state.translations.updated + 1,
        },
      };
    }
    case LobbyActionTypes.SET_LOBBY_TRANSLATIONS: {
      return {
        ...state,
        translations: {
          ...state.translations,
          content: payload,
        },
      };
    }
    case LobbyActionTypes.ADD_MISSING_TRANSLATION_VALUE: {
      return {
        ...state,
        translations: {
          ...state.translations,
          content: {
            ...state.translations.content,
            [payload]: '',
          },
        },
      };
    }
    default:
      return state;
  }
};
