import { AxiosError } from 'axios';
import ErrorLogger from './ErrorLogger';

class CategoryDoesNotExistError extends ErrorLogger {
  cause: unknown;

  constructor(cause?: AxiosError) {
    super('category games doesn\'t exist', cause);
    this.cause = cause;
  }
}

export default CategoryDoesNotExistError;
