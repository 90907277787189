import React from 'react';
import { Provider } from 'react-redux';
import { InitArgs } from './interfaces';
import store from './store';
import App from './App';

function Module({ initialState, lobbyId }: InitArgs) {
  return (
    <Provider store={store(initialState)}>
      <App lobbyId={lobbyId!} />
    </Provider>
  );
}

export default Module;
