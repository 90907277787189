import React, { useCallback } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { lobbyActions } from '../../store/actions';
import { colors, paddings } from '../../constants/style-variables';
import { logger } from '../../services';
import { Translations } from '../../constants/translations';
import useFallbackTranslation from '../../hooks/useFallbackTranslation';

interface CategoryHeaderProperties {
  className?: string;
  headerKey: string;
  total: number;
  pageSize: number;
  isRecentOrFavoritesCategory: boolean;
}

function CategoryHeaderComponent({
  className, headerKey, total, pageSize, isRecentOrFavoritesCategory,
}: CategoryHeaderProperties) {
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    logger.log(`User expanded a specific category: ${headerKey}`);
    dispatch(lobbyActions.setExpandedCategory(headerKey));
  }, [dispatch, headerKey]);

  const headerId = useFallbackTranslation(headerKey);

  return (
    <ClickWrapper className={className} onClick={handleClick}>
      <HeaderText className={isRecentOrFavoritesCategory ? 'ribbon-my-games' : ''}>
        <FormattedMessage id={headerId} />
      </HeaderText>
      {total > pageSize && (
        <MoreGames className="category-header-more-games">
          {total}
          {' '}
          <FormattedMessage id={Translations.EXPAND_MORE_GAMES} />
        </MoreGames>
      )}
    </ClickWrapper>
  );
}

CategoryHeaderComponent.defaultProps = {
  className: undefined,
};

const ClickWrapper = styled.div`
  cursor: pointer;
`;

const MoreGames = styled.div`
  padding: 2px 4px 0 4px;
  border-radius: 4px;
  border: 1px solid ${colors.blue};
  font-size: 10px;
  line-height: 13px;
  display: flex;
  color: ${colors.blue};
  text-transform: uppercase;
`;

const HeaderText = styled.div`
  margin-right: 4px;
  &.ribbon-my-games {
    margin-right: 14px;
    padding-left: ${paddings.pagePadding}px;
    margin-left: -${paddings.pagePadding}px;
    position: relative;
    background: ${colors.orange};
    color: ${colors.white};
    padding-right: 10px;
    height: 25px;
    display: flex;
    align-items: center;
  }

  &.ribbon-my-games:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    right: -10px;
    top: 0;
    border-width: 10px 7px;
    border-style: solid;
    border-color: ${colors.orange} transparent transparent ${colors.orange};
  }

  &.ribbon-my-games:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    right: -10px;
    bottom: 0;
    border-width: 10px 7px;
    border-style: solid;
    border-color:  transparent transparent ${colors.orange} ${colors.orange};
  }
`;

const CategoryHeader = styled(CategoryHeaderComponent)`
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  margin: 0px ${paddings.pagePadding}px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  color: ${colors.blue};

  @media (hover: hover) {
    :hover {
      color: ${colors.blueDarker};
    }
  }
`;

export default CategoryHeader;
