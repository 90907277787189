import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../interfaces';
import Description, { DescriptionProperties } from './Description';
import useFallbackTranslation from '../../../hooks/useFallbackTranslation';

interface CategoryDescriptionProperties extends Pick<DescriptionProperties, 'className'> {
  translationCode: string;
}

function CategoryDescription({ translationCode, className }: CategoryDescriptionProperties) {
  const translations = useSelector((state: RootState) => state.lobby.translations);
  const SUFFIX = 'DESCRIPTION';
  const translationId = useFallbackTranslation(`${translationCode}_${SUFFIX}`);

  const translationExists = () => {
    const { content } = translations;
    return content && translationId in content;
  };

  return translationExists() ? (
    <Description translationId={translationId} className={className} />
  ) : null;
}

export default CategoryDescription;
