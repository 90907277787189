import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Orientations } from '../interfaces';
import { applicationActions } from '../store/actions';
import debounce from '../utils/debounce';

let orientation = Orientations.Landscape;

export default () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = useCallback(debounce(() => {
    dispatch(applicationActions.setWidth(window.innerWidth));

    const temp = window.innerWidth > window.innerHeight
      ? Orientations.Landscape
      : Orientations.Portrait;
    if (temp !== orientation) {
      dispatch(applicationActions.setOrientation(temp));
      orientation = temp;
    }
  }, 50), []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);
};
