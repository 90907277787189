import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { colors, paddings } from '../../../constants/style-variables';
import { parseTags } from '../../../utils/customTags';

export interface DescriptionProperties {
  translationId: string;
  className?: string;
}

/**
 * Renders the decription of the category from a translation id
 *
 * @param translationId
 * @param className
 */
function Description({ translationId, className }: DescriptionProperties) {
  const { formatMessage } = useIntl();
  const description = formatMessage({ id: translationId });
  const tags = parseTags(description);

  return (
    <Wrapper className={className}>
      <Text>
        {tags.map((tag) => (tag.type === 'anchor' ? <a key={tag.id} href={tag.href}>{tag.content}</a> : <Fragment key={tag.id}>{tag.content}</Fragment>))}
      </Text>
    </Wrapper>
  );
}

Description.defaultProps = {
  className: undefined,
};

const Wrapper = styled.div`
  margin-left: ${paddings.pagePadding}px;
  color: ${colors.black};
`;

const Text = styled.div`
  font-size: 12px;
  line-height: 18px;
`;

export default Description;
