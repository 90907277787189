import { AuthActionTypes } from '../actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const setIsLoggedIn = (isLoggedIn: boolean) => ({
  type: AuthActionTypes.SET_IS_LOGGED_IN,
  payload: isLoggedIn,
});

export const setIsInternal = (isInternal: boolean) => ({
  type: AuthActionTypes.SET_IS_INTERNAL,
  payload: isInternal,
});
