import { AxiosError } from 'axios';
import { logger } from '../services';

class ErrorLogger extends Error {
  constructor(errorTextCode: string, cause?: AxiosError) {
    super(errorTextCode);

    logger.error(errorTextCode, { error: JSON.stringify(cause) });
  }
}

export default ErrorLogger;
