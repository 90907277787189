/* eslint-disable global-require */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import { RootState } from './interfaces';
import { applicationActions, lobbyActions } from './store/actions';
import GlobalStyle from './components/GlobalStyle';
import LobbyContainer from './components/LobbyContainer';
import useLocale from './hooks/useLocale';
import mobileTheme from './themes/mobile';
import desktopTheme from './themes/desktop';
import GlobalHooksContainer from './components/GlobalHooks';
import { DeviceType } from './interfaces/DeviceType';
import useConfigs from './hooks/useConfigs';
import { breakpoints } from './constants/style-variables';
import { useTranslations } from './hooks';
import translationsErrorHandler from './utils/translationsErrorHandler';
import useSoftHibernateMode from './hooks/useSoftHibernateMode';
import GameAssetsProvider from './components/rsi/Assets/GameAssetsProvider';

interface AppProps {
  lobbyId: string;
}

const getDeviceType = (): DeviceType => {
  if (RSIUtils.detector.isDesktop) {
    return DeviceType.Desktop;
  }
  const portraitWidth = Math.min(window.innerWidth, window.innerHeight);
  if (portraitWidth > (breakpoints['screen-xs-min'] + 1)) {
    return DeviceType.Tablet;
  }
  return DeviceType.Mobile;
};

function App({ lobbyId }: AppProps) {
  const dispatch = useDispatch();
  const deviceType = getDeviceType();
  const { locale } = useLocale();
  const { api } = useConfigs(['api']);
  const { activateSoftHibernateMode } = useSoftHibernateMode();
  const translations = useSelector((state: RootState) => state.lobby.translations.content);

  useTranslations();

  useEffect(() => {
    dispatch(applicationActions.setDeviceType(deviceType));
    dispatch(lobbyActions.setGameImageSrc('https://d1zyihka9e0i9f.cloudfront.net'));
  }, [dispatch, deviceType]);

  if (!locale || !api || !lobbyId || !translations) {
    return null;
  }

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={locale}
      messages={translations}
      fallbackOnEmptyString={false}
      onError={(err) => translationsErrorHandler(err, dispatch)}
    >
      <ThemeProvider theme={deviceType === DeviceType.Desktop ? desktopTheme : mobileTheme}>
        <GlobalHooksContainer lobbyId={lobbyId} />
        <GlobalStyle />
        <GameAssetsProvider>
          <LobbyContainer
            lobbyId={lobbyId}
            className={`lobby-container ${activateSoftHibernateMode ? 'use-soft-hibernate-mode' : ''}`}
          />
        </GameAssetsProvider>
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
