import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { RootState } from '../../../interfaces';
import { searchActions } from '../../../store/actions';
import { colors } from '../../../constants/style-variables';
import { useTypedDispatch } from '../../../store';
import ExpandedGameCategoryHeader from '../ExpandedGameCategory/ExpandedGameCategoryHeader';
import InfiniteScrollGames from '../InfiniteScrollGames';
import { Translations } from '../../../constants/translations';
import useFallbackTranslation from '../../../hooks/useFallbackTranslation';

interface SearchViewGamesProperties {
  className?: string;
}

function SearchViewGames({ className }: SearchViewGamesProperties) {
  const dispatch = useTypedDispatch();
  const clientType = useSelector((state: RootState) => state.application.clientType);
  const searchString = useSelector((state: RootState) => state.search.searchString);
  const searchVisible = useSelector((state: RootState) => state.search.searchVisible);

  const shouldUpdateGamesList = searchString.length !== 1;

  const onBackClick = useCallback(() => {
    dispatch(searchActions.setPreviousExpandedCategory(null));
    rsiApi.trigger(rsiApi.getEvent('CLOSE_WIDGET_BAR_SEARCH'));
  }, [dispatch]);

  const selectorHandler = useCallback((state: RootState) => state.search.games, []);

  const intl = useIntl();
  const searchHintMessageId = useFallbackTranslation(Translations.SEARCH_MIN_REQUIRES);

  const searchHintMessage = intl.formatMessage(
    { id: searchHintMessageId },
    {
      amount: 2,
    },
  );

  const fetchHandler = useCallback((page: number) => {
    if (clientType && shouldUpdateGamesList) {
      dispatch(searchActions.getSearchGames(searchString, clientType, page));
    }
  }, [dispatch, searchString, clientType, shouldUpdateGamesList]);

  if (!searchVisible) {
    return null;
  }

  if (!shouldUpdateGamesList) {
    return <SearchHint>{searchHintMessage}</SearchHint>;
  }

  return (
    <div className={className}>
      <ExpandedGameCategoryHeader translationCode={Translations.SEARCH} onBackClick={onBackClick} />
      <InfiniteScrollGames
        fetchHandler={fetchHandler}
        selectorHandler={selectorHandler}
        dataTestId="category-search-results"
        scrollToTop
      />
    </div>
  );
}

SearchViewGames.defaultProps = {
  className: undefined,
};

const SearchHint = styled.span`
  font-size: 16px;
  color: ${colors.gray4};
  font-weight: 500;
  text-align: center;
  display: block;
  margin-top: 15px;
`;

export default SearchViewGames;
