import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import useNavigation from '../../../hooks/useNavigation';
import { GameCategoryItem, GameItemsList, RootState } from '../../../interfaces';
import { lobbyActions } from '../../../store/actions';
import { KeyValuePair } from '../../../interfaces/KeyValuePair';
import GameItem from '../GameItem/GameItem';
import ItemClickAction from '../../../interfaces/GameItemClickAction';
import { DeviceType } from '../../../interfaces/DeviceType';
import { TileSize } from '../../../interfaces/TileSize';
import GameDetailsDesktop from '../GameDetailsView/Desktop/GameDetailsDesktop';
import { breakpoints } from '../../../constants/style-variables';
import { Translations } from '../../../constants/translations';
import useJackpotCategory from '../../../hooks/useJackpotCategory';
import { paddingXExpandedCategoryDesktop } from './ExpandedGameCategoryHeaderJackpot';
import doesIncludeRecentOrFavoriteSource from '../../../utils/doesIncludeRecentOrFavoriteSource';
import useConfigs from '../../../hooks/useConfigs';

interface ExpandedCategoryGamesContainerProperties {
  className?: string;
  expandedCategoryGames: GameItemsList | null,
  sourceType?: string;
  dataTestId?: string;
}

function ExpandedCategoryGamesContainerComponent({
  className,
  expandedCategoryGames,
  sourceType,
  dataTestId,
}:
  ExpandedCategoryGamesContainerProperties) {
  const expandedComponentId = useSelector((state: RootState) => state.lobby.expandedComponentId);
  const expandedFromWB = useSelector((state: RootState) => state.lobby.expandedFromWidgetbar);
  const deviceType = useSelector((state: RootState) => state.application.deviceType);
  const useFullscreenDetails = useSelector((state: RootState) => state.layout.useFullscreenDetails);
  const searchString = useSelector((state: RootState) => state.search.searchString);
  const detailedGameCategory = useSelector(
    (state: RootState) => state.lobby.detailedGame?.categoryId,
  );
  const { withJackpot } = useJackpotCategory(expandedComponentId);
  const isMobile = useSelector((state: RootState) => state.application.deviceType)
    === DeviceType.Mobile;
  const dispatch = useDispatch();
  const isRecentOrFavoritesCategory = doesIncludeRecentOrFavoriteSource(sourceType);

  const { launchGame, showGameDetailsModal } = useNavigation();
  const { casinoLobby } = useConfigs(['casinoLobby']);
  const setDetailedView = (item: GameCategoryItem, logData: KeyValuePair[]) => {
    dispatch(lobbyActions.setDetailedGame(item, logData, null));
  };
  const constructLogData = useCallback((gameIndex: number): KeyValuePair[] => [
    {
      key: 'index',
      value: gameIndex + 1,
    },
    {
      key: 'expanded category',
      value: expandedComponentId,
    },
    {
      key: 'expanded from WB',
      value: expandedFromWB,
    },
  ], [expandedComponentId, expandedFromWB]);

  const handleClick = (item: GameCategoryItem, action: ItemClickAction, index: number) => {
    const logData = constructLogData(index);
    if (action !== ItemClickAction.GAME_DETAILS && isRecentOrFavoritesCategory
      && expandedCategoryGames) {
      launchGame(item, 'REAL', logData);
    } else if (casinoLobby?.enableGameDetailsAsCards) {
      showGameDetailsModal(item, logData);
    } else {
      setDetailedView(item, logData);
    }
  };

  if (!expandedCategoryGames || !deviceType) {
    return null;
  }

  if (expandedCategoryGames.items.length === 0) {
    const setFormattedMessageId = () => {
      if (searchString) {
        return Translations.SEARCH_RESULT_EMPTY;
      }
      const expandedComponentPart = isRecentOrFavoritesCategory ? `_${expandedComponentId}` : '';
      return `${Translations.CATEGORY_EMPTY}${expandedComponentPart}`;
    };

    return (
      <NoGamesNotificationContainer data-test-id="no-games-notification">
        <FormattedMessage id={setFormattedMessageId()} />
      </NoGamesNotificationContainer>
    );
  }

  return (
    <ExpandedCategoryGamesWrapper
      className={className}
      data-test-id={dataTestId}
      withJackpotPadding={withJackpot && !isMobile && Boolean(expandedComponentId)}
    >
      <GridViewContainer>
        {expandedCategoryGames?.items.map((item, index) => (
          <>
            <GameItem
              key={item.code}
              item={item}
              size={TileSize.M}
              onClick={(action: ItemClickAction) => handleClick(item, action, index)}
              isMobileOneClick={isRecentOrFavoritesCategory}
              dataTestId={`game-item-${index}`}
              withJackpotItem={withJackpot}
            />
            {!useFullscreenDetails && !detailedGameCategory && (
              <ExpandedDetails game={item} />
            )}
          </>
        ))}
      </GridViewContainer>
    </ExpandedCategoryGamesWrapper>
  );
}

ExpandedCategoryGamesContainerComponent.defaultProps = {
  className: undefined,
  sourceType: undefined,
  dataTestId: 'expanded-category-games-container',
};

const ExpandedDetails = styled(GameDetailsDesktop)`
  grid-column: 1 / -1;
`;

const NoGamesNotificationContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 5% 10%;
`;

const GridViewContainer = styled.div`
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;

  .grid > * {
    align-items: flex-start;
    background: #eee;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  @media (min-width:${breakpoints['screen-xs-min'] + 1}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${breakpoints['screen-sm-min'] + 1}px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width:${breakpoints['screen-lg-min'] + 1}px) {
    grid-template-columns: repeat(6, 1fr);
  }
`;

/**
 * Top padding added because of ribbons that must slightly be outside the game tile area.
 */
const ExpandedCategoryGamesWrapper = styled.div.attrs((props: { withJackpotPadding: boolean; }) => props)`
  overflow: hidden;
  ${({ withJackpotPadding }) => `
    padding: ${withJackpotPadding
    ? `8px ${paddingXExpandedCategoryDesktop}px 0px ${paddingXExpandedCategoryDesktop}px`
    : '8px 16px 0px 16px'};
  `};
`;

export default ExpandedCategoryGamesContainerComponent;
