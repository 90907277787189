import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import ArrowLeftButton from '../../rsi/Button/ArrowLeftButton';
import { paddings } from '../../../constants/style-variables';
import useFallbackTranslation from '../../../hooks/useFallbackTranslation';

interface ExpandedGameCategoryHeaderProperties {
  className?: string;
  onBackClick: any;
  translationCode: string;
  dataTestId?: string;
}

function ExpandedGameCategoryHeaderComponent({
  className, translationCode, onBackClick, dataTestId,
}:
  ExpandedGameCategoryHeaderProperties) {
  const safeTranslationCode = useFallbackTranslation(translationCode);

  return (
    <div className={className} data-test-id={dataTestId}>
      <ArrowLeftButton className="back-button" onClick={onBackClick} name={`${dataTestId}-button-back`} />
      <div className="header-text" data-test-id={`${dataTestId}-text`}>
        <FormattedMessage id={safeTranslationCode} />
      </div>
    </div>
  );
}

ExpandedGameCategoryHeaderComponent.defaultProps = {
  className: undefined,
  dataTestId: 'expanded-game-category-header',
};

const ExpandedGameCategoryHeader = styled(ExpandedGameCategoryHeaderComponent)`
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 7px;
  padding-top: 12px;

  .back-button {
    position: absolute;
    left: 0;
    width: 54px;
  }

  .header-text {
    width: 100%;
    text-align: center;
  }

  .grid-selection-container {
    position: absolute;
    right: 0;
    margin-right: ${paddings.pagePadding}px;
  }
`;

export default ExpandedGameCategoryHeader;
