import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { colors } from '../../../constants/style-variables';
import { Translations } from '../../../constants/translations';

interface MoreGamesTileProperties {
  className?: string;
  dataTestId?: string;
  onClick?: VoidFunction;
  gamesNumber: string | number;
  withJackpot?: boolean;
}

function MoreGamesTileComponent({
  className, dataTestId, onClick, gamesNumber, withJackpot,
}: MoreGamesTileProperties) {
  return (
    <div className={className} data-test-id={dataTestId}>
      <ClickWrapper onClick={onClick}>
        <ContentWrapper withJackpot={withJackpot}>
          <Title withJackpot={withJackpot}>
            <FormattedMessage id={Translations.TILE_MORE_GAMES} />
          </Title>
          <Title withJackpot={withJackpot}>
            {`+${gamesNumber}`}
          </Title>
        </ContentWrapper>
      </ClickWrapper>
    </div>
  );
}

MoreGamesTileComponent.defaultProps = {
  className: undefined,
  dataTestId: undefined,
  onClick: undefined,
  withJackpot: false,
};

const Title = styled.p.attrs((props: { withJackpot: boolean }) => props)`
  ${({ withJackpot }) => `
    color: ${withJackpot ? colors.white : colors.lightBlue};
  `};
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
`;

const ClickWrapper = styled.div.attrs((props: { onClick?: VoidFunction }) => props)`
  height: 100%;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
`;

const jackpotBorderWidth = 3;
const jackpotBorderRadius = 5;

const ContentWrapper = styled.div.attrs((props: { withJackpot: boolean }) => props)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  ${({ withJackpot }) => !withJackpot && css`
    border-radius: 8px;
    border: 1px solid  ${colors.lightBlue};
  `};

  ${({ withJackpot }) => withJackpot && css`
    border-left: ${jackpotBorderWidth}px solid ${colors.gold};
    border-right: ${jackpotBorderWidth}px solid #0F27FB;
    background-image: linear-gradient(89.85deg, #FDB61B 19.65%, #0F27FB 75.65%), linear-gradient(89.85deg, #FDB61B 19.65%, #0F27FB 75.65%);
    background-size: 100% ${jackpotBorderWidth}px;
    background-position: 0 0, 0 100%;
    background-repeat: no-repeat;
    border-radius: ${jackpotBorderRadius}px;

    &:hover {
      & > p {
        font-size: 16px;
        line-height: 18px;
      }

      &:after {
        position: absolute;
        z-index: 0;
        border-left: ${jackpotBorderWidth}px solid rgb(253, 182, 27);
        border-right: ${jackpotBorderWidth}px solid rgb(15, 39, 251);
        background-image: linear-gradient(89.85deg, rgb(253, 182, 27) 19.65%, rgb(15, 39, 251) 75.65%), linear-gradient(89.85deg, rgb(253, 182, 27) 19.65%, rgb(15, 39, 251) 75.65%);
        background-size: 100% ${jackpotBorderWidth}px;
        background-position: 0px 0px, 0px 100%;
        background-repeat: no-repeat;
        border-radius: ${jackpotBorderRadius}px;
        filter: blur(6px);
        content: "";
        height: calc(100% + ${jackpotBorderWidth * 2}px);
        width: calc(100% + ${jackpotBorderWidth * 2}px);
        top: -${jackpotBorderWidth}px;
        left: -${jackpotBorderWidth}px;
        mix-blend-mode: screen;
      }
    }
  `};
`;

const MoreGamesTile = styled(MoreGamesTileComponent)`
  position: relative;
  height: 100%;
`;

export default MoreGamesTile;
