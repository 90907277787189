import { RECENT_AND_FAVORITES_SOURCES } from '../constants/category-variables';

const doesIncludeRecentOrFavoriteSource = (categoryCode?: string | null): boolean => {
  if (!categoryCode) {
    return false;
  }
  return RECENT_AND_FAVORITES_SOURCES.includes(categoryCode);
};

export default doesIncludeRecentOrFavoriteSource;
