/* eslint-disable react/jsx-no-useless-fragment */
import React, {
  createContext,
  ReactNode, useCallback, useEffect, useMemo, useState,
} from 'react';
import ArrowWrapper, { SlideButtonProperties } from '../Button/ArrowWrapper';
import useScrollEvent from '../../../hooks/useScrollEvent';

type ContextType = {
  scrollLeft: number,
  isInView: (elementRect?: DOMRect) => boolean,
}

export const HorizontalVirtualScrollContext = createContext<ContextType>({} as ContextType);

interface VirtualScrollProps extends SlideButtonProperties {
  children: ReactNode;
  isDesktop: boolean;
}

/**
 * Horizontal virtual scroll for game categories, this component is meant to work only for
 * categories, is not usable for any other kind of component.
 */
function HorizontalVirtualScroll({
  withFlags,
  tileSize,
  withJackpot,
  containerRef,
  childrenIsReady,
  gamesAmount,
  scrollingPoints,
  name,
  children,
  isDesktop,
}: VirtualScrollProps) {
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [containerRect, setContainerRect] = useState<DOMRect | null>(null);

  const scrollHandler = useCallback((event: Event) => {
    const scrollValue = (event.target as HTMLDivElement).scrollLeft;
    setScrollLeft(scrollValue);
  }, []);

  useScrollEvent(containerRef, scrollHandler, 0);

  const isInView = useCallback((elementRect?: DOMRect) => {
    if (!elementRect || !containerRect) {
      return true;
    }

    const scrollingPoint = scrollLeft + containerRect.x;

    return elementRect.x < (scrollingPoint + containerRect.width)
      && (elementRect.x + elementRect.width) > scrollingPoint;
  }, [containerRect, scrollLeft]);

  const contextValue = useMemo(() => ({
    scrollLeft,
    isInView,
  }), [isInView, scrollLeft]);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    setContainerRect(containerRef.current.getBoundingClientRect());
  }, [containerRef]);

  return (
    <HorizontalVirtualScrollContext.Provider value={contextValue}>
      {isDesktop ? (
        <ArrowWrapper
          withFlags={withFlags}
          tileSize={tileSize}
          withJackpot={withJackpot}
          containerRef={containerRef}
          childrenIsReady={childrenIsReady}
          gamesAmount={gamesAmount}
          scrollingPoints={scrollingPoints}
          name={name}
        >
          {children}
        </ArrowWrapper>
      ) : (
        <>
          {children}
        </>
      )}
    </HorizontalVirtualScrollContext.Provider>
  );
}

export default HorizontalVirtualScroll;
