import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  GameCategoryItem,
  Orientations,
  RootState,
} from '../../../../interfaces';
import RibbonsComponent from '../../Ribbons/Ribbons';
import { getDoesRenderRibbons } from '../../../../utils/ribbonsHelper';

interface GameDetailsRibbonsMobileProperties {
  item: GameCategoryItem;
}

interface HeaderRowProps {
  orientation: Orientations;
}

function GameDetailsRibbonsMobileComponent({ item }: GameDetailsRibbonsMobileProperties) {
  const orientation = useSelector((state: RootState) => state.application.orientation);
  const clientType = useSelector((state: RootState) => state.application.clientType);
  const doesRenderRibbons = getDoesRenderRibbons(item, clientType);

  if (!doesRenderRibbons) {
    return null;
  }

  return (
    <HeaderRow orientation={orientation}>
      <RibbonsComponent item={item} />
    </HeaderRow>
  );
}

const GameDetailsPaylineMobile = styled(GameDetailsRibbonsMobileComponent)`
  width: 100%;
  display: block;
  padding-bottom: 5px;
`;

const HeaderRow = styled.div < HeaderRowProps >`
  padding: 8px 0px 4px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: ${(props) => (props.orientation === Orientations.Landscape ? 'left' : 'center')};

  > * {
    &:not(:last-child) {
      margin-right: 4px;
    }
  }
`;

export default GameDetailsPaylineMobile;
