import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import useNavigation from '../../../../hooks/useNavigation';
import { GameCategoryItem, RootState } from '../../../../interfaces';
import { KeyValuePair } from '../../../../interfaces/KeyValuePair';
import TextButton from '../../../rsi/Button/TextButton';
import { colors } from '../../../../constants/style-variables';
import FavoriteButton from './FavoriteButton';
import { Translations } from '../../../../constants/translations';

interface GameDetailsButtonsProperties {
  className?: string;
  onClick: any;
  isLoggedIn: boolean;
  gameData: GameCategoryItem;
  logData: KeyValuePair[];
  disableDemoMode: boolean;
}

// eslint-disable-next-line max-len
function GameDetailsButtonsComponent({
  className, gameData, disableDemoMode, onClick, isLoggedIn, logData,
}: GameDetailsButtonsProperties) {
  const useFullscreenDetails = useSelector((state: RootState) => state.layout.useFullscreenDetails);
  const {
    launchGame,
    navigateToPage,
  } = useNavigation();
  const favoriteGameClicked = () => {
    if (!useFullscreenDetails) {
      onClick();
    }
  };

  if (isLoggedIn) {
    return (
      <div className={`${className} ${(useFullscreenDetails ? 'mobile' : '')}`}>
        <PlayButton
          className="button button-play"
          translationKey={Translations.PLAY_GAME}
          onClick={() => {
            launchGame(gameData, 'REAL', logData);
            onClick();
          }}
        />
        {!disableDemoMode && (
          <>
            <DemoButton
              className="button button-demo"
              translationKey={Translations.PLAY_DEMO}
              disabled={disableDemoMode}
              onClick={() => {
                launchGame(gameData, 'FUN', logData);
                onClick();
              }}
            />
            <SeparationLine />
          </>
        )}
        <FavoriteButton
          gameCode={gameData.code}
          isMobile={useFullscreenDetails}
          onClick={favoriteGameClicked}
        />
      </div>
    );
  }
  return (
    <div className={className}>
      <LoginButton
        className="button button-login"
        translationKey={Translations.LOGIN}
        onClick={() => {
          launchGame(gameData, 'REAL', logData);
          onClick();
        }}
      />
      <SignupButton
        className="button button-play"
        translationKey={Translations.SIGNUP}
        onClick={() => {
          navigateToPage('?page=signup');
          onClick();
        }}
      />
    </div>
  );
}

GameDetailsButtonsComponent.defaultProps = {
  className: undefined,
};

const GameDetailsButtons = styled(GameDetailsButtonsComponent)`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-top: 24px;
  padding-bottom: 24px;

  .button {
    font-size: 14px;
    height: 48px;
    font-weight: 700;
  }

  &.mobile {

    & > .button {
      border-radius: 8px;
    }

    .button-play {
      min-width: 116px;
    }

    .button-demo {
      margin-right: 24px;
    }

    & .button:not(:first-of-type) {
      margin-left: 24px;
    }

    @media only screen and (orientation: portrait) {
      flex-direction: row-reverse;
      justify-content: space-between;

      & .button:not(:first-of-type) {
        margin-left: 0;
        padding: 0 16px;
      }

      & .button:last-of-type {
        padding-left: 0;
      }

      .button-demo {
        margin-right: 0;
      }

      .button-play {
        width: 100%;
      }
    }

  }
`;

const unauthorizedButtonWidth = 95;

const LoginButton = styled(TextButton)`
  height: 18px;
  width: ${unauthorizedButtonWidth}px;
  background-color: transparent;
  color: ${colors.blue};
  border: solid 2px ${colors.blue};
  border-radius: 3px;
  flex: 1;
`;

const SignupButton = styled(TextButton)`
  border: 0;
  height: 18px;
  margin-left: 24px;
  width: ${unauthorizedButtonWidth}px;
  background-color: ${colors.gold};
  color: ${colors.blueDarker};
  border-radius: 3px;
  flex: 1;
`;

const SeparationLine = styled.div`
  height: 14px;
  background-color: #aaa;
  width: 2px;
  min-width: 2px;
`;

const DemoButton = styled(TextButton)`
  border: 0;
  height: 18px;
  background-color: transparent;
  color: ${colors.blue};
  &:disabled {
    color: ${colors.gray4} !important;
  }
`;

const PlayButton = styled(TextButton)`
  border: 0;
  height: 18px;
  border-radius: 3px;
  background-color: ${colors.blue};
  color: ${colors.white};
`;

export default GameDetailsButtons;
