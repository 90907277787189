import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { GameDetails, RootState } from '../interfaces';
import useConfigs from './useConfigs';
import getTransferredDollarsIntoCents from '../utils/getTransferredDollarsIntoCents';
import { Translations } from '../constants/translations';

/**
 * This hook returns payline as a string
 * - example: Min $0.50 Covers all 5 Pay Lines/Action
 * - it uses of both platforms: mobile and desktop
 */
export default (gameDetails: GameDetails | null) => {
  const { casinoLobby } = useConfigs(['casinoLobby']);
  const [paylineMessage, setPaylineMessage] = useState<string | null>(null);
  const rsiFormat = useSelector((state: RootState) => state.application.rsiFormat);

  const intl = useIntl();

  useEffect((): void => {
    if (!gameDetails || !casinoLobby) {
      return;
    }
    const minBet = getTransferredDollarsIntoCents(rsiFormat, gameDetails.minBet);
    const payLines = gameDetails.maxPaylines ? gameDetails.maxPaylines.toString() : null;
    const { showMinBet, showPaylines } = casinoLobby;

    if (showMinBet && showPaylines && minBet && payLines) {
      const payLinesString = intl.formatMessage(
        { id: Translations.GAME_DETAILS_PAYLINES_INFO },
        { payLines },
      );
      const minBetString = intl.formatMessage(
        { id: Translations.GAME_DETAILS_MINBET_INFO },
        { minBet },
      );
      const allActionString = `${minBetString} ${payLinesString}`;
      setPaylineMessage(allActionString);
      return;
    }

    if (showPaylines && payLines) {
      const payLinesString = intl.formatMessage(
        { id: Translations.GAME_DETAILS_PAYLINES_INFO },
        { payLines },
      );
      setPaylineMessage(payLinesString);
      return;
    }

    if (showMinBet && minBet) {
      const minBetString = intl.formatMessage(
        { id: Translations.GAME_DETAILS_MINBET_INFO },
        { minBet },
      );

      setPaylineMessage(minBetString);
    }
  }, [gameDetails, intl, casinoLobby, rsiFormat]);

  return paylineMessage;
};
