import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import GameDetailsPaylineMobile from './GameDetailsPaylineMobile';
import GameDetailsRibbonsMobile from './GameDetailsRibbonsMobile';
import {
  GameImage, NavigationState, Orientations, RootState,
} from '../../../../interfaces';
import { lobbyActions } from '../../../../store/actions';
import logger from '../../../../services/logger';
import ImageCarouselMobile from './ImageCarouselMobile';
import GameDetailsButtons from '../Shared/GameDetailsButtons';
import GameDetailsHeaderMobile from './GameDetailsHeaderMobile';
import LargestWinSection from './LargestWinSection';
import {
  breakpoints,
  colors,
  fixedSizes,
  paddings,
} from '../../../../constants/style-variables';
import { useTypedDispatch } from '../../../../store';
import { ClientType } from '../../../../interfaces/ClientType';
import { Translations } from '../../../../constants/translations';

interface ReadMoreButtonProps {
  textExpanded: boolean;
  readMoreTextVisible: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

function ReadMoreButton({
  textExpanded,
  readMoreTextVisible,
  onClick,
}: ReadMoreButtonProps) {
  return (
    <ReadButton
      textExpanded={textExpanded}
      onClick={onClick}
    >
      {readMoreTextVisible && (
      <FormattedMessage id={textExpanded ? Translations.READ_LESS : Translations.READ_MORE} />
      )}
    </ReadButton>
  );
}

interface GameDetailsMobileProperties {
  className?: string;
}

/**
 * Game details component for mobile layout. The text needs to be wrapped around game image and
 * there must be possibility to expand/close long text description.
 * - Text container needs to use block and not flex layout, otherwise there will be no wrapping
 * around floating image.
 * - Long texts are cut off with overflow and 'Read more' row is placed on top of it in the bottom
 * as an absolute element with background color. Game image needs to be on top of that.
 * - Rotating a device needs to reset image height but expanded text needs to stay expanded.
 * - Background (body) must not be scrollable while details are visible. Because of iOS it requires
 * special handling: saving current scroll position and setting fixed position (moves scrollbar to
 * top automatically). When details are closed then body styles are removed and window is scrolled
 * back to the position it was before opening details panel. Unfortunately scrolling to original
 * position sometimes causes flickering and thus all these scroll activities must be done before
 * taking details panel away (1ms delay).
 * - Read more functionality: it's better to assume that the feature with it's text-covering
 * line-stripe is required immediately than displaying the text and then covering the last bit of
 * the text. Also because of flickering the text itself comes visible 1ms later after everything
 * has been set up visually.
 */
function GameDetailsMobile({ className }: GameDetailsMobileProperties) {
  const dispatch = useTypedDispatch();
  const clientType = useSelector((state: RootState) => state.application.clientType);
  const orientation = useSelector((state: RootState) => state.application.orientation);
  const hasNativeTopBar = useSelector((state: RootState) => state.layout.hasNativeTopBar);
  const detailedGame = useSelector((state: RootState) => state.lobby.detailedGame);
  const detailedGameInfo = useSelector((state: RootState) => state.lobby.detailedGameInfo);
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

  const [textExpanded, setTextExpanded] = useState(false);
  const [overflowActive, setOverflowActive] = useState(true);
  const [readMoreRequired, setReadMoreRequired] = useState(true);
  const [readMoreTextVisible, setReadMoreTextVisible] = useState(false);
  const [imgMaxHeight, setImgMaxHeight] = useState<number | null>(null);

  const disabledDemoMode = detailedGameInfo ? detailedGameInfo.demoModeDisabled : true;

  const onExitClick = useCallback((fromButton: boolean) => {
    RSIUtils.unlockBodyScroll();
    const timeout = fromButton ? 1 : 0;
    setTimeout(() => {
      dispatch(lobbyActions.setDetailedGame(null, null, null));
    }, timeout);
  }, [dispatch]);

  useEffect(() => {
    if (detailedGame && clientType && !detailedGameInfo) {
      dispatch(lobbyActions.fetchGamesDetails(detailedGame.item.code, isLoggedIn ? 'REAL' : 'FUN', detailedGame.item.gameProvider, clientType));
    }
  }, [dispatch, detailedGame, clientType, detailedGameInfo, isLoggedIn]);

  useEffect(() => {
    RSIUtils.lockBodyScroll();

    const unsub = RSINavigationHandler.subscribe(
      ({
        from,
        to,
      }: NavigationState) => {
        if (from?.page === 'all-games' && to?.page !== from?.page) {
          onExitClick(false);
        }
      },
    );

    return () => {
      try {
        unsub();
      } catch (err) {
        let errorMsg = '';
        if (err instanceof Error) {
          errorMsg = err.message;
        }
        logger.error(`Failed to unsub from RSINavigationHandler: ${errorMsg}`);
      }
      RSIUtils.unlockBodyScroll();
    };
    // TODO remove orientation, added because something else overrides this style on rotation
  }, [onExitClick, orientation]);

  useEffect(() => {
    setImgMaxHeight(null);
  }, [orientation]);

  /**
   * Used for reducing "Read more" button flickering.
   */
  useEffect(() => {
    const readRequired = overflowActive || textExpanded;
    setReadMoreRequired(readRequired);
    setTimeout(() => {
      setReadMoreTextVisible(readRequired && !!detailedGameInfo);
    }, 1);
  }, [detailedGameInfo, overflowActive, textExpanded]);

  /**
   * Safari 14 doesn't support 'aspect-ratio' style, therefore have to manually set the height.
   */
  const aspectRatio = useCallback((node: HTMLInputElement | null) => {
    if (!node) {
      return;
    }
    let heightValue = node.clientWidth * (252 / 402);
    if (orientation === Orientations.Landscape && imgMaxHeight && imgMaxHeight < heightValue) {
      heightValue = imgMaxHeight;
    }
    // eslint-disable-next-line no-param-reassign
    node.style.setProperty('height', `${heightValue}px`);
    node.style.setProperty('min-height', `${heightValue}px`);
  }, [imgMaxHeight, orientation]);

  const measureRef = useCallback((node: HTMLInputElement | null) => {
    if (!node || !detailedGameInfo) {
      return;
    }
    /* timeout set to get orientation hook to be first, otherwise max height might get overridden
    back to 100% and will cause a img size jump when expanding/closing text panel */
    setTimeout(() => {
      if (!node.parentElement) {
        return;
      }
      const shouldActiveOverflow = node.clientHeight > node.parentElement.clientHeight;
      setOverflowActive(shouldActiveOverflow);
      if (!textExpanded) {
        const maxHeight = node.parentElement.clientHeight - 8;
        setImgMaxHeight(maxHeight);
      }
    }, 1);
  }, [textExpanded, detailedGameInfo]);

  if (!detailedGame) {
    return null;
  }

  const images: GameImage[] = [{
    gameCode: detailedGame.item.code,
    gameName: detailedGame.item.name,
  }];

  return (
    <GameDetailsMobileComponent
      className={className}
      hasNativeBottomBar={
          clientType === ClientType.IosApp || clientType === ClientType.IosSingleApp
        }
      hasNativeTopBar={hasNativeTopBar}
      textExpanded={textExpanded}
      data-test-id="game-details-mobile"
    >
      <InnerContainer>
        <GameDetailsHeaderMobile detailedGame={detailedGame} onExitClick={onExitClick} />
        {orientation === Orientations.Landscape ? (
          <GameDetailsBody hasOverflow={!textExpanded} isPortrait={false}>
            <ImgLandscapeWrapper
              maxHeight={imgMaxHeight ? `${imgMaxHeight}px` : '100%'}
              ref={aspectRatio}
            >
              <ImageCarouselMobile images={images} code={detailedGame.item.code} />
            </ImgLandscapeWrapper>
            <InfoBlock ref={measureRef}>
              <GameDetailsRibbonsMobile item={detailedGame.item} />
              <GameDetailsPaylineMobile gameDetails={detailedGameInfo} />
              {detailedGameInfo && (
                <GameDetailsButtons
                  gameData={detailedGame.item}
                  logData={detailedGame.logData}
                  onClick={onExitClick}
                  isLoggedIn={isLoggedIn}
                  disableDemoMode={disabledDemoMode}
                />
              )}
              <GameDescription>
                {detailedGameInfo?.gameDescription}
              </GameDescription>
            </InfoBlock>
            {readMoreRequired
              && (
              <ReadMoreButton
                textExpanded={textExpanded}
                readMoreTextVisible={readMoreTextVisible}
                onClick={() => setTextExpanded(!textExpanded)}
              />
              )}
          </GameDetailsBody>
        ) : (
          <>
            <GameDetailsRibbonsMobile item={detailedGame.item} />
            <GameDetailsPaylineMobile gameDetails={detailedGameInfo} />
            <ImgPortraitWrapper
              maxHeight={imgMaxHeight ? `${imgMaxHeight}px` : '100%'}
              ref={aspectRatio}
            >
              <ImageCarouselMobile images={images} code={detailedGame.item.code} />
            </ImgPortraitWrapper>
            {detailedGameInfo && (
              <GameDetailsButtons
                gameData={detailedGame.item}
                logData={detailedGame.logData}
                onClick={onExitClick}
                isLoggedIn={isLoggedIn}
                disableDemoMode={disabledDemoMode}
              />
            )}
            <GameDetailsBody hasOverflow={!textExpanded} isPortrait>
              <InfoBlock ref={measureRef}>
                <GameDescription>
                  {detailedGameInfo?.gameDescription}
                </GameDescription>
              </InfoBlock>
              {readMoreRequired
                && (
                <ReadMoreButton
                  textExpanded={textExpanded}
                  readMoreTextVisible={readMoreTextVisible}
                  onClick={() => setTextExpanded(!textExpanded)}
                />
                )}
            </GameDetailsBody>
          </>
        )}
        <LargestWinSection detailedGameInfo={detailedGameInfo} />
      </InnerContainer>
    </GameDetailsMobileComponent>
  );
}

GameDetailsMobile.defaultProps = {
  className: undefined,
};

const GameDescription = styled.div`
  width: 100%;
  line-height: 20px;
  font-weight: 500;
  font-size: 12px;
`;

const InfoBlock = styled.div`
  position: relative;
`;

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

interface DetailsBodyProps {
  hasOverflow: boolean;
  isPortrait: boolean;
}

const GameDetailsBody = styled.div<DetailsBodyProps>`
  ${(props) => props.hasOverflow && css`
    overflow: hidden;
  `}

  ${(props) => props.isPortrait && css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 25px;
  `}

  position: relative;
`;

interface ReadButtonProps {
  textExpanded: boolean;
}

const ReadButton = styled.div<ReadButtonProps>`
  ${(props) => !props.textExpanded && css`
    position: absolute;
    bottom: 0px;
  `}
  background: white;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 8px;
  text-decoration: underline;
  cursor: pointer;

  :after {
    content: '\\200b';
  }
`;

interface GameDetailsMobileProps {
  hasNativeTopBar: boolean;
  hasNativeBottomBar: boolean;
  textExpanded: boolean;
}

const GameDetailsMobileComponent = styled.div < GameDetailsMobileProps >`
  ${(props) => !props.textExpanded && css`
    display: flex;
  `}

  position: fixed;
  top: ${(props) => (props.hasNativeTopBar ? '0px' : fixedSizes.headerHeightLowerOnly)};
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px ${paddings.pagePadding}px 20px ${paddings.pagePadding}px;
  background-color: ${colors.white};
  z-index: 3;
  overflow-x: hidden;
  overflow-y: auto;

  @supports (padding-left: env(safe-area-inset-left)) {
    @media all and (orientation: landscape) {
      padding-left: max(${paddings.pagePadding}px, env(safe-area-inset-left));
    }
  }

  @media only screen and (min-width: ${breakpoints['screen-md-min']}px) {
    top: ${(props) => (props.hasNativeTopBar ? '0px' : fixedSizes.headerHeight)};
  }

  @media only screen and (max-width: ${breakpoints['screen-xs-max']}px) and (orientation: portrait) {
    bottom: ${(props) => (props.hasNativeBottomBar ? '0px' : fixedSizes.bottomNavHeight)};
  }
`;

interface ImgWrapperProps {
  maxHeight: string;
}

const ImgPortraitWrapper = styled.div<ImgWrapperProps>`
  width: 100%;
  max-width: 402px;
  min-width: 100%;
  max-height: 252px;
  margin-top: 8px;
`;

const ImgLandscapeWrapper = styled.div<ImgWrapperProps>`
  position: relative;
  float: right;
  width: calc(45%);
  max-width: 402px;
  max-height: ${(props) => props.maxHeight};
  margin-bottom: 8px;
  margin-left: 16px;

  img {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    max-height: 100%;
  }
`;

export default GameDetailsMobile;
