import React, { ReactElement, useCallback, useState } from 'react';
import { CMS_SRC_PATH, FOLDERS_BY_IMAGE_TYPE } from '../../../../constants/image-variables';
import ImgVisibilityWrapper from '../Shared/ImgVisibilityWrapper';
import SkeletonLoader from '../../../Loading/SkeletonLoader';
import PictureTag from './PictureTag';
import ChildrenWithErrorHandler from '../Shared/ChildrenWithErrorHandler';

interface AnimatedFileTypeProps {
  isVisible: boolean;
  isFavoriteOrRecentRolledUp: boolean;
  src: string | null;
  code: string | null;
  children: ReactElement;
  className?: string;
  customWidth?: number;
  skeletonHeight?: number;
  handleError: Function;
  type: 'svg' | 'gif';
}

type ReadOnlyAnimatedFileTypeProps = Readonly<AnimatedFileTypeProps>;

function AnimatedFileType({
  isVisible,
  src,
  code,
  children,
  className,
  customWidth,
  skeletonHeight,
  isFavoriteOrRecentRolledUp,
  handleError,
  type,
}: ReadOnlyAnimatedFileTypeProps) {
  const [loaded, setLoaded] = useState<boolean>(false);

  const handleLoaded = useCallback(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded]);

  const getFolder = useCallback((imageType: string): string => {
    if (src && src === CMS_SRC_PATH) {
      return '';
    }

    return FOLDERS_BY_IMAGE_TYPE[imageType] || '';
  }, [src]);

  return (
    <ImgVisibilityWrapper data-testid={`${type}-image-wrapper`} isVisible={isVisible}>
      {!loaded && (
        <SkeletonLoader width={customWidth} height={skeletonHeight} hasAspectRatio />
      )}
      <PictureTag
        className={className}
        handleImageLoaded={handleLoaded}
        hasSkeleton={!loaded && isFavoriteOrRecentRolledUp}
        skeletonHeight={skeletonHeight}
      >
        <source
          srcSet={`${src}${getFolder(type)}${code}`}
          type={`image/${type === 'svg' ? 'svg+xml' : type}`}
        />
        <ChildrenWithErrorHandler handleError={handleError}>
          {children}
        </ChildrenWithErrorHandler>
      </PictureTag>
    </ImgVisibilityWrapper>
  );
}

AnimatedFileType.defaultProps = {
  className: '',
  customWidth: 0,
  skeletonHeight: undefined,
};

export default AnimatedFileType;
