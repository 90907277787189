import { ClientType } from '../interfaces/ClientType';
import { GameCategoryItem } from '../interfaces';

/**
 * Utility class for managing game ribbon flags. Some rules to follow:
 * - hidden flags always take the priority
 * - some games may be hidden for specific platforms only (eg. hidden in ios app but visible
 * everywhere else)
 * - there might be unknown flags that are just ignored (no visual ribbons for these)
 * - only one visible ribbon per game
 */

const hiddenFlag = 'HIDDEN';
const supportedFlagsInPriorityOrder: string[] = [
  hiddenFlag, 'NEW', 'EXCLUSIVE', 'PROGRESSIVE', 'GLOBAL_PROGRESSIVE',
  'POPULAR', 'FEATURED', 'RUSH_REWARDS', 'FREE_SPINS',
];
const clientHiddenRibbonMap = new Map<ClientType, string>([
  [ClientType.Android, 'ANDROID_HIDDEN'],
  [ClientType.IosApp, 'IOS_APP_HIDDEN'],
  [ClientType.IosWeb, 'IOS_WEB_HIDDEN'],
  [ClientType.IosSingleApp, 'IOS_SINGLE_APP_HIDDEN'],
  [ClientType.Web, 'WEB_HIDDEN'],
]);

const hasGenericRibbons = (flags: string[]): boolean => (
  flags.some((flag) => supportedFlagsInPriorityOrder.includes(flag))
);

const hasClientHiddenRibbon = (flags: string[], client: ClientType): boolean => (
  flags.includes(clientHiddenRibbonMap.get(client) || '')
);

export const hasRibbons = (flags: string[], client: ClientType): boolean => (
  hasGenericRibbons(flags) || hasClientHiddenRibbon(flags, client)
);

export const priorityRibbon = (flags: string[], client: ClientType): string | null => {
  if (hasClientHiddenRibbon(flags, client)) {
    return hiddenFlag;
  }
  const ribbon = supportedFlagsInPriorityOrder.find((flag) => flags.includes(flag));
  if (ribbon) {
    return ribbon;
  }
  return null;
};

/**
 * Function return flag: does item have Jackpot
 * - returns true:
 * 1) GameCategory's flags contain PROGRESSIVE or GLOBAL_PROGRESSIVE
 * - returns false in the rest of the cases
 */
export const getHasJackpot = (item: GameCategoryItem): boolean => item.flags.includes('PROGRESSIVE') || item.flags.includes('GLOBAL_PROGRESSIVE');

/**
 * Function returns flag: do ribbons render
 * - returns false:
 * 1) There are no GameCategoryItem or ClientType
 * 2) There are no ribbons, no JP, and no payout percentage
 * - returns true in the rest of the cases
 */
export const getDoesRenderRibbons = (item: GameCategoryItem, clientType: ClientType | null)
  : boolean => {
  if (!item || !clientType) {
    return false;
  }

  const hasJP = getHasJackpot(item);
  const hasFlag = hasRibbons(item.flags, clientType);

  if (!hasFlag && !hasJP && !item.payoutPercentage) {
    return false;
  }
  return true;
};
