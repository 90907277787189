import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { logger } from '../services';
import { applicationActions, layoutActions } from '../store/actions';
import { ClientType } from '../interfaces/ClientType';

export default (): void => {
  const dispatch = useDispatch();
  const clientType = () => {
    if (RSIUtils.detector.isIOS) {
      if (RSIUtils.detector.isSingleApp) {
        return ClientType.IosSingleApp;
      }
      if (RSIUtils.detector.isApp) {
        return ClientType.IosApp;
      }
      return ClientType.IosWeb;
    }
    if (RSIUtils.detector.isAndroid) {
      return ClientType.Android;
    }
    return ClientType.Web;
  };

  let retryCount = 0;

  const initializeRsiApi = (): void => {
    try {
      if (!rsiApi.isLoaded()) {
        retryCount += 1;
        logger.warn(`[rsiApi] rsiApi not loaded, retry ${retryCount}`);
        setTimeout(() => {
          initializeRsiApi();
        }, 1000);
        return;
      }

      dispatch(applicationActions.setRsiApiStatus(true));
      dispatch(applicationActions.setClientType(clientType()));
      dispatch(layoutActions.setHasNativeTopBar(RSIUtils.detector.isMobileApp));

      const format = rsiApi.getModule('util/Format');
      dispatch(applicationActions.setFormat(format));
      logger.info('[rsiApi] rsiApi ready');
    } catch (e) {
      retryCount += 1;
      logger.warn(`Cannot find rsiApi, retry ${retryCount}`);
      setTimeout(() => {
        initializeRsiApi();
      }, 1000);
    }
  };

  useEffect(() => {
    initializeRsiApi();
    // eslint-disable-next-line
  }, []);
};
