import { useEffect, useRef, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useCarousel = (imagesLength: number) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0);
  const handleSlideChange = (elementIndex: number): void => {
    const ref = containerRef.current;
    if (!ref
      // This guarantees that the children are loaded, so the width calculation will be correct
      || ref.children[ref.children.length - 1]?.scrollWidth < 50) {
      return;
    }
    ref.children.item(elementIndex)
      ?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  };

  const scrollNext = () => {
    if (activeImageIndex + 1 >= imagesLength) {
      setActiveImageIndex(0);
    } else {
      setActiveImageIndex(activeImageIndex + 1);
    }
  };
  const scrollPrev = () => {
    if (activeImageIndex <= 0) {
      setActiveImageIndex(imagesLength - 1);
    } else {
      setActiveImageIndex(activeImageIndex - 1);
    }
  };

  useEffect(() => {
    handleSlideChange(activeImageIndex);
  }, [activeImageIndex]);

  return {
    handleSlideChange,
    containerRef,
    activeImageIndex,
    setActiveImageIndex,
    scrollNext,
    scrollPrev,
  };
};

export default useCarousel;
