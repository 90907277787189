import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { RootState } from '../../../../interfaces';
import { colors } from '../../../../constants/style-variables';
import { Translations } from '../../../../constants/translations';
import eventBus, { eventTypes } from '../../../../services/eventBus';

interface FavoriteButtonProperties {
  className?: string;
  onClick: any;
  gameCode: string;
  isMobile: boolean;
}

interface FavoriteIconContainerProps {
  isFavorite: boolean;
}

const { Icon } = UiComponentLibrary;

function FavoriteButtonComponent({
  className, onClick, gameCode, isMobile,
}: FavoriteButtonProperties) {
  const favoriteGames = useSelector((state: RootState) => state.lobby.favoriteGames);
  const isFavorite: boolean = favoriteGames.includes(gameCode);

  return (
    <button
      className={`${className} button button-favorite ${isMobile ? 'mobile' : ''}`}
      type="button"
      onClick={() => {
        eventBus.publish(eventTypes.CASINO_GAME_FAVORITED, {
          gameCode,
          favorite: isFavorite,
        });
        onClick();
      }}
    >
      <FavoriteIconContainer isFavorite={isFavorite}>
        <Icon
          name={isFavorite ? 'star-fill' : 'star-line'}
          width={23}
          height={22}
        />
      </FavoriteIconContainer>
      <FormattedMessage
        id={isFavorite ? Translations.UNFAVORITE_GAME : Translations.FAVORITE_GAME}
      />
    </button>
  );
}

FavoriteButtonComponent.defaultProps = {
  className: undefined,
};

const FavoriteIconContainer = styled.div <FavoriteIconContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  margin-right: 6px;

  ${({ isFavorite }) => isFavorite && css`
    color: ${colors.gold};
  `}

  ${({ isFavorite }) => !isFavorite && css`
    color: inherit;
  `}
`;

const FavoriteButton = styled(FavoriteButtonComponent)`
  border: 0;
  height: 18px;
  background-color: transparent;
  color: ${colors.blue};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
`;

export default FavoriteButton;
