export type LobbyConfig = {
  lobbyId: string;
  enabledDevices: EnabledDevice[];
  showMinBet: boolean;
  showPaylines: boolean;
  enableGameDetailsAsCards: boolean;
  enableLazyLoadingForGameCategories: boolean;
};

export enum EnabledDevice {
  DESKTOP = 'DESKTOP',
  APP_IOS = 'APP_IOS',
  MOBILE_WEB_IOS = 'MOBILE_WEB_IOS',
  APP_ANDROID = 'APP_ANDROID',
  MOBILE_WEB_ANDROID = 'MOBILE_WEB_ANDROID',
}
