import axios from 'axios';
import { SearchActionTypes } from '../actionTypes';
import { logger } from '../../services';
import requestApiHeaders from '../../utils/headers';
import { TypedThunk } from '../index';

export const setSearchString = (searchString: string) => ({
  type: SearchActionTypes.SET_SEARCH_STRING,
  payload: searchString,
});

export const setPreviousExpandedCategory = (categoryCode: string | null) => ({
  type: SearchActionTypes.SET_PREVIOUS_EXPANDED_CATEGORY,
  payload: categoryCode,
});

export const setSearchVisible = (visible: boolean) => ({
  type: SearchActionTypes.SET_SEARCH_VISIBLE,
  payload: visible,
});

export const getSearchGames = (
  searchText: string,
  clientType: string | null,
  pageNr: number,
  pageSize: number = 20,
):TypedThunk => async (dispatch) => {
  dispatch({ type: SearchActionTypes.GET_SEARCH_REQUEST });
  try {
    const { apiUrl, cageCode } = RSIConfigHandler.getConfigs().api.data;
    const response = await axios.get(
      `${apiUrl}service/lobby/portal/search/games`,
      {
        headers: requestApiHeaders(),
        params: {
          cageCode,
          clientType,
          searchText,
          pageNr,
          pageSize,
        },
      },
    );
    dispatch({
      type: SearchActionTypes.GET_SEARCH_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    let errorMsg = '';
    if (err instanceof Error) {
      errorMsg = err.message;
    }
    logger.error(`Failed to search games with text: ${searchText}`, { error: errorMsg });
    dispatch({ type: SearchActionTypes.GET_SEARCH_FAILURE });
  }
};
