export type Locale = {
  language: string;
  locale: string;
}

export type LanguageConfig = {
  default: string;
  available: string[];
  locales: Locale[];
}

type LangLocaleObject = {
  [key: string]: string;
}

export const LangLocale: LangLocaleObject = {
  SPA: 'es-CO',
  'es-CO': 'SPA',
  ENG: 'en-US',
  'en-US': 'ENG',
};

export enum TranslationType {
  LOBBY = 'lobbyTranslations'
}
