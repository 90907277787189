import { TileSize } from '../interfaces/TileSize';

export const CMS_SRC_PATH = './cms/games/';
export const FOLDERS_BY_IMAGE_TYPE: { [type: string]: string } = {
  jpg: 'games/',
  svg: 'svg-games/',
  webp: 'webp-games/',
};
export const FILE_SIZE_MAP = new Map<TileSize, string>([
  [TileSize.S, 'sd'],
  [TileSize.M, 'md'],
  [TileSize.L, 'hd'],
]);
export const IMG_SIZE_WIDTH = new Map<TileSize, number>([
  [TileSize.S, 201],
  [TileSize.M, 268],
  [TileSize.L, 402],
]);
export const IMG_SIZE_HEIGHT = new Map<TileSize, number>([
  [TileSize.S, 126],
  [TileSize.M, 168],
  [TileSize.L, 252],
]);
