// NavigationHandler typings
export type Params = {
  [key: string]: any;
}

export type ToLocation = {
  page: string;
  params: Params;
  hash: string;
}

export type ToLocationPayload = string | Partial<ToLocation>;

export enum LocalHistoryState {
  Auto = 'AUTO',
  Push = 'PUSH',
  Replace = 'REPLACE',
}

export interface LocationObject extends ToLocation {
  fullPath: string;
  historyState: LocalHistoryState;
}

export type NavigationState = {
  to?: LocationObject;
  from?: LocationObject;
}
