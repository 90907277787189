export const { GameImageSize } = RSIUtils.gameImageEnums;
export const { METADATA } = RSIUtils.gameImageEnums.DataType;

export interface GameAssets {
  [identifier: string]: {
    [imageSize in typeof GameImageSize]: string | {
      ratio: string;
      width: number;
      height: number;
    };
  };
}
