import React from 'react';
import styled from 'styled-components';

function BubbleComponent({ className }: {className?: string}) {
  return <div className={className} />;
}

BubbleComponent.defaultProps = {
  className: undefined,
};

const BubbleMobile1 = styled(BubbleComponent)`
  position: absolute;
  width: 40px;
  height: 40px;
  background: radial-gradient(50% 50% at 50% 50%, rgb(250, 140, 70) 0%, rgba(250, 140, 70, 0.58) 38%, rgba(250, 140, 70, 0.27) 68%, rgba(250, 140, 70, 0.07) 89%, rgba(250, 140, 70, 0) 100%);
  background-blend-mode: screen;
  mix-blend-mode: screen;
  border-radius: 15px;
  top: -1%;
  right: 10%
`;

export default BubbleMobile1;
