enum LayoutActionTypes {
  SET_USE_FULLSCREEN_DETAILS = 'layout/SET_USE_FULLSCREEN_DETAILS',
  SET_DESKTOP_MODE = 'layout/SET_DESKTOP_MODE',
  SET_MAIN_TILE_SIZE_MAP = 'layout/SET_MAIN_TILE_SIZE_MAP',
  SET_NATIVE_TOP_BAR= 'layout/SET_NATIVE_TOP_BAR',
  SET_DESKTOP_READY_FOR_INIT = 'layout/SET_DESKTOP_READY_FOR_INIT',

  DEFAULT = 'configs/DEFAULT',
}

export default LayoutActionTypes;
