import React from 'react';
import styled, { css } from 'styled-components';
import { colors, paddings } from '../../../constants/style-variables';
import { TileSize } from '../../../interfaces/TileSize';
import {
  GAME_TILE_BORDER_RADIUS,
  PADDING_Y_GAME_CATEGORY_WITHOUT_JACKPOT, RIBBON_TOP_POSITION,
} from '../../../constants/category-variables';

export const TOP_PADDING_GAME_CATEGORY_WITH_JACKPOT = 20;
export const BOTTOM_PADDING_GAME_CATEGORY_WITH_JACKPOT = 28;

interface SlideButtonProperties {
  className?: string;
  onClick: VoidFunction;
  hidden: boolean;
  isNext: boolean;
  name?: string;
  withJackpot: boolean;
  withFlags: boolean;
  tileSize: TileSize;
}

function SlideButtonComponent({
  onClick,
  className,
  isNext,
  hidden,
  name,
  withJackpot,
  tileSize,
  withFlags,
}: SlideButtonProperties) {
  const withLargeView = (tileSize === TileSize.M || tileSize === TileSize.L);
  return (
    <ArrowButton
      className={`scroll-button ${className} button-${isNext ? 'next' : 'prev'} ${hidden ? 'hidden' : ''}`}
      onClick={onClick}
      name={name}
      withJackpot={withJackpot}
      isNext={isNext}
      hidden={hidden}
      withLargeView={withLargeView}
      withFlags={withFlags}
    >
      <img
        src="./cms/casinoLobby/category_scroll_arrow_button.svg"
        alt={isNext ? 'scroll next' : 'scroll previous'}
      />
    </ArrowButton>
  );
}

SlideButtonComponent.defaultProps = {
  className: 'slide-button',
  name: 'slide-button',
};

/**
 * Property - withLargeView
 * The large view depends on the largest tile in the category. If it contains the largest tile,
 * then the button width and the icon are wider
 *
 * Calculating height depends on ribbonTop variable
 * category may have ribbons that have negative top position. These ribbons must be covered under
 * the button's background. That is why the height increased by this number
 */

const ArrowButton = styled.button.attrs((props: {
  withJackpot: boolean,
  isNext: boolean,
  hidden: boolean,
  withLargeView: boolean,
  withFlags: boolean,
}) => props)`
  border: 0;
  padding: 0;
  font-size: 22px;
  line-height: 22px;
  position: absolute;
  z-index: 2;
  top: 0;

  ${({ withLargeView }) => withLargeView && css`
    width: 70px;
  `};

  ${({ withLargeView }) => !withLargeView && css`
    width: 50px;
  `};

  ${({ withJackpot }) => withJackpot && css`
    background: linear-gradient(90deg, rgba(1, 46, 108, 0) 16.79%, #012E6C 100%);
  `};

  ${({ withJackpot }) => !withJackpot && css`
    background: linear-gradient(90deg, rgba(1, 46, 108, 0) 16.79%, #011024 97.4%);
  `};

  ${({ withJackpot, withFlags }) => withJackpot && !withFlags && css`
    height: calc(100% - ${(TOP_PADDING_GAME_CATEGORY_WITH_JACKPOT + BOTTOM_PADDING_GAME_CATEGORY_WITH_JACKPOT)}px);
    margin: ${TOP_PADDING_GAME_CATEGORY_WITH_JACKPOT}px 0 ${BOTTOM_PADDING_GAME_CATEGORY_WITH_JACKPOT}px 0;
  `};

  ${({ withJackpot, withFlags }) => !withJackpot && !withFlags && css`
    height: calc(100% - ${(PADDING_Y_GAME_CATEGORY_WITHOUT_JACKPOT * 2)}px);
    margin-top: ${PADDING_Y_GAME_CATEGORY_WITHOUT_JACKPOT}px;
  `};

  ${({ withJackpot, withFlags }) => withJackpot && withFlags && css`
    height: calc(100% - ${TOP_PADDING_GAME_CATEGORY_WITH_JACKPOT}px - ${BOTTOM_PADDING_GAME_CATEGORY_WITH_JACKPOT}px + ${RIBBON_TOP_POSITION + 2}px);
    margin: ${TOP_PADDING_GAME_CATEGORY_WITH_JACKPOT - RIBBON_TOP_POSITION - 1}px 0 ${BOTTOM_PADDING_GAME_CATEGORY_WITH_JACKPOT}px 0;
  `};

  ${({ withJackpot, withFlags }) => !withJackpot && withFlags && css`
    height: calc(100% - ${(PADDING_Y_GAME_CATEGORY_WITHOUT_JACKPOT * 2)}px + ${PADDING_Y_GAME_CATEGORY_WITHOUT_JACKPOT - RIBBON_TOP_POSITION}px);
    margin-top: ${PADDING_Y_GAME_CATEGORY_WITHOUT_JACKPOT - RIBBON_TOP_POSITION}px;
  `};

  & > img {
    margin-right: 15px;

    ${({ withLargeView }) => withLargeView && css`
      width: 19px;
    `};

    ${({ withLargeView }) => !withLargeView && css`
      width: 13px;
    `};
  }

  @media (hover: hover) {

    :hover {
      color: ${colors.blueDarker};

      & > img {

        ${({ withLargeView }) => withLargeView && css`
          width: 24px;
        `};

        ${({ withLargeView }) => !withLargeView && css`
          width: 18px;
        `};
      }
    }
  }

  ${({ isNext }) => !isNext && css`
    transform: rotateZ(180deg);
    left: ${paddings.pagePadding}px;
  `};

  ${({ isNext }) => isNext && css`
    right: ${paddings.pagePadding}px;
  `};

  border-top-right-radius: ${GAME_TILE_BORDER_RADIUS}px;
  border-bottom-right-radius: ${GAME_TILE_BORDER_RADIUS}px;

  ${({ hidden }) => hidden && css`
    display: none;
  `};
`;

export default SlideButtonComponent;
