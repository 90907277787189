import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// @ts-ignore
import { Subscription } from 'rxjs';
import { JackpotPotType, RootState } from '../interfaces';
import { findTickerByGameCode } from './useJackpotsManager';

/**
 * Listens Jackpot visual ticker value changes for a specified game code. No ticker if the game is
 * not supported by Jackpot system, this is defined by game code and type of jackpot.
 * @param gameCode game code
 * @param potType type of jackpot
 * @param pauseSubscription flag to pause a jackpot subscription
 */
export default (
  gameCode: string,
  potType: JackpotPotType,
  pauseSubscription?: boolean,
): number | undefined => {
  const supportedGames = useSelector((state: RootState) => (state.jackpots.supportedGames));
  const tickerObservers = useSelector((state: RootState) => (state.jackpots.tickerObservers));
  const [jpValue, setJpValue] = useState<number | undefined>(undefined);

  useEffect(() => {
    let jpSubscription : Subscription | undefined;
    if (pauseSubscription) {
      return jpSubscription ? jpSubscription?.unsubscribe() : () => {};
    }

    const ticker = findTickerByGameCode(gameCode, potType);
    if (!ticker || !supportedGames.includes(gameCode) || !tickerObservers.has(ticker.id)) {
      setJpValue(undefined);
      return () => {};
    }

    jpSubscription = tickerObservers.get(ticker.id)
      ?.subscribe((value : number) => setJpValue(value));

    return () => {
      jpSubscription?.unsubscribe();
    };
  }, [supportedGames, tickerObservers, gameCode, potType, pauseSubscription]);

  return jpValue;
};
