import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../../constants/style-variables';

interface ScrollDotProperties {
  className?: string;
  active: boolean;
  onClick?: any;
}

function ScrollDotComponent({ onClick, className, active }: ScrollDotProperties) {
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
    <ClickWrapper className={className! + active ? ' active' : ''} onClick={onClick} />
  );
}

ScrollDotComponent.defaultProps = {
  className: 'scroll-dot',
  onClick: () => {},
};

const ClickWrapper = styled.li`
  cursor: pointer;
`;

const ScrollDot = styled(ScrollDotComponent)`
  width: 8px;
  height: 8px;
  margin: 5px 10px;
  border-radius: 6px;
  background-color: ${colors.white};

  ${(props) => props.active && css`
    background-color: ${colors.gold};
  `};
`;

export default ScrollDot;
