/* eslint-disable no-nested-ternary */
import React from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { colors } from '../../../../constants/style-variables';
import { JackpotPotType, RootState, RsiFormat } from '../../../../interfaces';
import { useJackpotGameTicker } from '../../../../hooks';
import { logger } from '../../../../services';

export const sumBorderRadius = '2% 2% 2% 2% / 70% 70% 70% 70%;';

interface DigitContainerProperties {
  className?: string;
  isMobile: boolean;
  categoryCode?: string;
  pauseJackpot?: boolean;
}

export enum CurvedDividerStyle {
  curvedFromRight = 'curvedFromRight',
  curvedFromLeft = 'curvedFromLeft',
  notCurved = 'notCurved',
}

export const getCurvedDividerStyle = (
  _val: string,
  idx: number,
  array: string[],
): CurvedDividerStyle => {
  const arrMiddle = array.length / 2;
  const arrCeilIdx = Math.ceil(arrMiddle) - 1;
  const arrFloorIdx = Math.floor(arrMiddle) - 1;
  if (idx < arrFloorIdx) {
    return CurvedDividerStyle.curvedFromLeft;
  } if (idx === arrCeilIdx || idx === arrFloorIdx) {
    return CurvedDividerStyle.notCurved;
  }
  return CurvedDividerStyle.curvedFromRight;
};

// 10000032 -> ['$10,' '0', '0', '0,', '0', '0', '0.', '32'];
const getArrayOfDigitsBySeparators = (
  currency: '$' | 'S/',
  value: string,
  thousandsSeparator: string,
  centsSeparator: string,
): string[] => {
  // 10000000.32 -> 10,000,000.32
  const currencySignSplit = value.split(currency);
  const sumInRequiredFormat = currencySignSplit[1].split(centsSeparator);
  const dollars = sumInRequiredFormat[0];
  const cents = sumInRequiredFormat[1];
  const resultDigits: string[] = [];
  // 10,000,000 -> [10, 000, 000];
  const split = dollars.split(thousandsSeparator);
  // [10, 000, 000]; -> [000, 000]; do not need a separator if there is only hundreds
  resultDigits.push(`${split[0]}${split.length > 1 ? thousandsSeparator : ''}`);
  split.splice(0, 1);
  // [000, 000]; -> ['0', '0', '0,', '0', '0', '0'];
  split.join('').split('')
    .map((digit, idx, array) => (((idx + 1) % 3 === 0 && idx < array.length - 3) ? `${digit}${thousandsSeparator}` : digit))
    .map((digit) => resultDigits.push(digit));
  // colombia format with . separator does not have cents
  if (cents && centsSeparator !== ',') {
    resultDigits[resultDigits.length - 1] = `${resultDigits[resultDigits.length - 1]}${centsSeparator}`;
    resultDigits.push(`${cents}`);
  }
  resultDigits.splice(0, 0, currency);
  return resultDigits;
};

const doesStringFullMatch = (reg: RegExp, str: string): boolean => str.replaceAll(reg, '') === '';

// 10000032 -> ['$10,' '0', '0', '0,', '0', '0', '0.', '32'];
export const getArrayOfDigitsForTicker = (
  value?: number,
  rsiFormat?: RsiFormat | null,
): string[] => {
  const isCurrencyNotFunction = typeof rsiFormat?.Currency !== 'function';
  if (!value || isCurrencyNotFunction) {
    if (isCurrencyNotFunction) {
      logger.error(`typeof rsiFormat.Currency's value is ${typeof rsiFormat?.Currency} and rsiFormat
      is ${JSON.stringify(rsiFormat)}`);
    }
    return [];
  }
  const valueWithCurrency = rsiFormat?.Currency(value);
  const isValueWithCurrencyNotString = typeof valueWithCurrency !== 'string';
  if (isValueWithCurrencyNotString || !valueWithCurrency) {
    if (isValueWithCurrencyNotString) {
      logger.error(`typeof valueWithCurrency is ${typeof valueWithCurrency}
      and value is ${JSON.stringify(valueWithCurrency)}`);
    }
    if (!valueWithCurrency) {
      logger.error(`rsiFormat.Currency returned not expected format
      ${JSON.stringify(valueWithCurrency)}`);
    }
    return [];
  }
  const formattedValue = valueWithCurrency?.replaceAll(' ', '');

  const enSumFormat = /^(\$)?(([1-9]\d{0,2}(,\d{3})*|([1-9]\d*))(\.\d{2})?)|([0]\.(([0][1-9])|([1-9]\d)))$/g;
  if (doesStringFullMatch(enSumFormat, formattedValue)) {
    return getArrayOfDigitsBySeparators('$', formattedValue, ',', '.');
  }
  const colombianSumFormat = /^(\$)?(([1-9]\d{0,2}(\.\d{3})*|([1-9]\d*))(,\d{2})?)|([0],(([0][1-9])|([1-9]\d)))$/g;
  if (doesStringFullMatch(colombianSumFormat, formattedValue)) {
    return getArrayOfDigitsBySeparators('$', formattedValue, '.', ',');
  }

  const peruSumFormat = /^(S\/)?([1-9]\d{0,2}(,\d{3})*(\.\d{2})?|0\.\d{2})$/g;
  if (doesStringFullMatch(peruSumFormat, formattedValue)) {
    return getArrayOfDigitsBySeparators('S/', formattedValue, ',', '.');
  }

  return [];
};

function DigitContainerComponent({
  className,
  isMobile,
  categoryCode,
  pauseJackpot,
}: DigitContainerProperties) {
  const rsiFormat = useSelector((state: RootState) => state.application.rsiFormat);
  const category = useSelector((state: RootState) => state.lobby.components)
    ?.find((component) => component.code === categoryCode);
  const gameCode = category?.data?.games?.items[0]?.code;
  const gameJpValue = useJackpotGameTicker(
    String(gameCode),
    JackpotPotType.PROGRESSIVE,
    pauseJackpot,
  );

  const digits = getArrayOfDigitsForTicker(gameJpValue, rsiFormat);
  const curvedDividerStyles: CurvedDividerStyle[] = digits.map(getCurvedDividerStyle);
  const supportedCurrencies = ['$', 'S/'];

  return (
    <Wrapper className={className}>
      {digits.map((digit, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={`${digit} ${idx}`}>
          <Digit isMobile={isMobile} withDot={digit.includes('.')}>{digit}</Digit>
          {!supportedCurrencies.includes(digit) && (
            <Divider
              idx={idx}
              curved={curvedDividerStyles[idx]}
              isMobile={isMobile}
            />
          )}
        </React.Fragment>
      ))}
    </Wrapper>
  );
}

DigitContainerComponent.defaultProps = {
  className: undefined,
  categoryCode: undefined,
  pauseJackpot: undefined,
};

const Wrapper = styled.div`
  display: flex;
  border-radius: ${sumBorderRadius};
  background: linear-gradient(180deg, #003D6F -10.27%, #00294B 30.4%);
`;

const Digit = styled.p.attrs((props: { isMobile: boolean; withDot: boolean; }) => props)`
  :first-child:first-letter {
    color: ${colors.gold};
    text-shadow: 1.64066px 3.28132px 3.28132px rgba(11, 0, 33, 0.25);
    font-size: ${({ isMobile }) => (isMobile ? 32 : 40)}px;
    padding-right: ${({ isMobile }) => (isMobile ? 2 : 6)}px;
    padding-left: ${({ isMobile }) => (isMobile ? 4 : 8)}px;
  }

  :last-of-type {
    padding-right: ${({ isMobile }) => (isMobile ? 5 : 7)}px;
  }

  padding: ${({ isMobile }) => (isMobile ? '6px 0px 6px 0px' : '8px 0px 8px 0px')};

  color: ${colors.white};
  margin: 0;

  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  font-size: ${({ isMobile }) => (isMobile ? 28 : 36)}px;

  mix-blend-mode: lighten;
  text-shadow: 1.64066px 3.28132px 3.28132px rgb(11 0 33 / 30%);
  min-width: ${({ isMobile }) => (isMobile ? 17 : 22)}px;
  ${({ withDot, isMobile }) => withDot && css`
    min-width: ${(isMobile ? 26 : 33)}px;
  `}
`;

const Divider = styled.div.attrs((props: { idx: number; curved: CurvedDividerStyle; isMobile: boolean; }) => props)`
  :last-child {
    display: none;
  }

  border-color: rgb(255, 217, 0);
  border-style: solid;
  border-radius: 200%/100px 240px 200px 300px;
  border-left: none;
  border-bottom: none;
  border-top: none;
  border-width: 1.5px;

  ${({ curved, isMobile }) => {
    if (curved === CurvedDividerStyle.notCurved) {
      return `
        width: 1px;
        margin-left: ${isMobile ? 5 : 6}px;
        margin-right: ${isMobile ? 5 : 6}px;
      `;
    } if (curved === CurvedDividerStyle.curvedFromLeft) {
      return `
        width: 17px;
        transform: rotate(180deg);
        margin-left: ${isMobile ? 5 : 6}px;
        margin-right: -8px;
      `;
    }
    return `
      width: 17px;
      margin-left: ${isMobile ? -8 : -6}px;
      margin-right: ${isMobile ? 5 : 6}px;
    `;
  }}
`;

export default React.memo(DigitContainerComponent);
