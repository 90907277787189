import {
  useState, useEffect, useCallback, RefObject,
} from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../interfaces';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useScrolling = (
  scrollingPoints: { [point: number]: number },
  containerRef: RefObject<HTMLDivElement>,
  childrenIsReady: boolean,
  gamesAmount: number,
) => {
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);
  const [containerWidth, setContainerWidth] = useState(0);
  const expandedComponentId = useSelector((state: RootState) => state.lobby.expandedComponentId);

  const handleArrowVisibility = useCallback((): void => {
    const ref = containerRef.current;
    if (!ref) {
      return;
    }

    if (ref.getBoundingClientRect().width !== containerWidth) {
      const { width } = ref.getBoundingClientRect();
      setContainerWidth(width);
    }

    if (
      ref.scrollLeft > 0 && ref.scrollLeft + containerWidth > ref.scrollWidth
    ) {
      ref.scrollLeft = ref.scrollWidth - containerWidth;
    }

    const shouldHaveNext = Math.ceil(ref.scrollLeft + containerWidth) < ref.scrollWidth;
    const shouldHavePrev = ref.scrollLeft > 0;

    setHasNext(shouldHaveNext);
    setHasPrev(shouldHavePrev);
  }, [containerRef, containerWidth]);

  /*
    Recalculate the arrow visibility when:
    * screen size changes
    * amount of games (container width) changes
    delay is required to be sure updated values are used in visibility function
   */
  useEffect(() => {
    if (!childrenIsReady) {
      return () => { };
    }
    const timeoutId = setTimeout(() => {
      handleArrowVisibility();
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    childrenIsReady,
    containerWidth,
    handleArrowVisibility,
    expandedComponentId,
    gamesAmount,
  ]);

  const handleScroll = useCallback(() => {
    if (!containerRef.current) {
      return;
    }
    handleArrowVisibility();
  }, [handleArrowVisibility, containerRef]);

  useEffect(() => {
    const ref = containerRef.current;
    if (!ref) {
      return undefined;
    }
    ref.addEventListener('scroll', handleScroll);
    return () => {
      ref.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, containerRef]);

  const performScrolling = (pixelsToScroll: number): void => {
    const ref = containerRef.current;
    if (!ref) {
      return;
    }

    ref.scrollLeft = pixelsToScroll;

    handleArrowVisibility();
  };

  const performEdgeScrolling = (): void => {
    const ref = containerRef.current;
    if (!ref) {
      return;
    }

    ref.scrollLeft = ref.scrollWidth - containerWidth;

    handleArrowVisibility();
  };

  const findClosestScrollingPoint = (currentScrollPosition: number, goingForward?: boolean) => {
    const scrollingValues = Object.values(scrollingPoints);

    const filter = scrollingValues.filter((value) => {
      if (goingForward) {
        return value > currentScrollPosition;
      }

      return value < currentScrollPosition;
    });

    let closest = goingForward && filter.length > 0 ? filter[0] : 0;

    if (!goingForward && filter.length > 0) {
      closest = filter[filter.length - 1];
    }

    // @ts-ignore
    const index = Object.keys(scrollingPoints).find((key) => scrollingPoints[key] === closest);

    let indexFound = 0;
    if (index) {
      indexFound = parseInt(index, 10);
    }

    /**
     * Edge case: if the difference between the current scroll index vs the found scroll point
     * is too small, when clicking the go back arrow the scroll feels a bit weird
     * fixing a limit to scroll makes the animation nicer and natural
     */
    if (!goingForward && currentScrollPosition - closest < 10) {
      indexFound -= 1;
    }

    return indexFound;
  };

  const handlePrev = (): void => {
    const ref = containerRef.current;
    if (!ref) {
      return;
    }

    const scrollLeft = Math.floor(ref.scrollLeft);
    const nextPoint = findClosestScrollingPoint(scrollLeft);
    const pixelsToScroll = scrollingPoints[nextPoint];

    performScrolling(pixelsToScroll);
  };

  const handleNext = (): void => {
    const ref = containerRef.current;
    if (!ref) {
      return;
    }

    const scrollLeft = Math.ceil(ref.scrollLeft);
    const nextPoint = findClosestScrollingPoint(scrollLeft, true);
    const pixelsToScroll = scrollingPoints[nextPoint];

    const remainingPixels = ref.scrollWidth - ref.scrollLeft - containerWidth;

    const isCloseToViewPortEdge = remainingPixels < scrollingPoints[1] * 1.5;

    if (isCloseToViewPortEdge) {
      performEdgeScrolling();
      return;
    }

    performScrolling(pixelsToScroll);
  };

  return {
    handleNext, handlePrev, hasPrev, hasNext, containerRef,
  };
};

export default useScrolling;
