import { AnyAction } from 'redux';
import { LayoutState } from '../../interfaces';
import { LayoutActionTypes } from '../actionTypes';
import { TileSize } from '../../interfaces/TileSize';

const initialState: LayoutState = {
  isDesktop: false,
  useFullscreenDetails: true,
  mainTileSizeWidthMap: new Map<TileSize, number>(),
  hasNativeTopBar: true,
  desktopReadyForInit: false,
};

const tileSizesEqual = (prev: Map<TileSize, number>, updated: Map<TileSize, number>): boolean => {
  const prevList: string[] = [];
  const newList: string[] = [];
  prev.forEach((value, key) => prevList.push(`${key}=${value}`));
  updated.forEach((value: number, key: TileSize) => newList.push(`${key}=${value}`));
  return prevList.length === newList.length && prevList.every((value) => newList.includes(value));
};

export default (
  state = initialState,
  { type, payload }: AnyAction = { type: LayoutActionTypes.DEFAULT },
) => {
  switch (type) {
    case LayoutActionTypes.SET_USE_FULLSCREEN_DETAILS: {
      return {
        ...state,
        useFullscreenDetails: payload,
      };
    }
    case LayoutActionTypes.SET_NATIVE_TOP_BAR: {
      return {
        ...state,
        hasNativeTopBar: payload,
      };
    }
    case LayoutActionTypes.SET_MAIN_TILE_SIZE_MAP: {
      if (tileSizesEqual(state.mainTileSizeWidthMap, payload)) {
        return state;
      }
      return {
        ...state,
        mainTileSizeWidthMap: payload,
      };
    }
    case LayoutActionTypes.SET_DESKTOP_MODE: {
      return {
        ...state,
        isDesktop: payload,
      };
    }
    case LayoutActionTypes.SET_DESKTOP_READY_FOR_INIT: {
      return {
        ...state,
        desktopReadyForInit: payload,
      };
    }
    default:
      return state;
  }
};
