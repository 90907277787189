import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { logger } from '../services';
import useConfigs from './useConfigs';

const timeOutRandomness = Math.random();

function useGameListUpdateThrottle(eventHandler: () => void) {
  const { casinoLobby } = useConfigs(['casinoLobby']);
  const maxThrottleSeconds = useMemo(
    () => Number(casinoLobby?.maxDelaySecondsGameListUpdate ?? 0),
    [casinoLobby?.maxDelaySecondsGameListUpdate],
  );
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const throttledHandler = useCallback(() => {
    const timeOutDelay = Math.floor(timeOutRandomness * maxThrottleSeconds);
    const timeOutDelatMs = timeOutDelay * 1000;
    logger.log(`Throttled handler called, will wait for ${timeOutDelay}s out of a max ${maxThrottleSeconds}s configured delay`);
    if (timeoutRef.current !== null) return; // There's already a scheduled execution

    timeoutRef.current = setTimeout(() => {
      logger.log('Throttled handler executed');
      eventHandler(); // Call the provided event handler
      timeoutRef.current = null; // Reset the timer reference
    }, timeOutDelatMs); // Delay defined by the random timeout delay
  }, [eventHandler, maxThrottleSeconds]);

  useEffect(() => {
    const subscribe = () => {
      rsiApi.getPushService().on('GAME_LIST_UPDATE', throttledHandler);
    };

    const unsubscribe = () => {
      rsiApi.getPushService().off('GAME_LIST_UPDATE', throttledHandler);
    };

    subscribe(); // Subscribe with the throttled handler

    return () => {
      unsubscribe(); // Clean up the subscription
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current); // Clear the timer if it's still running
        timeoutRef.current = null;
      }
    };
  }, [throttledHandler]);
}

export default useGameListUpdateThrottle;
