/*
  Number of skeleton boxes to render per skeleton game row.
*/
export const SKELETON_GAMES_PER_ROW: number = 6;

/*
  Number of skeleton categories to render in the skeleton lobby
*/
export const SKELETON_CATEGORIES_IN_LOBBY: number = 6;

/**
 * This constant expects portal to use rectangle-like images
 * in case we start implementing other kind of shape we most
 * likely need to double-check this value
 */
export const SKELETON_HEIGHT_PERCENTAGE: number = 0.6268;
