import { useEffect, useState } from 'react';
import eventBus, { eventTypes } from '../services/eventBus';

const useSoftHibernateMode = () => {
  const [activateSoftHibernateMode, setActivateSoftHibernateMode] = useState(false);

  useEffect(() => {
    const gameOpenSubscriber = eventBus.subscribe(
      eventTypes.OPEN_GAME,
      () => {
        setActivateSoftHibernateMode(true);
      },
    );

    const gameCloseSubscriber = eventBus.subscribe(
      eventTypes.CLOSE_GAME,
      () => {
        setActivateSoftHibernateMode(false);
      },
    );

    return () => {
      gameOpenSubscriber.unsubscribe();
      gameCloseSubscriber.unsubscribe();
    };
  }, []);

  return { activateSoftHibernateMode };
};

export default useSoftHibernateMode;
