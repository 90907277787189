import React from 'react';
import styled, { css } from 'styled-components';
import { GameImage } from '../../../../interfaces';
import Picture from '../../../rsi/Image/Picture';
import { TileSize } from '../../../../interfaces/TileSize';
import { IMG_SIZE_WIDTH } from '../../../../constants/image-variables';

interface CarouselImageDesktopProperties {
  className?: string;
  image: GameImage;
  active?: boolean;
}

// eslint-disable-next-line max-len
function CarouselImageDesktopComponent({
  className,
  active,
  image,
}: CarouselImageDesktopProperties) {
  return (
    <Wrapper>
      <Picture
        className={className! + active ? 'active' : ''}
        gameCode={image.gameCode}
        customWidth={IMG_SIZE_WIDTH.get(TileSize.L)}
        isFavoriteOrRecentRolledUp={false}
      >
        <Image
          loading="lazy"
          alt={image.gameName}
        />
      </Picture>
    </Wrapper>
  );
}

CarouselImageDesktopComponent.defaultProps = {
  className: undefined,
  active: true,
};

const CarouselImageDesktop = styled(CarouselImageDesktopComponent)`

  ${(props) => !props.active && css`
    opacity: 0.2;
  `};
`;

const Image = styled.img`
  margin: 0 5px;
  border-radius: 5px;
  height: 100%;
`;

const Wrapper = styled.div`
  position: relative;
  width: 328px;
  display: inline-flex;
`;

export default CarouselImageDesktop;
