import React from 'react';
import styled from 'styled-components';

interface IconButtonProperties {
  className?: string;
  imageClassName?: string;
  imageName: string;
  onClick?: any;
}

function IconButtonComponent({
  onClick, className, imageName, imageClassName,
}: IconButtonProperties) {
  return (
    <button type="button" className={className} onClick={onClick}>
      <ButtonImage className={imageClassName} src={`./cms/casinoLobby/${imageName}`} alt={imageName} />
    </button>
  );
}

IconButtonComponent.defaultProps = {
  className: undefined,
  imageClassName: undefined,
  onClick: undefined,
};

const ButtonImage = styled.img`
  width: 100%;
  height: 100%;
  vertical-align: unset;
`;

const IconButton = styled(IconButtonComponent)`
  padding: 0;
`;

export default IconButton;
