import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import useNavigation from '../../hooks/useNavigation';
import {
  GameCategoryItem, GridComponent, LobbyGameCategory, MultiRowTemplate, RootState,
} from '../../interfaces';
import { lobbyActions } from '../../store/actions';
import { KeyValuePair } from '../../interfaces/KeyValuePair';
import { colors, paddings } from '../../constants/style-variables';
import CategoryHeader from './CategoryHeader';
import GameCategoryComponent from './GameCategory';
import ItemClickAction from '../../interfaces/GameItemClickAction';
import CategoryExpandedDetailsComponent from './GameCategoryExpandedDetails';
import { logger } from '../../services';
import CategoryDescription from './CategoryDescription/CategoryDescription';
import useMultiRowTemplate from '../../hooks/useMultiRowTemplate';
import { TileSize } from '../../interfaces/TileSize';
import SkeletonGamesRow from '../Loading/SkeletonGamesRow';
import { SKELETON_GAMES_PER_ROW } from '../../constants/skeleton-loader';
import useJackpotCategory from '../../hooks/useJackpotCategory';
import JackpotContainerComponent from './JackpotContainer/JackpotContainer';
import { DeviceType } from '../../interfaces/DeviceType';
import { BOTTOM_PADDING_GAME_CATEGORY_WITH_JACKPOT, TOP_PADDING_GAME_CATEGORY_WITH_JACKPOT } from '../rsi/Button/SlideButton';
import HorizontalVirtualScroll from '../rsi/Scroll/HorizontalVirtualScroll';
import doesIncludeRecentOrFavoriteSource from '../../utils/doesIncludeRecentOrFavoriteSource';
import useConfigs from '../../hooks/useConfigs';

interface GameCategoryContainerProperties {
  className?: string;
  category: LobbyGameCategory;
  dataTestId?: string;
}

/**
 * Displays game category in main lobby. Visual parts depend on what device is used.
 * - desktop: scroll arrows when hovering over the games + game details opens as expandable row
 * - tablet: scrolling is same as in mobile + game details opens as expandable row
 * - mobile: no scroll buttons, overflow games next to the edge + game details opens as a modal
 */
function GameCategoryContainer({
  category,
  className,
  dataTestId,
}: GameCategoryContainerProperties) {
  const dispatch = useDispatch();
  const { withJackpot } = useJackpotCategory(category?.code);
  const [categoryTemplate, setCategoryTemplate] = useState<MultiRowTemplate>();
  const [gameCategoryIsReady, setGameCategoryIsReady] = useState<boolean>(false);
  const [scrollingPoints, setScrollingPoints] = useState({});
  const [smallestWidth, setSmallestWidth] = useState<number>();
  const [smallestSize, setSmallestSize] = useState<Partial<GridComponent>>();
  const [gap, setGap] = useState<number>();
  const { launchGame, showGameDetailsModal } = useNavigation();
  const isDesktop = useSelector((state: RootState) => state.layout.isDesktop);
  const isMobile = useSelector((state: RootState) => state.application.deviceType)
    === DeviceType.Mobile;
  const useFullscreenDetails = useSelector((state: RootState) => state.layout.useFullscreenDetails);
  const tileWidthMap = useSelector((state: RootState) => state.layout.mainTileSizeWidthMap);
  const { casinoLobby } = useConfigs(['casinoLobby']);
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    getLargestTileSize,
    getSmallestTileDimensions,
    getTemplateBy,
    getScrollingPoints,
    getGap,
    getComponentsWithMargin,
    getTileSize,
  } = useMultiRowTemplate();
  const isRecentOrFavoritesCategory = doesIncludeRecentOrFavoriteSource(category?.data?.sourceType);

  const setDetailedView = (item: GameCategoryItem, logData: KeyValuePair[]) => {
    dispatch(lobbyActions.setDetailedGame(item, logData, category.code));
  };

  const expandCategory = () => {
    logger.log(`User expanded a specific category: ${category.code}`);
    dispatch(lobbyActions.setExpandedCategory(category.code));
  };

  const handleClick = (gameId: string, action: ItemClickAction) => {
    if (!category?.data) {
      return;
    }
    const item = category.data.games.items.find((gameItem) => gameItem.code === gameId);
    if (!item) {
      return;
    }
    const index = category.data.games.items.indexOf(item);
    const logData = constructLogData(index, category.code);

    if (action !== ItemClickAction.GAME_DETAILS && isRecentOrFavoritesCategory) {
      launchGame(item, 'REAL', logData);
    } else if (casinoLobby?.enableGameDetailsAsCards) {
      showGameDetailsModal(item, logData);
    } else {
      setDetailedView(item, logData);
    }
  };

  const constructLogData = (gameIndex: number, categoryCode: string): KeyValuePair[] => [
    {
      key: 'index',
      value: gameIndex + 1,
    },
    {
      key: 'lobby category',
      value: categoryCode,
    },
  ];

  const configureTemplate = useCallback((template: MultiRowTemplate) => {
    const [tileSmallestWidth, tileSmallestSize] = getSmallestTileDimensions(template, tileWidthMap);

    setSmallestWidth(tileSmallestWidth);
    setSmallestSize(tileSmallestSize);

    const templateGap = getGap(template);
    setGap(templateGap);

    const templateWithMargin = {
      ...template,
      components: getComponentsWithMargin(template.components),
    };

    setCategoryTemplate(templateWithMargin);

    if (!isDesktop) {
      return;
    }

    const totalGames = category.data!.games.paging.totalElements;
    const { pageSize } = category.data.games.paging;
    const points = getScrollingPoints(
      templateWithMargin,
      totalGames,
      pageSize,
      tileSmallestWidth,
      templateGap,
    );
    setScrollingPoints(points);
  }, [
    category.data,
    getComponentsWithMargin,
    getGap,
    getScrollingPoints,
    getSmallestTileDimensions,
    isDesktop,
    tileWidthMap,
  ]);

  const getTemplate = useCallback(() => {
    /*
      recent and favorite categories has a number of visual issues when using templates larger
      than 1x1, thus it needs to be considered separately
    */
    if (doesIncludeRecentOrFavoriteSource(category.code)) {
      // If template exists return a 1x1 template based on the first component's tile size
      const singleTemplate = getTemplateBy(getTileSize(category.style.template.components[0].type));
      return singleTemplate ?? getTemplateBy(TileSize.S);
    }

    return category.style.template;
  }, [category.code, category.style.template, getTemplateBy, getTileSize]);

  const withFlags = useMemo(
    () => category.data!.games.items
      .some((game) => game.flags.length > 0),
    [category.data],
  );

  useEffect(() => {
    const template = getTemplate();
    configureTemplate(template);
  }, [
    category.style,
    configureTemplate,
    getTemplate,
    getTemplateBy,
  ]);

  return (
    <Wrapper
      className={className}
      data-test-id={dataTestId}
      withJackpot={withJackpot}
      isMobile={isMobile}
    >
      {!gameCategoryIsReady && (
        <SkeletonGamesRow numberOfGames={SKELETON_GAMES_PER_ROW} />
      )}
      {gameCategoryIsReady && (
        <>
          {withJackpot && <JackpotContainerComponent categoryCode={category?.code} />}
          <CategoryHeaderWrapper
            headerKey={category.code}
            total={category.data!.games.paging.totalElements}
            pageSize={category.data.games.paging.pageSize}
            isRecentOrFavoritesCategory={isRecentOrFavoritesCategory}
            withJackpot={withJackpot}
          />
          <CategoryDescription
            translationCode={category.code}
            className={(withJackpot) ? 'category_description_uses_background' : undefined}
          />
        </>
      )}
      <div className="category-row">
        <HorizontalVirtualScroll
          withFlags={withFlags}
          tileSize={getLargestTileSize(category.style.template)}
          withJackpot={withJackpot}
          containerRef={containerRef}
          childrenIsReady={gameCategoryIsReady}
          gamesAmount={category.data.games.items.length}
          scrollingPoints={scrollingPoints}
          name={dataTestId}
          isDesktop={isDesktop}
        >
          <GameCategory
            categoryCode={category.code}
            totalGames={category.data!.games.paging.totalElements}
            pageSize={category.data.games.paging.pageSize}
            games={category.data!.games.items}
            template={categoryTemplate}
            onClick={handleClick}
            className="category"
            ref={containerRef}
            isRecentOrFavoritesCategory={isRecentOrFavoritesCategory}
            withJackpot={withJackpot}
            isDesktop={isDesktop}
            onClickMoreGames={expandCategory}
            smallestWidth={smallestWidth}
            smallestSize={smallestSize}
            gap={gap}
            onCategoryReady={(isReady: boolean) => setGameCategoryIsReady(isReady)}
          />
        </HorizontalVirtualScroll>
      </div>

      {!useFullscreenDetails && (
        <CategoryExpandedDetailsComponent
          className="game-details desktop"
          category={category}
          games={category.data!.games.items}
          withJackpot={withJackpot}
        />
      )}
    </Wrapper>
  );
}

GameCategoryContainer.defaultProps = {
  className: undefined,
  dataTestId: 'game-category-container',
};

const Wrapper = styled.div.attrs((props: { withJackpot: boolean, isMobile: boolean }) => props)`
  width: 100%;
  position: relative;

  ${({ withJackpot, isMobile }) => withJackpot && css`
    background: linear-gradient(93.53deg, #08012D -2.71%, #0026ED 55.73%, #003171 97.52%);
    margin-top: ${isMobile ? 57 : 67}px;
    border: 1px solid ${colors.black};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: ${isMobile ? 0 : 14}px;
  `};

  .category-row {
    position: relative;
    overflow: hidden;

    .button-prev, .button-next {
      display: none;
    }
    :hover {
      .button-prev, .button-next {
        display: flex;
        justify-content: end;
        align-items: center;
      }
    }
  }

  .category-title {
    padding-left: ${paddings.pagePadding}px;
    font-size: 16px;
    font-weight: 700;
    color: #316094;
  }

  .category_description_uses_background {
    color: ${colors.white};
  }
`;

const CategoryHeaderWrapper = styled(CategoryHeader).attrs((
  props: {
    withJackpot: boolean;
  },
) => props)`
  ${({ withJackpot }) => withJackpot && css`
    padding-top: 16px;
  `};
  ${({ withJackpot }) => (withJackpot) && css`
    color: ${colors.white};
    @media (hover: hover) {
      :hover {
        color: ${colors.white};
      }
    }
  `};
  ${({ withJackpot }) => (withJackpot) && css`
    .category-header-more-games {
      color: ${colors.white};
      border: 1px solid ${colors.white};
    }

    @media (hover: hover) {
      :hover {
        color: ${colors.gray3};
      }
    }
  `};
`;

const GameCategory = styled(GameCategoryComponent).attrs((
  props: {
    withJackpot: boolean;
  },
) => props)`
  ${({ withJackpot }) => withJackpot && css`
    padding: ${TOP_PADDING_GAME_CATEGORY_WITH_JACKPOT}px 0 ${BOTTOM_PADDING_GAME_CATEGORY_WITH_JACKPOT}px 0;
  `};
`;

export default GameCategoryContainer;
