import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../interfaces/Store';
import { TranslationType } from '../interfaces';
import { LobbyActionTypes } from '../store/actionTypes';
import { useTypedDispatch } from '../store/index';
import useLocale from './useLocale';

export default () => {
  const { language } = useLocale();
  const translationsUnsubscribe = useRef<Unsubscribe>();
  const dispatch = useTypedDispatch();
  const updated = useSelector((state: RootState) => state.lobby.translations.updated);

  useEffect(() => {
    if (translationsUnsubscribe.current) {
      translationsUnsubscribe.current();
    }
    if (language) {
      const translationsConfigKey = `${TranslationType.LOBBY}-${language}`;
      translationsUnsubscribe.current = RSIConfigHandler.subscribe([{
        configKey: translationsConfigKey,
        customUrl: `/conf/${translationsConfigKey}.json`,
      }], ({ data }) => {
        dispatch({ type: LobbyActionTypes.SET_LOBBY_TRANSLATIONS, payload: data });
      });
    }
  }, [dispatch, updated, language]);
};
