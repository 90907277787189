import { useSelector } from 'react-redux';
import { RootState } from '../interfaces/Store';

const useFallbackTranslation = (id: string) => {
  const lobbyCode = useSelector((state: RootState) => state.lobby.code);
  const translations = useSelector((state: RootState) => state.lobby.translations.content);

  if (translations && translations[id]) {
    return id;
  }
  return `${lobbyCode}_${id}`;
};

export default useFallbackTranslation;
