import axios from 'axios';
import { logger } from '../../services';
import requestApiHeaders from '../../utils/headers';
import { LobbyActionTypes } from '../actionTypes';
import {
  CustomGameImage,
  GameCategoryData,
  GameCategoryItem,
  GameCategoryUpdateType,
  LobbyComponentType,
} from '../../interfaces';
import { KeyValuePair } from '../../interfaces/KeyValuePair';
import { TypedThunk } from '../index';
import { LobbyState } from '../../interfaces/Lobby';
import { ClientType } from '../../interfaces/ClientType';
import CategoryDoesNotExistError from '../../errors/CategoryDoesNotExistError';
import { gamesReducer } from '../../utils';

const version = process.env.REACT_APP_VERSION;

// eslint-disable-next-line import/prefer-default-export
export const getLobby = (
  lobbyID: string,
  clientType: ClientType,
  resetLastCategory: boolean,
): TypedThunk => async (dispatch, getState) => {
  dispatch({ type: LobbyActionTypes.GET_LOBBY_CONFIGURATION_REQUEST });
  try {
    const { apiUrl, cageCode } = RSIConfigHandler.getConfigs().api.data;
    const response = await axios.get(
      `${apiUrl}service/lobby/portal/cage/${cageCode}/lobbycontainers/${lobbyID}`,
      {
        headers: requestApiHeaders(),
        params: {
          clientType,
          version,
        },
      },
    );

    const payload: LobbyState = response.data;
    const lastPayloadComponent = payload.components[payload.components.length - 1];

    if (!resetLastCategory
      && lastPayloadComponent.type === LobbyComponentType.GameCategory
      && getState().lobby.code
    ) {
      const updatedGames = gamesReducer(getState().lobby.components[getState().lobby
        .components.length - 1].data.games, lastPayloadComponent.data.games);
      if (updatedGames !== null) {
        lastPayloadComponent.data.games = updatedGames;
      }
    }

    dispatch({
      type: LobbyActionTypes.GET_LOBBY_CONFIGURATION_SUCCESS,
      payload,
    });
  } catch (err) {
    let errorMsg = '';
    if (err instanceof Error) {
      errorMsg = err.message;
    }
    logger.error(`Failed to receive lobby with ID: ${lobbyID}`, { error: errorMsg });
    dispatch({ type: LobbyActionTypes.GET_LOBBY_CONFIGURATION_FAILURE });
  }
};

export const getExpandedCategoryGames = (
  categoryCode: string,
  clientType: ClientType,
  updateType: GameCategoryUpdateType,
  pageNr: number,
  pageSize: number = 24,
): TypedThunk => async (dispatch) => {
  dispatch({ type: LobbyActionTypes.GET_EXPANDED_CATEGORY_GAMES_REQUEST });
  try {
    const { apiUrl, cageCode } = RSIConfigHandler.getConfigs().api.data;
    const response = await axios.get(
      `${apiUrl}service/lobby/portal/cage/${cageCode}/gamecategories/${categoryCode}`,
      {
        headers: requestApiHeaders(),
        params: {
          clientType,
          pageNr,
          pageSize,
        },
      },
    );

    const dispatchLobby: boolean = updateType !== GameCategoryUpdateType.OnlyExpCategory;
    const dispatchExpCategory: boolean = updateType !== GameCategoryUpdateType.OnlyLobby;
    const dispatchSingleCategory = updateType === GameCategoryUpdateType.SingleCategoryInLobby;

    if (dispatchSingleCategory) {
      dispatch({
        type: LobbyActionTypes.GET_LOBBY_CATEGORY_GAMES_SUCCESS,
        payload: response.data,
      });
      return;
    }

    if (dispatchExpCategory) {
      dispatch({
        type: LobbyActionTypes.GET_EXPANDED_CATEGORY_GAMES_SUCCESS,
        payload: response.data,
      });
    }

    if (dispatchLobby) {
      const lobbyLimitedPayload: GameCategoryData = {
        code: response.data.code,
        sourceType: response.data.sourceType,
        games: {
          items: response.data.games.items.slice(0, response.data.games.paging.pageSize),
          paging: response.data.games.paging,
        },
      };

      dispatch({
        type: LobbyActionTypes.GET_LOBBY_CATEGORY_GAMES_SUCCESS,
        payload: lobbyLimitedPayload,
      });
    }
  } catch (err) {
    if (axios.isAxiosError(err) && err?.response?.status === 404) {
      throw new CategoryDoesNotExistError(err);
    }
    let errorMsg = '';
    if (err instanceof Error) {
      errorMsg = err.message;
    }
    logger.error(`Failed to receive category games with code: ${categoryCode}`, { error: errorMsg });
    dispatch({ type: LobbyActionTypes.GET_EXPANDED_CATEGORY_GAMES_FAILURE });
  }
};

export const setExpandedCategory = (categoryCode: string | null, fromWB: boolean = false) => ({
  type: LobbyActionTypes.SET_EXPANDED_CATEGORY,
  payload: {
    categoryCode,
    fromWB,
  },
});

export const setDetailedGame = (
  item: GameCategoryItem | null,
  logData: KeyValuePair[] | null,
  categoryId: string | null,
) => ({
  type: LobbyActionTypes.SET_DETAILED_GAME,
  payload: item ? { item, logData, categoryId } : null,
});

export const fetchGamesDetails = (
  gameCode: string,
  gameMode: string,
  gameProvider: string,
  clientType: ClientType,
): TypedThunk => async (dispatch) => {
  dispatch({ type: LobbyActionTypes.GET_GAME_DETAILS_REQUEST });
  try {
    const { apiUrl, cageCode } = RSIConfigHandler.getConfigs().api.data;
    const response = await axios.get(
      `${apiUrl}service/portalgames/cage/${cageCode}/gamedetails`,
      {
        headers: requestApiHeaders(),
        params: {
          gameCode,
          gameMode,
          gameProvider,
          clientType,
        },
      },
    );
    dispatch({
      type: LobbyActionTypes.GET_GAME_DETAILS_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    let errorMsg = '';
    if (err instanceof Error) {
      errorMsg = err.message;
    }
    logger.error(`Failed to receive game details for code: ${gameCode}`, { error: errorMsg });
    dispatch({ type: LobbyActionTypes.GET_GAME_DETAILS_FAILURE });
  }
};

export const setGameImageSrc = (imageSrc: string | null) => ({
  type: LobbyActionTypes.SET_GAME_IMAGE_SRC,
  payload: imageSrc,
});

export const setCustomGameImages = (customImages: CustomGameImage[]) => ({
  type: LobbyActionTypes.SET_CUSTOM_GAME_IMAGES,
  payload: customImages,
});

export const setFavoriteGames = (games: string[]) => ({
  type: LobbyActionTypes.SET_FAVORITE_GAMES,
  payload: games,
});

export const setTranslations = (translations: {
  [key: string]: string;
} | undefined) => ({
  type: LobbyActionTypes.SET_LOBBY_TRANSLATIONS,
  payload: translations,
});

export const updateTranslations = () => ({
  type: LobbyActionTypes.UPDATE_TRANSLATIONS,
});
