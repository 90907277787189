import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavigationState, RootState } from '../interfaces';
import { DeviceType } from '../interfaces/DeviceType';
import { layoutActions } from '../store/actions';
import { TileSize } from '../interfaces/TileSize';
import { breakpoints, paddings } from '../constants/style-variables';

const mobileVisibleTileCount = new Map<TileSize, number>([
  [TileSize.S, 2.5],
  [TileSize.M, 2.2],
  [TileSize.L, 1.5],
]);
const tabletVisibleTileCount = new Map<TileSize, number>([
  [TileSize.S, 4.5],
  [TileSize.M, 3.75],
  [TileSize.L, 2.66],
]);
const largeDesktopVisibleTileCount = new Map<TileSize, number>([
  [TileSize.S, 6],
  [TileSize.M, 5],
  [TileSize.L, 3],
]);
const mediumDesktopVisibleTileCount = new Map<TileSize, number>([
  [TileSize.S, 5],
  [TileSize.M, 4],
  [TileSize.L, 3],
]);
const smallDesktopVisibleTileCount = new Map<TileSize, number>([
  [TileSize.S, 4],
  [TileSize.M, 3],
  [TileSize.L, 2],
]);
const xsDesktopVisibleTileCount = new Map<TileSize, number>([
  [TileSize.S, 3],
  [TileSize.M, 2],
  [TileSize.L, 1],
]);

const calculateTileWidth = (containerWidth: number, tileCount: number, gap: number): number => {
  const gapCount: number = (tileCount % 1 === 0) ? tileCount - 1 : Math.floor(tileCount);
  return Math.floor((containerWidth - (gapCount * gap)) / tileCount);
};

export default (): void => {
  const deviceType = useSelector((state: RootState) => state.application.deviceType);
  const width = useSelector((state: RootState) => state.application.width);
  const desktopReadyForInit = useSelector((state: RootState) => state.layout.desktopReadyForInit);
  const dispatch = useDispatch();

  /**
   * Constructs tile width sizes for main lobby view in touch devices (tablet and mobiles).
   * - The tile width is calculated for portrait mode where landscape just uses the same tile width.
   * - There are fixed numbers of tiles that must be visible regardless of the device width. So,
   * player will always see 2.5 tiles on a very narrow and on a very large mobile.
   * - Left side page padding needs to be excluded from calculations (tiles must touch the right
   * edge).
   */
  const tileSizeMapForTouch = useCallback((): Map<TileSize, number> => {
    const tileCountMap = (deviceType === DeviceType.Mobile)
      ? mobileVisibleTileCount : tabletVisibleTileCount;
    const containerWidth = Math.min(window.innerWidth, window.innerHeight) - paddings.pagePadding;
    return new Map<TileSize, number>([
      [TileSize.S, calculateTileWidth(
        containerWidth,
        tileCountMap.get(TileSize.S) || 1,
        paddings.gameTileGapWidth.get(TileSize.S) || 1,
      )],
      [TileSize.M, calculateTileWidth(
        containerWidth,
        tileCountMap.get(TileSize.M) || 1,
        paddings.gameTileGapWidth.get(TileSize.M) || 1,
      )],
      [TileSize.L, calculateTileWidth(
        containerWidth,
        tileCountMap.get(TileSize.L) || 1,
        paddings.gameTileGapWidth.get(TileSize.L) || 1,
      )],
    ]);
  }, [deviceType]);

  const tileSizeMapForDesktop = useCallback((): Map<TileSize, number> => {
    const lobbyContainer = document.getElementById('rsi-casino-lobby-game-list');
    if (lobbyContainer) {
      const windowWidth = window.innerWidth;
      let tileMap;
      if (windowWidth <= breakpoints['screen-xs-min']) {
        dispatch(layoutActions.setUseFullscreenDetails(true));
        tileMap = xsDesktopVisibleTileCount;
      } else if (windowWidth < breakpoints['screen-sm-min']) {
        dispatch(layoutActions.setUseFullscreenDetails(true));
        tileMap = smallDesktopVisibleTileCount;
      } else if (windowWidth < breakpoints['screen-md-min']) {
        dispatch(layoutActions.setUseFullscreenDetails(false));
        tileMap = mediumDesktopVisibleTileCount;
      } else {
        dispatch(layoutActions.setUseFullscreenDetails(false));
        tileMap = largeDesktopVisibleTileCount;
      }
      const containerWidth = lobbyContainer.clientWidth - (2 * paddings.pagePadding);
      return new Map<TileSize, number>([
        [TileSize.S, calculateTileWidth(
          containerWidth,
          tileMap.get(TileSize.S) || 1,
          paddings.gameTileGapWidth.get(TileSize.S) || 1,
        )],
        [TileSize.M, calculateTileWidth(
          containerWidth,
          tileMap.get(TileSize.M) || 1,
          paddings.gameTileGapWidth.get(TileSize.M) || 1,
        )],
        [TileSize.L, calculateTileWidth(
          containerWidth,
          tileMap.get(TileSize.L) || 1,
          paddings.gameTileGapWidth.get(TileSize.L) || 1,
        )],
      ]);
    }
    return new Map();
  }, [dispatch]);

  useEffect((): void => {
    if (deviceType !== DeviceType.Desktop || !width || !desktopReadyForInit) {
      return;
    }
    dispatch(layoutActions.setMainTileWidth(tileSizeMapForDesktop()));
  }, [dispatch, deviceType, width, tileSizeMapForDesktop, desktopReadyForInit]);

  /**
   * CASINO-1181 - when launching sportsbook page then casino lobby is initialized on the background
   * but container is not visible (hidden, width 0px). When it comes visible (navigating to casino
   * page) then tile sizes, row scrolling etc. have to be calculated. This useEffect will not be
   * required anymore in future when lobby gets proper hibernation support (CASINO-1096, no hidden
   * DOM elements etc.).
   *
   * Used only for desktop.
   */
  useEffect(() => {
    if (deviceType !== DeviceType.Desktop || desktopReadyForInit) {
      return () => {
        // no actions needed
      };
    }
    const unsubscribe = RSINavigationHandler.subscribe(
      ({ to }: NavigationState) => {
        if (to?.page === 'all-games') {
          dispatch(layoutActions.setDesktopReadyForInitialization(true));
        }
      },
    );
    return () => unsubscribe();
  }, [dispatch, deviceType, desktopReadyForInit]);

  useEffect((): void => {
    switch (deviceType) {
      case DeviceType.Mobile: {
        dispatch(layoutActions.setDesktopMode(false));
        dispatch(layoutActions.setUseFullscreenDetails(true));
        dispatch(layoutActions.setMainTileWidth(tileSizeMapForTouch()));
        break;
      }
      case DeviceType.Tablet: {
        dispatch(layoutActions.setDesktopMode(false));
        dispatch(layoutActions.setUseFullscreenDetails(false));
        dispatch(layoutActions.setMainTileWidth(tileSizeMapForTouch()));
        break;
      }
      case DeviceType.Desktop: {
        dispatch(layoutActions.setDesktopMode(true));
        dispatch(layoutActions.setUseFullscreenDetails(false));
        dispatch(layoutActions.setMainTileWidth(tileSizeMapForDesktop()));
        break;
      }
      default: {
        break;
      }
    }
  }, [dispatch, deviceType, tileSizeMapForTouch, tileSizeMapForDesktop]);
};
