import React from 'react';
import { LobbyComponentType } from '../../interfaces';
import GameCategoryContainer from '../GameCategory/GameCategoryContainer';

import NewsFeed from '../GeneralComponentCategory/NewsFeed';
import Banner from '../BannerCategory/Banner';
import LobbyLastCategory from '../GameCategory/LobbyLastCategory';
import { paddings } from '../../constants/style-variables';
import ErrorBoundary from '../ErrorBoundary';

interface LobbyComponentHandlerProps {
  item: any;
  lastChild?: boolean;
}

enum GeneralComponentCode {
  NEWSFEED = 'NEWSFEED',
  PROMOTIONAL_JACKPOT = 'PROMOTIONAL_JACKPOT',
}

function LobbyComponentHandler({ item, lastChild }: LobbyComponentHandlerProps) {
  if (!item.data) {
    return null;
  }

  if (item.type === LobbyComponentType.Banner) {
    // TODO return check if banner can be shown or something
    return (
      <ErrorBoundary componentName="Banner">
        <Banner bannerConfig={item.data} key={item.code} />
      </ErrorBoundary>
    );
  }

  if (
    item.type === LobbyComponentType.GameCategory || item.type === LobbyComponentType.LiveCategory
  ) {
    if (lastChild) {
      return (
        <ErrorBoundary componentName="LobbyLastCategory">
          <LobbyLastCategory category={item} key={item.code} />
        </ErrorBoundary>
      );
    }
    if (item.data.games.items.length > 0) {
      return (
        <ErrorBoundary componentName="GameCategoryContainer">
          <GameCategoryContainer
            category={item}
            key={item.code}
            dataTestId={`game-category-container-${item.code}`}
          />
        </ErrorBoundary>
      );
    }
  }

  if (item.type === LobbyComponentType.General) {
    const UMDLoader = RSIClient?.UMDLoader;
    switch (item.code) {
      case GeneralComponentCode.NEWSFEED: {
        return (
          <ErrorBoundary componentName="NewsFeed">
            <NewsFeed key={item.code} />
          </ErrorBoundary>
        );
      }
      case GeneralComponentCode.PROMOTIONAL_JACKPOT: {
        return (
          <div style={{ padding: `${paddings.pagePadding}px` }}>
            <ErrorBoundary componentName="mfe-promo-jackpot-banner">
              <UMDLoader
                key={item.code}
                name="mfe-promo-jackpot-banner"
                props={{ variant: 'lobby' }}
              />
            </ErrorBoundary>
          </div>
        );
      }
      default:
        return null;
    }
  }

  return null;
}

LobbyComponentHandler.defaultProps = {
  lastChild: false,
};

export default LobbyComponentHandler;
