import React, {
  useCallback,
  useContext,
  useEffect, useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { SKELETON_HEIGHT_PERCENTAGE } from '../../../constants/skeleton-loader';
import ImgFileType from './FileTypes/ImgFileType';
import { VerticalVirtualScrollContext } from '../Scroll/VerticalVirtualScroll';
import { HorizontalVirtualScrollContext } from '../Scroll/HorizontalVirtualScroll';
import { GameImageSize, METADATA } from '../../../interfaces/GameAssets';
import AnimatedPicture from './AnimatedPicture';
import { CustomGameImage, ImageSource, RootState } from '../../../interfaces';
import {
  CMS_SRC_PATH, FILE_SIZE_MAP,
  FOLDERS_BY_IMAGE_TYPE,
} from '../../../constants/image-variables';
import fileNameHasExtension from '../../../utils/fileNameHasExtension';
import { TileSize } from '../../../interfaces/TileSize';
import { GameAssetsProviderContext } from '../Assets/GameAssetsProvider';

const PLACEHOLDERS: { [key: string]: string } = {
  REGULAR_LARGE: '/webp-games/NA_hd.webp',
  REGULAR_MEDIUM: '/webp-games/NA_md.webp',
  REGULAR_SMALL: '/webp-games/NA_sd.webp',
};

interface PictureProps {
  gameCode: string;
  children: any;
  containerRect?: DOMRect;
  className?: string;
  customWidth?: number;
  isFavoriteOrRecentRolledUp: boolean;
}

/**
 * Display images in the view
 * Features:
 * - It supports 2 types of images (JPG, WEBP) and SVG.
 * - SVGs are rendered using the img tag in order to improve performance,
 * see: https://codepen.io/tigt/post/improving-svg-rendering-performance meaning that SVGs with
 * scripts won't work.
 * - It supports 2 source for images, S3 and CMS; in order to get the custom images it uses the
 * customGameImages redux state property and the gameCode property.
 * - It support 3 type of qualities "sd", "md" and "hd" which are set based on the width of the
 * image, the Picture component tries to get its parent reference width using the containerRect
 * property and if it is not set, it will use the customWidth property instead.
 *
 * @param className Custom css class to set styles
 * @param gameCode Game identifier
 * @param children Image to render
 * @param customWidth Can be used by the parent in order to specify a desired Width
 * @param containerRect Used in order to get the parent's width (if it is set)
 * @param isFavoriteOrRecentRolledUp
 */
function Picture({
  className,
  gameCode,
  children,
  customWidth,
  containerRect,
  isFavoriteOrRecentRolledUp,
}: PictureProps) {
  const customGameImages = useSelector((state: RootState) => state.lobby.customGameImages);
  const gameImageSrc = useSelector((state: RootState) => state.lobby.gameImageSrc);
  const [imageSrc, setImageSrc] = useState<string | undefined>();
  const [alternateImageSrc, setAlternateImageSrc] = useState<string | undefined>(undefined);
  const [imageSize, setImageSize] = useState<typeof GameImageSize>();
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [showImg, setShowImg] = useState<boolean>(true);
  const [isHandlingError, setIsHandlingError] = useState<boolean>(false);
  const skeletonHeight = customWidth && customWidth * SKELETON_HEIGHT_PERCENTAGE;
  const inView = useContext(VerticalVirtualScrollContext);
  const horizontalScrollContext = useContext(HorizontalVirtualScrollContext);
  const assetsContext = useContext(GameAssetsProviderContext);

  const gameAssets = useMemo(() => ({
    assets: assetsContext[gameCode],
    metadata: assetsContext[METADATA],
  }), [assetsContext, gameCode]);

  const configureImageSize = useCallback(() => {
    const wrapperWidth = containerRect?.width ?? customWidth;
    if (!wrapperWidth || !gameAssets.metadata) {
      return;
    }

    const { metadata } = gameAssets;
    if (!metadata) {
      return;
    }

    try {
      const closestImageSize = (Object.keys(metadata) as typeof GameImageSize[])
        .filter((key: typeof GameImageSize) => (
          metadata[key] as typeof METADATA).width >= wrapperWidth)
        .reduce((previousKey: typeof GameImageSize, currentKey: typeof GameImageSize) => {
          const prevWidth = (metadata[previousKey] as typeof METADATA).width;
          const currWidth = (metadata[currentKey] as typeof METADATA).width;
          return Math.abs(currWidth - wrapperWidth) < Math.abs(prevWidth - wrapperWidth)
            ? currentKey
            : previousKey;
        });

      setImageSize(closestImageSize);
    } catch (e) {
      // do not break lobby when reduce fails.
      setImageSize(GameImageSize.SMALL);
    }
  }, [containerRect?.width, customWidth, gameAssets]);

  const getFolder = useCallback((imageType: string, imgSrc: string): string => {
    if (imgSrc === CMS_SRC_PATH) {
      return '';
    }

    return FOLDERS_BY_IMAGE_TYPE[imageType] || '';
  }, []);

  const getFileEnding = useCallback((imageType: string, imgCode: string): string => {
    if (fileNameHasExtension(imgCode)) {
      return '';
    }

    return `.${imageType}`;
  }, []);

  const getMappedSize = useCallback(() => {
    if (imageSize === GameImageSize.SMALL) {
      return FILE_SIZE_MAP.get(TileSize.S);
    }

    if (imageSize === GameImageSize.MEDIUM) {
      return FILE_SIZE_MAP.get(TileSize.M);
    }

    return FILE_SIZE_MAP.get(TileSize.L);
  }, [imageSize]);

  const configureCustomImage = useCallback((customGameImgObject: CustomGameImage): void => {
    const source = customGameImgObject.source === ImageSource.S3
      ? `${gameImageSrc}/`
      : CMS_SRC_PATH;

    const imgCode = fileNameHasExtension(customGameImgObject.imageName)
      ? customGameImgObject.imageName
      : `${customGameImgObject.imageName}_${getMappedSize()}`;
    const webp = `${source}${getFolder('webp', source)}${imgCode}${getFileEnding('webp', imgCode)}`;
    const jpg = `${source}${getFolder('jpg', source)}${imgCode}${getFileEnding('jpg', imgCode)}`;

    setImageSrc(webp);
    setAlternateImageSrc(jpg);
  }, [gameImageSrc, getFileEnding, getFolder, getMappedSize]);

  const configureImageSource = useCallback(() => {
    if (!imageSize) {
      return;
    }

    const { assets } = gameAssets;
    if (!assets) {
      return;
    }

    setImageSrc(assets[imageSize] as string);
  }, [gameAssets, imageSize]);

  const handleError = useCallback(() => {
    if (!imageSize) {
      return;
    }

    setImageSrc(`${gameImageSrc}${PLACEHOLDERS[imageSize]}`);
    setShowImg(true);
    setIsVisible(false);
    setIsHandlingError(true);
  }, [gameImageSrc, imageSize]);

  useEffect(() => {
    if (imageSrc !== undefined && imageSize !== undefined) {
      return;
    }

    configureImageSize();

    const customGameImage = customGameImages
      ?.find((image) => image.gameCode === gameCode);

    const hastCustomImageAndIsNotAnimated = customGameImage
      && (
        !customGameImage.imageName.includes('.svg')
        && !customGameImage.imageName.includes('.gif')
      );

    if (customGameImage && hastCustomImageAndIsNotAnimated) {
      configureCustomImage(customGameImage);
      return;
    }

    configureImageSource();
  }, [
    configureCustomImage,
    configureImageSize,
    configureImageSource,
    customGameImages,
    gameCode,
    imageSize,
    imageSrc,
  ]);

  useEffect(() => {
    // visibility should not change on images that couldn't be loaded
    if (isHandlingError) {
      return;
    }

    /**
     * needed since not all pictures will be rendered in a horizontal scrollable container
     */
    if (Object.keys(horizontalScrollContext).length !== 0) {
      const inViewHorizontal = horizontalScrollContext.isInView(containerRect);
      setIsVisible(inViewHorizontal && inView);
      return;
    }

    setIsVisible(inView);
  }, [
    containerRect,
    horizontalScrollContext,
    horizontalScrollContext.scrollLeft,
    inView,
    isHandlingError,
  ]);

  return (
    <>
      <ImgFileType
        isVisible={showImg}
        src={imageSrc}
        alternateImageSrc={alternateImageSrc}
        className={className}
        customWidth={customWidth}
        skeletonHeight={skeletonHeight}
        isFavoriteOrRecentRolledUp={isFavoriteOrRecentRolledUp}
        handleError={handleError}
      >
        {children}
      </ImgFileType>

      <AnimatedPicture
        cdnSrc={gameImageSrc}
        gameCode={gameCode}
        customWidth={customWidth}
        className={className}
        skeletonHeight={skeletonHeight}
        isVisible={isVisible}
        isFavoriteOrRecentRolledUp={isFavoriteOrRecentRolledUp}
        shouldShowImg={setShowImg}
        handleError={handleError}
        customGameImages={customGameImages}
      >
        {children}
      </AnimatedPicture>
    </>
  );
}

Picture.defaultProps = {
  className: '',
  customWidth: 0,
  containerRect: undefined,
};

export default Picture;
