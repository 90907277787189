import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { GameCategoryItem, NavigationState, RootState } from '../interfaces';
import { KeyValuePair } from '../interfaces/KeyValuePair';
import { logger } from '../services';
import NavigationMethods from '../constants/navigation-methods';
import GAME_INFO_URL from '../constants/gameDetailsCode';
import eventBus, { eventTypes } from '../services/eventBus';

const useNavigation = () => {
  const searchString = useSelector((state: RootState) => state.search.searchString);
  const lobbyContainerCode = useSelector((state: RootState) => state.lobby.code);
  const getCurrentParams = useCallback((): any => (
    RSINavigationHandler.getCurrentLocation().params
  ), []);

  const getDoesObjectFieldsMatch = useCallback((
    model: object,
    comparable: object,
  ): boolean => {
    const modelKeys = (Object.keys(model) as (keyof typeof model)[]);
    const comparableKeys = (Object.keys(comparable) as (keyof typeof comparable)[]);
    if (modelKeys.length !== comparableKeys.length) {
      return false;
    }
    return modelKeys
      .every((modelKey) => comparableKeys
        .every((comparableKey) => modelKey === comparableKey
          && model[modelKey] === comparable[comparableKey]));
  }, []);

  const addParamToUrl = useCallback((
    key: string,
    value: string,
    method: NavigationMethods = NavigationMethods.PERFORM,
  ) => {
    const params = getCurrentParams();
    const updatingParams = { [key]: value };
    if (!getDoesObjectFieldsMatch(params, updatingParams)) {
      switch (method) {
        case NavigationMethods.PUSH: {
          RSINavigationHandler.push({ params: { ...params, ...updatingParams } });
          return;
        }
        case NavigationMethods.REPLACE: {
          RSINavigationHandler.replace({ params: { ...params, ...updatingParams } });
          return;
        }
        case NavigationMethods.PERFORM:
        default: {
          RSINavigationHandler.performNavigation({ params: { ...params, ...updatingParams } });
        }
      }
    }
  }, [getCurrentParams, getDoesObjectFieldsMatch]);

  const launchGame = useCallback((
    gameData: GameCategoryItem,
    gameMode: string,
    logData: KeyValuePair[],
  ) => {
    const logDataFinal = [
      { key: 'game id', value: gameData.code },
      { key: 'game name', value: gameData.name },
      { key: 'game integration provider', value: gameData.integrationProvider },
      { key: 'game mode', value: gameMode },
      { key: 'search', value: searchString },
      { key: 'lobby container', value: lobbyContainerCode },
    ]
      .concat(logData)
      .filter((data) => data.value)
      .map((data) => `${data.key}: '${data.value}'`)
      .join(', ');
    logger.log(`Attempt to launch a game from casino lobby, ${logDataFinal}`);

    rsiApi.trigger(rsiApi.getEvent('LAUNCH_GAME'), {
      gameCode: gameData.code,
      gameProvider: gameData.gameProvider,
      gameName: gameData.name,
      gamePortrait: gameData.portrait,
      gameLandscape: gameData.landscape,
      gameMobileRatio: gameData.mobileRatio,
      gameDesktopRatio: gameData.desktopRatio,
      integrationProvider: gameData.integrationProvider,
      rgsProvider: gameData.rgsProvider,
      androidExternal: gameData.androidExternal,
      iosExternal: gameData.iosExternal,
      gameMode,
    });
  }, [searchString, lobbyContainerCode]);

  const navigateToPage = useCallback((page?: string, method?: string): void => {
    // Check if we are navigating to the same page or from a blocked page
    if (!page) {
      return;
    }
    switch (method) {
      case 'push': {
        RSINavigationHandler.push(page);
        return;
      }
      case 'replace': {
        RSINavigationHandler.replace(page);
        return;
      }
      default: {
        RSINavigationHandler.performNavigation(page);
      }
    }
  }, []);

  // opens game details card microfrontend
  const showGameDetailsModal = useCallback((
    gameData: GameCategoryItem,
    logData: KeyValuePair[],
  ) => {
    const currentParams = getCurrentParams();
    /*
      The next condition handles the scenario where the user closes the modal sheet and immediately
      attempts to open a new game tile. In this case, the URL may already contain new gameInfo,
      but the isModalSheetOpened state has not been updated yet because the
      BOTTOM_SHEET_MODAL_CLOSED event has not triggered the update of the isModalSheetOpened state
    */
    if (currentParams[GAME_INFO_URL]) {
      let hasTriggeredNav = false;
      let hasTriggeredCloseEvent = false;

      const checkAndHandleEvents = () => {
        if (hasTriggeredNav && hasTriggeredCloseEvent) {
          /*
            setTimeout is required because without it, there will be a concurrency issue with two
            events: BOTTOM_SHEET_MODAL_OPENED and BOTTOM_SHEET_MODAL_CLOSED. As a result, the
            URL will be updated and OPEN_BOTTOM_SHEET_MODAL will be published, but
            BOTTOM_SHEET_MODAL_OPENED will not be handled
          */
          setTimeout(() => {
            cleanupSubscriptions();
            addParamToUrl(GAME_INFO_URL, gameData.code, NavigationMethods.PUSH);
            hasTriggeredNav = false;
            hasTriggeredCloseEvent = false;
          }, 0);
        }
      };

      const handleNavigationEvent = ({ to }: NavigationState) => {
        if (!(to?.params[GAME_INFO_URL])) {
          hasTriggeredNav = true;
          checkAndHandleEvents();
        }
      };

      const handleBottomSheetClosed = () => {
        hasTriggeredCloseEvent = true;
        checkAndHandleEvents();
      };

      /*
        There should be two subscriptions: one for the Navigation event and another for the
        BOTTOM_SHEET_MODAL_CLOSED event. This is necessary to ensure that the URL has been
        changed and the modal bottom sheet has been closed
      */
      const navigationSubscription = RSINavigationHandler.subscribe(handleNavigationEvent);
      const closeBottomSheetModalSubscription = eventBus.subscribe(
        eventTypes.BOTTOM_SHEET_MODAL_CLOSED,
        handleBottomSheetClosed,
      );

      const cleanupSubscriptions = () => {
        navigationSubscription();
        closeBottomSheetModalSubscription.unsubscribe();
      };
    } else {
      addParamToUrl(GAME_INFO_URL, gameData.code, NavigationMethods.PUSH);
      const logDataFinal = [
        { key: 'game code', value: gameData.code },
        { key: 'game name', value: gameData.name },
        { key: 'search', value: searchString },
        { key: 'lobby container', value: lobbyContainerCode },
      ]
        .concat(logData)
        .filter((data) => data.value)
        .map((data) => `${data.key}: '${data.value}'`)
        .join(', ');
      logger.log(`Game details opened from casino lobby, ${logDataFinal}`);
    }
  }, [lobbyContainerCode, searchString, addParamToUrl, getCurrentParams]);

  const removeParamFromUrl = useCallback((key: string) => {
    if (!key) {
      return;
    }
    const params = getCurrentParams();
    let paramsChanged = false;

    if (key in params) {
      paramsChanged = true;
      delete params[key];
    }
    if (paramsChanged) {
      RSINavigationHandler.performNavigation({ params: { ...params } });
    }
  }, [getCurrentParams]);

  const getParamFromUrl = useCallback((key: string) => getCurrentParams()[key], [getCurrentParams]);

  return {
    launchGame,
    navigateToPage,
    removeParamFromUrl,
    getParamFromUrl,
    addParamToUrl,
    showGameDetailsModal,
  };
};

export default useNavigation;
