enum ApplicationActionTypes {
  SET_RSI_API_STATUS= 'application/SET_RSIAPI_STATUS',
  SET_FORMAT= 'application/SET_FORMAT',
  SET_CLIENT_TYPE= 'application/SET_CLIENT_TYPE',
  SET_DEVICE_TYPE= 'application/SET_DEVICE_TYPE',
  SET_WIDTH= 'application/SET_WIDTH',
  SET_ORIENTATION= 'application/SET_ORIENTATION',

  DEFAULT = 'configs/DEFAULT',
}

export default ApplicationActionTypes;
