import React, { ReactElement, useCallback, useState } from 'react';
import SkeletonLoader from '../../../Loading/SkeletonLoader';
import ImgVisibilityWrapper from '../Shared/ImgVisibilityWrapper';
import PictureTag from './PictureTag';
import ChildrenWithErrorHandler from '../Shared/ChildrenWithErrorHandler';

interface ImgFileTypeProps {
  isVisible: boolean;
  src: string | undefined;
  children: ReactElement;
  className?: string;
  customWidth?: number;
  skeletonHeight?: number;
  isFavoriteOrRecentRolledUp: boolean;
  handleError: Function;
  alternateImageSrc?: string;
}

function ImgFileType({
  isVisible,
  src,
  alternateImageSrc,
  children,
  className,
  customWidth,
  skeletonHeight,
  isFavoriteOrRecentRolledUp,
  handleError,
}: ImgFileTypeProps) {
  const [imgLoaded, setImgLoaded] = useState<boolean>(false);

  const handleImageLoaded = useCallback(() => {
    if (!imgLoaded) {
      setImgLoaded(true);
    }
  }, [imgLoaded]);

  return (
    <ImgVisibilityWrapper data-testid="image-wrapper" isVisible={isVisible}>
      {!imgLoaded && (
        <SkeletonLoader width={customWidth} height={skeletonHeight} hasAspectRatio />
      )}
      <PictureTag
        className={className}
        handleImageLoaded={handleImageLoaded}
        hasSkeleton={!imgLoaded && isFavoriteOrRecentRolledUp}
        skeletonHeight={skeletonHeight}
      >
        <source srcSet={src} type="image/webp" />
        {alternateImageSrc && (
          <source srcSet={alternateImageSrc} type="image/jpeg" />
        )}
        <ChildrenWithErrorHandler handleError={handleError}>
          {children}
        </ChildrenWithErrorHandler>
      </PictureTag>
    </ImgVisibilityWrapper>
  );
}

ImgFileType.defaultProps = {
  className: '',
  customWidth: 0,
  skeletonHeight: undefined,
  alternateImageSrc: undefined,
};

export default ImgFileType;
