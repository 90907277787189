import React, {
  ReactElement, useCallback, useEffect, useState,
} from 'react';
import { CustomGameImage, ImageSource } from '../../../interfaces';
import { CMS_SRC_PATH } from '../../../constants/image-variables';
import AnimatedFileType from './FileTypes/AnimatedFileType';

interface AnimatedPictureProps {
  gameCode: string;
  children: ReactElement;
  className?: string;
  customWidth?: number;
  skeletonHeight?: number;
  isFavoriteOrRecentRolledUp: boolean;
  isVisible: boolean;
  shouldShowImg: Function;
  handleError: Function;
  customGameImages: CustomGameImage[] | null;
  cdnSrc?: string;
}

type ReadOnlyAnimatedPictureProps = Readonly<AnimatedPictureProps>;

function AnimatedPicture({
  cdnSrc,
  gameCode,
  children,
  className,
  customWidth,
  skeletonHeight,
  isFavoriteOrRecentRolledUp,
  isVisible,
  shouldShowImg,
  handleError,
  customGameImages,
}: ReadOnlyAnimatedPictureProps) {
  const [src, setSrc] = useState<string | null>(null);
  const [code, setCode] = useState<string | null>(null);
  const [type, setType] = useState<'svg' | 'gif'>('svg');

  const configImage = useCallback((
    customGameImgObject: CustomGameImage | undefined,
  ): void => {
    if (!customGameImgObject) {
      return;
    }

    if (
      customGameImgObject.source === ImageSource.CMS
      && (customGameImgObject.imageName.includes('.svg') || customGameImgObject.imageName.includes('.gif'))
    ) {
      setSrc(CMS_SRC_PATH);
      setCode(customGameImgObject.imageName);
      setType(customGameImgObject.imageName.includes('.svg') ? 'svg' : 'gif');
    }
  }, []);

  const showImg = useCallback(() => {
    if (!code) {
      shouldShowImg(true);
      return;
    }

    shouldShowImg(!isVisible);
  }, [code, shouldShowImg, isVisible]);

  useEffect(() => {
    if (!customGameImages || !cdnSrc) {
      return;
    }

    const customGameImage = customGameImages
      ?.find((image) => image.gameCode === gameCode);

    configImage(customGameImage);
    showImg();
  }, [customGameImages, gameCode, cdnSrc, configImage, showImg]);

  if (!src || !code || code?.includes('undefined')) {
    return null;
  }

  return (
    <AnimatedFileType
      isFavoriteOrRecentRolledUp={isFavoriteOrRecentRolledUp}
      isVisible={isVisible}
      src={src}
      code={code}
      className={className}
      customWidth={customWidth}
      skeletonHeight={skeletonHeight}
      handleError={handleError}
      type={type}
    >
      {children}
    </AnimatedFileType>
  );
}

AnimatedPicture.defaultProps = {
  className: '',
  customWidth: 0,
  skeletonHeight: 0,
  cdnSrc: undefined,
};

export default AnimatedPicture;
