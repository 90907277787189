import React, { useRef } from 'react';
import styled from 'styled-components';
import useLazyLoadingLobbyComponents from '../../../hooks/useLazyLoadingLobbyComponents';
import { LobbyComponent } from '../../../interfaces';
import LobbyComponentVS from '../../LobbyComponent/LobbyComponentVS';

interface LobbyLazyLoadingProperties {
  components: LobbyComponent[],
}

function LobbyLazyLoading({ components }: LobbyLazyLoadingProperties) {
  const loadingRefPoint = useRef(null);
  const componentsQty = components.length;
  const numberOfVisibleComponents = useLazyLoadingLobbyComponents(loadingRefPoint, componentsQty);
  const allComponentsLoaded = numberOfVisibleComponents >= componentsQty;

  const lobbyComponentHandler = components
    .slice(0, numberOfVisibleComponents)
    .map((component, index) => (
      <LobbyComponentVS
        key={component.code + component.type}
        component={component}
        lastChild={index + 1 === componentsQty}
      />
    ));

  return (
    <>
      {lobbyComponentHandler}

      {!allComponentsLoaded && (
        <RefLoadingPoint
          ref={loadingRefPoint}
          id="ref-loading-point"
          data-testid="ref-loading-point"
        />
      )}
    </>
  );
}

const RefLoadingPoint = styled.div`
  height: 50px;
  overflow-anchor: none;
`;

export default LobbyLazyLoading;
