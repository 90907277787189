import { AnyAction } from 'redux';
import { ApplicationActionTypes } from '../actionTypes';
import { DeviceType } from '../../interfaces/DeviceType';
import { ClientType } from '../../interfaces/ClientType';

export const setRsiApiStatus = (payload: boolean): AnyAction => ({
  type: ApplicationActionTypes.SET_RSI_API_STATUS,
  payload,
});

export const setFormat = (payload: any): AnyAction => ({
  type: ApplicationActionTypes.SET_FORMAT,
  payload,
});

export const setDeviceType = (payload: DeviceType): AnyAction => ({
  type: ApplicationActionTypes.SET_DEVICE_TYPE,
  payload,
});

export const setClientType = (payload: ClientType): AnyAction => ({
  type: ApplicationActionTypes.SET_CLIENT_TYPE,
  payload,
});

export const setWidth = (width: number): AnyAction => ({
  type: ApplicationActionTypes.SET_WIDTH,
  payload: width,
});

export const setOrientation = (orientation: string): AnyAction => ({
  type: ApplicationActionTypes.SET_ORIENTATION,
  payload: orientation,
});
