/* eslint-disable max-len */
import { useCallback, useEffect, useRef } from 'react';
import { useSelector, useStore } from 'react-redux';
import {
  GameCategoryData,
  GameCategoryUpdateType,
  LobbyComponentType,
  RootState,
  LobbyComponent,
  GameCategoryChangedMessage,
  Pages,
} from '../interfaces';
import { lobbyActions, searchActions } from '../store/actions';
import {
  RECENT_AND_FAVORITES_SOURCES_TYPES,
} from '../constants/category-variables';
import { useTypedDispatch } from '../store';
import useNavigation from './useNavigation';
import { logger } from '../services';
import doesIncludeRecentOrFavoriteSource from '../utils/doesIncludeRecentOrFavoriteSource';
import eventBus, { eventTypes } from '../services/eventBus';
import useGameListUpdateThrottle from './useGameListUpdateThrottle';

/**
 * Handles game list updates based on RSI events. A new list of games is fetched from server
 * immediately. Quite often the list doesn't change because hidden games were modified and players
 * do not see those. In this case the list will not be updated (in reducer) to avoid unnecessary
 * DOM re-rendering.
 *
 * The update task is meant to be run as background job, therefore some store values are taken
 * directly from state (useStore hook and not via useSelect) to reduce re-rendering visual
 * components. Event listeners are set only once.
 *
 * Supported events:
 * - GAME_LIST_UPDATE: games have been modified in BO (flags, hidden etc.)
 * - LOGIN/LOGOUT: player has logged in/out
 * - CLOSE_GAME/NATIVE_GAME_CLOSE: player's recent and favorite list might have been changed
 * (recent games)
 * - FAVORITES_PROFILE_UPDATED: player has (un)favourite a game, whole process is done by legacy
 * portal favorite functionality, casino lobby just displays the result or triggers a game to be
 * (un)favorite. Event comes only for logged in players.
 *
 * @param lobbyId default lobby code used for not authenticated players and players who don't have
 * a special lobby set for them
 */

let gameCloseSubscriber: Subscriber;
let gameNativeCloseSubscriber: Subscriber;

export default (lobbyId: string): void => {
  const store = useStore<RootState>();
  const dispatch = useTypedDispatch();
  const clientType = useSelector((state: RootState) => state.application.clientType);
  const isUserLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const isFavoritesInitializedRef = useRef<boolean>(false);
  const personalizedLobbyCode = useRef<string>(lobbyId);
  const { getParamFromUrl } = useNavigation();

  const getPageCounts = (totalItemsCount: number, batchSize: number = 1000): number[] => Array.from({ length: Math.ceil(totalItemsCount / batchSize) }, (_, i) => {
    const leftover = totalItemsCount - i * batchSize;
    return leftover > batchSize ? batchSize : leftover;
  });

  const handleGameListUpdated = useCallback(
    (resetLastCategory: boolean) => {
      if (!clientType || !lobbyId) {
        return;
      }

      dispatch(lobbyActions.getLobby(personalizedLobbyCode.current, clientType, resetLastCategory));

      const expCategory = store.getState().lobby.expandedComponentId;
      if (expCategory) {
        const total: number = store.getState().lobby.expandedCategoryGames?.games.items.length || 0;
        const pages: number[] = getPageCounts(total);
        pages.forEach((count, index) => {
          dispatch(
            lobbyActions.getExpandedCategoryGames(
              expCategory,
              clientType,
              GameCategoryUpdateType.OnlyExpCategory,
              index + 1,
              count,
            ),
          );
        });
      }

      const { searchString } = store.getState().search;
      if (searchString) {
        dispatch(searchActions.getSearchGames(searchString, clientType, 1, 1000));
      }
    },
    [clientType, dispatch, lobbyId, store],
  );

  /**
   * Updates the list of games in player specific category like MY_GAMES of RECENT. The update
   * happens in
   *  - lobby, only if the category is not expanded/opened. Other categories in lobby will be
   *  unaffected
   *  - expanded category for every paging set if it is opened and in lobby for the first paging
   *  (for updating pageSize games in the main lobby view in that category)
   */
  const updatePlayerSpecificCategoryList = useCallback((type: RECENT_AND_FAVORITES_SOURCES_TYPES) => {
    if (!clientType) {
      return;
    }

    const playerLobbyCategory = store.getState().lobby.components
      .find((comp: LobbyComponent) => comp.type === LobbyComponentType.GameCategory
        && comp.data?.sourceType === type);

    let expCategory: GameCategoryData | null = store.getState().lobby.expandedCategoryGames;
    if (expCategory && expCategory.sourceType !== type) {
      expCategory = null;
    }

    // only lobby
    if (playerLobbyCategory && !expCategory) {
      dispatch(lobbyActions.getExpandedCategoryGames(
        playerLobbyCategory.code,
        clientType,
        GameCategoryUpdateType.OnlyLobby,
        1,
        playerLobbyCategory.data.games.paging.pageSize,
      ));
      return;
    }

    if (expCategory) {
      const gamesCount: number = expCategory.games.items.length || 0;
      const pages: number[] = getPageCounts(gamesCount);
      const expCatCode: string = store.getState().lobby.expandedComponentId;
      pages.forEach((count, index) => {
        const updateType = (index === 0 && playerLobbyCategory)
          ? GameCategoryUpdateType.LobbyAndExpandedCategory
          : GameCategoryUpdateType.OnlyExpCategory;
        dispatch(lobbyActions.getExpandedCategoryGames(
          expCatCode,
          clientType,
          updateType,
          index + 1,
          count,
        ));
      });
    }
  }, [clientType, dispatch, store]);

  /**
   * Player's favorites list updated and received. Event is dispatched also when player logs in, in
   * that case there is no need to re-fetch recent or favourite as the whole lobby is re-fetched
   * anyway.
   * NB! The same instance of modified array list is sent from legacy portal.
   */
  const handleFavoriteGamesUpdated = useCallback(
    (games: string[]): void => {
      dispatch(lobbyActions.setFavoriteGames([...games]));
      if (!isFavoritesInitializedRef.current) {
        isFavoritesInitializedRef.current = true;
        return;
      }
      updatePlayerSpecificCategoryList('FAVORITES');
      updatePlayerSpecificCategoryList('MY_GAMES');
    },
    [dispatch, updatePlayerSpecificCategoryList, isFavoritesInitializedRef],
  );

  /**
   * Handles player's logout:
   * - favorites are reset and the whole lobby game list re-fetched
   * - recent or favorite expanded categories are closed (player is taken to main lobby)
   * - recent or favorite categories removed from search back button action
   */
  const handleLogout = useCallback((): void => {
    dispatch(lobbyActions.setFavoriteGames([]));
    isFavoritesInitializedRef.current = false;

    const expCategory: GameCategoryData | null = store.getState().lobby.expandedCategoryGames;
    if (expCategory && doesIncludeRecentOrFavoriteSource(expCategory.sourceType)) {
      dispatch(lobbyActions.setExpandedCategory(null));
    }
    const prevCat = store.getState().search.prevExpCatType;
    if (doesIncludeRecentOrFavoriteSource(prevCat)) {
      dispatch(searchActions.setPreviousExpandedCategory(null));
    }

    handleGameListUpdated(true);
  }, [dispatch, handleGameListUpdated, store]);

  /**
   * recent or favorite categories need to be updated only for logged in players. The GAME_CLOSE
   * event is also dispatched when player logs out (even when no games were opened, perhaps to close
   * bingo games) but the logout event comes later, therefore a direct check to rsiApi.isLoggedIn()
   * is done.
   */
  const handleGameClose = useCallback((): void => {
    if (rsiApi.isLoggedIn()) {
      updatePlayerSpecificCategoryList('RECENT');
      updatePlayerSpecificCategoryList('MY_GAMES');
    }
  }, [updatePlayerSpecificCategoryList]);

  const handleCategoriesListUpdated = useCallback(() => {
    dispatch(lobbyActions.updateTranslations());
  }, [dispatch]);

  const gameListUpdateSubscription = useCallback(() => {
    handleGameListUpdated(false);
  }, [handleGameListUpdated]);

  const gameCategoryChangedSubscription = useCallback(
    (message: GameCategoryChangedMessage) => {
      handleCategoriesListUpdated();
      handleGameListUpdated(false);
      if (RSINavigationHandler.getCurrentPage() !== Pages.ALL_GAMES || getParamFromUrl('game')) {
        logger.log('[WSS] game category update', { type: 'GAME_CATEGORY_CHANGED', message });
      }
    },
    [getParamFromUrl, handleCategoriesListUpdated, handleGameListUpdated],
  );

  const lobbyContainerChangedSubscription = useCallback(() => {
    handleCategoriesListUpdated();
    handleGameListUpdated(true);
  }, [handleCategoriesListUpdated, handleGameListUpdated]);

  const loginSubscription = useCallback(() => {
    personalizedLobbyCode.current = rsiApi.getPlayerDataByField('RO_CASINO_LOBBY_CODE') || lobbyId;
    handleGameListUpdated(true);
  }, [lobbyId, handleGameListUpdated]);

  const logoutSubscription = useCallback(() => {
    personalizedLobbyCode.current = lobbyId;
    handleLogout();
  }, [lobbyId, handleLogout]);

  const handleLoginStateChange = useCallback(({ isLoggedIn }: { isLoggedIn: boolean }): void => {
    if (isUserLoggedIn === isLoggedIn) {
      return;
    }
    if (isLoggedIn) {
      loginSubscription();
    } else {
      logoutSubscription();
    }
  }, [isUserLoggedIn, loginSubscription, logoutSubscription]);

  useGameListUpdateThrottle(gameListUpdateSubscription);

  useEffect(() => {
    const favoritesProfileSubscription = eventBus.subscribe(
      eventTypes.CASINO_FAVORITES_PROFILE_UPDATED,
      handleFavoriteGamesUpdated,
    );
    return () => {
      favoritesProfileSubscription.unsubscribe();
    };
  }, [handleFavoriteGamesUpdated]);

  useEffect(() => {
    rsiApi.getPushService().on('GAME_CATEGORY_CHANGED', gameCategoryChangedSubscription);
    rsiApi.getPushService().on('LOBBY_CONTAINER_CHANGED', lobbyContainerChangedSubscription);
    gameCloseSubscriber = eventBus.subscribe(eventTypes.CLOSE_GAME, handleGameClose);
    gameNativeCloseSubscriber = eventBus.subscribe(
      eventTypes.NATIVE_GAME_CLOSE,
      handleGameClose,
    );
    const playerLoginStateSubscription = eventBus.subscribe(
      eventTypes.PORTAL_LOGIN_STATE_CHANGE,
      handleLoginStateChange,
    );

    return () => {
      rsiApi.getPushService().off('GAME_CATEGORY_CHANGED', gameCategoryChangedSubscription);
      rsiApi.getPushService().off('LOBBY_CONTAINER_CHANGED', lobbyContainerChangedSubscription);
      gameCloseSubscriber.unsubscribe();
      gameNativeCloseSubscriber.unsubscribe();
      playerLoginStateSubscription.unsubscribe();
      // no need to initialize subscriptions on lobbyId update
    };
  }, [
    handleLoginStateChange,
    handleGameClose,
    gameCategoryChangedSubscription,
    gameListUpdateSubscription,
    lobbyContainerChangedSubscription,
  ]);
};
