/* eslint-disable import/prefer-default-export */
/**
 * Special game category source that has a custom logic and differs from the rest of the categories
 * but is the same as FAVORITES and RECENT.
 */
export const MY_GAMES_SOURCE: string = 'MY_GAMES';

/**
 * Special game category source that has a custom logic and differs from the rest of the categories
 * but is the same as RECENT and MY_GAMES.
 */
export const FAVORITES_SOURCE: string = 'FAVORITES';

/**
 * Special game category source that has a custom logic and differs from the rest of the categories
 * but is the same as FAVORITES and MY_GAMES.
 */
export const RECENT_SOURCE: string = 'RECENT';

/**
 * Special sources have an additional button with game details and could be launched right after
 * the first click on the game tile
 */
export const RECENT_AND_FAVORITES_SOURCES: string[] = [
  MY_GAMES_SOURCE,
  FAVORITES_SOURCE,
  RECENT_SOURCE,
];

export type RECENT_AND_FAVORITES_SOURCES_TYPES = 'MY_GAMES' | 'FAVORITES' | 'RECENT';

export const PADDING_Y_GAME_CATEGORY_WITHOUT_JACKPOT = 8;

export const GAME_TILE_BORDER_RADIUS = 5;

export const RIBBON_TOP_POSITION = 3;
