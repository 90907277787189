import React, { ReactElement, useCallback } from 'react';

interface ChildrenWithCallbackProps {
  children: ReactElement;
  handleError: Function;
}

function ChildrenWithErrorHandler({
  children,
  handleError,
}: ChildrenWithCallbackProps) {
  const onError = useCallback((element: { target: HTMLDivElement }) => {
    // eslint-disable-next-line no-param-reassign
    element.target.onerror = null;
    handleError();
  }, [handleError]);

  const childrenWithProps = React.Children.map(children, (child) => {
    if ((child.type as any).target === 'img') {
      return React.cloneElement(child, { onError });
    }

    return child;
  });

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {childrenWithProps}
    </>
  );
}

export default ChildrenWithErrorHandler;
