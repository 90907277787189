import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../interfaces';
import { colors } from '../../../constants/style-variables';
import { priorityRibbon } from '../../../utils/ribbonsHelper';
import { RIBBON_TOP_POSITION } from '../../../constants/category-variables';

interface RibbonsProperties {
  className?: string;
  flags: string[];
}

function RibbonsComponent({ className, flags }: RibbonsProperties) {
  const clientType = useSelector((state: RootState) => state.application.clientType);
  const [activeRibbon, setActiveRibbon] = useState<string | null>(null);
  const bgClass = (ribbon: string): string => {
    switch (ribbon) {
      case 'HIDDEN': {
        return 'purple';
      }
      case 'EXCLUSIVE': {
        return 'yellow';
      }
      case 'NEW':
      case 'FEATURED': {
        return 'red';
      }
      default: {
        return 'grey';
      }
    }
  };

  useEffect(() => {
    if (!clientType) {
      return;
    }
    if (flags.length > 0) {
      const supportedFlag = priorityRibbon(flags, clientType);
      if (supportedFlag) {
        setActiveRibbon(supportedFlag);
      }
    } else {
      setActiveRibbon(null);
    }
  }, [flags, clientType]);

  if (!activeRibbon) {
    return null;
  }
  return (
    <div className={`${className} ${bgClass(activeRibbon)}`}>
      <Text><FormattedMessage id={activeRibbon} /></Text>
    </div>
  );
}

RibbonsComponent.defaultProps = {
  className: undefined,
};

const Text = styled.span`
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: 700;
  font-family: "DM Sans";
  font-size: 10px;
  line-height: 13px;
`;

const Ribbons = styled(RibbonsComponent)`
  position: absolute;
  top: -${RIBBON_TOP_POSITION}px;
  display: flex;
  height: 13px;
  max-width: 100%;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, .1);
  border-radius: 4px 4px 4px 0px;
  padding: 0px 5px;
  z-index: 1;

  &.red {
    color: ${colors.white};
    background: ${colors.red};
  }

  &.yellow {
    color: ${colors.blueDark};
    background: ${colors.yellow};
  }

  &.purple {
    color: ${colors.white};
    background: ${colors.purple};
  }

  &.grey {
    color: ${colors.blueDark};
    background: ${colors.grayBlueDark};
  }

`;

export const DetailsRibbons = styled(RibbonsComponent)`
  background: ${colors.blue3};
  border-radius: 4px;
  text-align: center;
  font-weight: 700;
  font-family: monospace;
  font-size: 12px;
  padding: 0 12px;
  line-height: 16px;

  &.red {
    color: ${colors.white};
    background: ${colors.red};
    border: 1px solid ${colors.red};
  }

  &.yellow {
    color: ${colors.blueDark};
    background: ${colors.yellow};
    border: 1px solid ${colors.yellow};
  }

  &.purple {
    color: ${colors.white};
    background: ${colors.purple};
    border: 1px solid ${colors.purple};
  }

  &.grey {
    color: ${colors.blueDark};
    background: ${colors.grayBlueDark};
    border: 1px solid ${colors.grayBlueDark};
  }

`;

export default Ribbons;
