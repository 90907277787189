import React from 'react';
import styled from 'styled-components';
import { DetailedGame } from '../../../../interfaces';
import ArrowLeftButton from '../../../rsi/Button/ArrowLeftButton';
import { colors } from '../../../../constants/style-variables';

interface GameDetailsHeaderMobileProperties {
  detailedGame: DetailedGame;
  onExitClick: any;
}

// eslint-disable-next-line max-len
function GameDetailsHeaderMobileComponent({ detailedGame, onExitClick }: GameDetailsHeaderMobileProperties) {
  return (
    <HeaderRow>
      <BackButton onClick={onExitClick} />
      <HeaderTitle>
        {detailedGame.item.name}
      </HeaderTitle>
    </HeaderRow>
  );
}

const GameDetailsHeaderMobile = styled(GameDetailsHeaderMobileComponent)`
  width: 100%;
  display: block;
  padding-bottom: 5px;
`;

const HeaderRow = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  position: relative;
`;

const BackButton = styled(ArrowLeftButton)`
  position: absolute;
  z-index: 1;
  width: 54px;
  margin-left: -20px;
  font-size: 30px;
  height: 30px;
`;

const HeaderTitle = styled.span`
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  position: relative;
  color: ${colors.black};
  padding: 0px 16px;
  text-align: center;
  width: 100%;

  @media only screen and (orientation: landscape) {
    text-align: left;
    padding: 0;
    margin-left: 25px;
  }
`;

export default GameDetailsHeaderMobile;
