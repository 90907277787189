import { useEffect } from 'react';
import useNavigation from './useNavigation';

export default () => {
  const rsiApiAvailable = rsiApi.isLoaded();
  const { navigateToPage, getParamFromUrl } = useNavigation();

  useEffect(() => {
    if (rsiApiAvailable) {
      rsiApi.on(rsiApi.getEvent('TOGGLE_CASINO_QUICK_LAUNCH'), () => {
        if (getParamFromUrl('game')) {
          navigateToPage('?page=all-games', 'replace');
        }
      });
    }
  }, [getParamFromUrl, navigateToPage, rsiApiAvailable]);
};
