import React from 'react';
import styled from 'styled-components';

function BubbleComponent({ className }: {className?: string}) {
  return <div className={className} />;
}

BubbleComponent.defaultProps = {
  className: undefined,
};

const BubbleDesktop5 = styled(BubbleComponent)`
  position: absolute;
  width: 77px;
  height: 77px;
  background: radial-gradient(50% 50% at 50% 50%, rgb(129, 79, 20) 0%, rgba(129, 79, 20, 0.58) 38%, rgba(129, 79, 20, 0.27) 68%, rgba(129, 79, 20, 0.07) 89%, rgba(129, 79, 20, 0) 100%);
  border-radius: 14px;
  transform: matrix(0.98, -0.2, -0.2, -0.98, 0, 0);
  background-blend-mode: screen;
  mix-blend-mode: screen;
  top: 140px;
  right: 60px;
`;

export default BubbleDesktop5;
