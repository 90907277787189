import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { JackpotPotType, RootState } from '../../../interfaces';
import Ticker from './Ticker';
import { getArrayOfDigitsForTicker } from './DigitContainer/DigitContainer';
import { useJackpotGameTicker } from '../../../hooks';
import { findTickerByGameCode } from '../../../hooks/useJackpotsManager';
import { VerticalVirtualScrollContext } from '../../rsi/Scroll/VerticalVirtualScroll';

interface TickerProperties {
  categoryCode?: string;
  className?: string;
}
/**
 * TickerDataContainer gets the data from the state and prepares it for Ticker component
 *
 * Data is based on a gameCode that takes from the first game in the category
 *
 * The hook useJackpotCategory category helps to understand whether it is a jackpot category or not
 *
 */
function TickerDataContainer({
  className,
  categoryCode,
}: TickerProperties) {
  const inView = useContext(VerticalVirtualScrollContext);
  const category = useSelector((state: RootState) => state.lobby.components)
    ?.find((component) => component.code === categoryCode);
  const gameCode = category?.data?.games?.items[0]?.code;
  const rsiFormat = useSelector((state: RootState) => state.application.rsiFormat);
  const [pauseJackpot, setPauseJackpot] = useState<boolean>(false);
  const dailyJackpot = getArrayOfDigitsForTicker(
    useJackpotGameTicker(
      gameCode,
      JackpotPotType.DAILY,
      pauseJackpot,
    ),
    rsiFormat,
  ).join('');
  const hourlyJackpot = getArrayOfDigitsForTicker(
    useJackpotGameTicker(
      gameCode,
      JackpotPotType.HOURLY,
      pauseJackpot,
    ),
    rsiFormat,
  ).join('');
  const isDailyHot = Boolean(findTickerByGameCode(gameCode, JackpotPotType.DAILY)?.isHot);
  const isHourlyHot = Boolean(findTickerByGameCode(gameCode, JackpotPotType.HOURLY)?.isHot);

  useEffect(() => {
    /**
     * we must handle the pause jackpot flag in a different state, so we can have at least
     * an initial value for it and the rendering process looks smooth for the user
     */
    setPauseJackpot(!inView);
  }, [inView]);

  return (
    <Ticker
      categoryCode={categoryCode}
      className={className}
      dailyJackpot={dailyJackpot}
      hourlyJackpot={hourlyJackpot}
      isDailyHot={isDailyHot}
      isHourlyHot={isHourlyHot}
      pauseJackpot={pauseJackpot}
    />
  );
}

TickerDataContainer.defaultProps = {
  className: undefined,
  categoryCode: undefined,
};

export default React.memo(TickerDataContainer);
