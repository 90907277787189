import { useSelector } from 'react-redux';
import { RootState } from '../interfaces';

interface VisibilityControllerProperties {
  containerRef: HTMLDivElement | null;
}

/**
 * Takes care of visually hiding and showing main lobby. All main lobby DOM elements remain
 * unchanged and running in background. This is required because of external components, like
 * banners and newsfeed, that are initialised again every time when they are removed and added
 * back to DOM.
 *
 * This functionality could be in lobby component, inside useEffect. But store useSelect causes
 * unnecessary re-renderings, therefore it is moved into a separate component.
 */
function LobbyVisibilityController({ containerRef }: VisibilityControllerProperties) {
  const expandedComponentId = useSelector((state: RootState) => state.lobby.expandedComponentId);
  const searchVisible = useSelector((state: RootState) => state.search.searchVisible);

  if (containerRef) {
    const styleValue = (expandedComponentId || searchVisible) ? 'none' : 'block';
    containerRef.style.setProperty('display', styleValue);
  }

  return null;
}

export default LobbyVisibilityController;
