/**
 * Handles updates of games category data for reducers (expanded category and search pages).
 *
 * Events that trigger the update:
 * - infinite scroll/lazy loading of a new set of games (the next page). The new set of games is
 * added to the previous games list. Page data is also updated.
 * - game list updated (WSS event). The corresponding subset of games (both old and new) are
 * checked. No changes if the subsets match.
 *
 * @param previous games data of previous/current state
 * @param updated new games data for the state
 * @return null updated games data if anything in the games list has changed, otherwise null
 */
import { GameCategoryItem, GameItemsList } from '../interfaces';

// eslint-disable-next-line max-len
const gamesStateHandler = (previous: GameItemsList | null, updated: GameItemsList): GameItemsList | null => {
  if (!previous) {
    return updated;
  }

  if (previous.paging.page < updated.paging.page
    && previous.paging.pageSize === updated.paging.pageSize) {
    return {
      items: previous.items.concat(updated.items),
      paging: updated.paging,
    };
  }

  const startIdx: number = updated.paging.pageSize * (updated.paging.page - 1);
  const endIdx: number = startIdx + updated.paging.pageSize;
  const prevSubset: GameCategoryItem[] = previous.items.slice(startIdx, endIdx);
  const noChangeInGamesView: boolean = JSON.stringify(prevSubset) === JSON.stringify(updated.items);

  if (noChangeInGamesView && previous.paging.totalElements === updated.paging.totalElements) {
    return null;
  }

  const result = {
    items: previous.items.concat([]),
    paging: { ...previous.paging },
  };

  if (!noChangeInGamesView) {
    result.items.splice(startIdx, updated.items.length, ...updated.items);
    if (result.items.length > updated.paging.totalElements) {
      const countToRemove = result.items.length - updated.paging.totalElements;
      result.items.splice(updated.paging.totalElements, countToRemove);
    }
  }

  result.paging.totalElements = updated.paging.totalElements;
  result.paging.totalPages = updated.paging.totalPages;
  result.paging.page = updated.paging.page;

  return result;
};

export default gamesStateHandler;
