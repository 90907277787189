import React from 'react';
import styled from 'styled-components';

function BubbleComponent({ className }: {className?: string}) {
  return <div className={className} />;
}

BubbleComponent.defaultProps = {
  className: undefined,
};

const BubbleDesktop10 = styled(BubbleComponent)`
  position: absolute;
  width: 21px;
  height: 21px;
  background: radial-gradient(50% 50% at 50% 50%, rgb(215, 167, 39) 0%, rgba(215, 167, 39, 0.58) 38%, rgba(215, 167, 39, 0.27) 68%, rgba(215, 167, 39, 0.07) 89%, rgba(215, 167, 39, 0) 100%);
  border-radius: 14px;
  transform: matrix(-0.93, -0.37, -0.37, 0.93, 0, 0);
  top: 35px;
  right: 120px;
`;

export default BubbleDesktop10;
