import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../interfaces';
import JackpotTickerType from '../../JackpotCategory/JackpotTickerType';
import { DeviceType } from '../../../interfaces/DeviceType';
import BubbleMobile1 from './Bubbles/Mobile/BubbleMobile1';
import BubbleMobile2 from './Bubbles/Mobile/BubbleMobile2';
import BubbleMobile3 from './Bubbles/Mobile/BubbleMobile3';
import BubbleMobile4 from './Bubbles/Mobile/BubbleMobile4';
import BubbleDesktop1 from './Bubbles/Desktop/BubbleDesktop1';
import BubbleDesktop2 from './Bubbles/Desktop/BubbleDesktop2';
import BubbleDesktop3 from './Bubbles/Desktop/BubbleDesktop3';
import BubbleDesktop4 from './Bubbles/Desktop/BubbleDesktop4';
import BubbleDesktop5 from './Bubbles/Desktop/BubbleDesktop5';
import BubbleDesktop6 from './Bubbles/Desktop/BubbleDesktop6';
import BubbleDesktop7 from './Bubbles/Desktop/BubbleDesktop7';
import BubbleDesktop8 from './Bubbles/Desktop/BubbleDesktop8';
import BubbleDesktop9 from './Bubbles/Desktop/BubbleDesktop9';
import BubbleDesktop10 from './Bubbles/Desktop/BubbleDesktop10';
import BubbleDesktop11 from './Bubbles/Desktop/BubbleDesktop11';

interface JackpotContainerProperties {
  className?: string;
  categoryCode?: string;
}

function JackpotContainerComponent({ className, categoryCode }: JackpotContainerProperties) {
  const isMobile = useSelector((state: RootState) => state.application.deviceType)
    === DeviceType.Mobile;
  return (
    <div className={className}>
      <JackpotTopPlaceholder isMobile={isMobile}>
        {!isMobile && (
        <>
          <SmallStar src="./cms/casinoLobby/star_jackpot_category.png" alt="star jackpot" />
          <MediumStar src="./cms/casinoLobby/star_jackpot_category.png" alt="star jackpot" />
          <BigStar src="./cms/casinoLobby/star_jackpot_category.png" alt="star jackpot" />
        </>
        )}
        <BlurPlaceholder>
          {isMobile && (
            <>
              <BubbleMobile1 />
              <BubbleMobile2 />
              <BubbleMobile3 />
              <BubbleMobile4 />
            </>
          )}
          {!isMobile && (
            <>
              <BubbleDesktop1 />
              <BubbleDesktop2 />
              <BubbleDesktop3 />
              <BubbleDesktop4 />
              <BubbleDesktop5 />
              <BubbleDesktop6 />
              <BubbleDesktop7 />
              <BubbleDesktop8 />
              <BubbleDesktop9 />
              <BubbleDesktop10 />
              <BubbleDesktop11 />
            </>
          )}
          <RoundTopBlur />
          <RoundBottomBlur />
        </BlurPlaceholder>
        <TickerWrapper>
          <JackpotTickerType categoryCode={categoryCode} />
        </TickerWrapper>
      </JackpotTopPlaceholder>
    </div>
  );
}

JackpotContainerComponent.defaultProps = {
  className: undefined,
  categoryCode: undefined,
};

const SmallStar = styled.img`
  position: absolute;
  width: 53px;
  height: 53px;
  top: 0px;
  right: 90px;
  z-index: 1;
  pointer-events: none;
`;

const MediumStar = styled.img`
  position: absolute;
  width: 77px;
  height: 77px;
  top: -51px;
  right: 40px;
  z-index: 1;
  pointer-events: none;
`;

const BigStar = styled.img`
  position: absolute;
  width: 97px;
  height: 97px;
  top: -10px;
  right: 0px;
  z-index: 1;
  pointer-events: none;
`;

const TickerWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
`;

const BlurPlaceholder = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
`;

const RoundTopBlur = styled.div`
  position: absolute;
  width: 1150px;
  height: 110px;
  top: 40px;
  background: radial-gradient(49.94% 50.16% at 49.14% 53.82%, #FFFFFF 0%, rgba(255, 255, 255, 0.58) 38%, rgba(255, 255, 255, 0.27) 68%, rgba(255, 255, 255, 0.07) 89%, rgba(255, 255, 255, 0) 100%);
  background-blend-mode: overlay;
  mix-blend-mode: overlay;
  opacity: 0.8;
  border-radius: 14px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  pointer-events: none;
`;

const RoundBottomBlur = styled.div`
  position: absolute;
  width: 684px;
  height: 335px;
  background: radial-gradient(50.01% 101.09% at 50% 101.08%, #0000FF 0%, #0012FF 2%, #002FFF 4%, #0026D3 11%, #001DA2 20%, #001576 29%, #000F52 39%, #000934 49%, #00051D 60%, #00020C 71%, #000003 84%, #000000 100%);
  background-blend-mode: screen;
  mix-blend-mode: screen;
  opacity: 0.8;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
`;

const JackpotTopPlaceholder = styled.div.attrs((props: { isMobile: boolean }) => props)`
  ${({ isMobile }) => `
    margin-top: -${isMobile ? 30 : 40}px;
  `};
`;

export default JackpotContainerComponent;
