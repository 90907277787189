import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { RootState } from '../../../interfaces';
import { colors, paddings } from '../../../constants/style-variables';
import useFallbackTranslation from '../../../hooks/useFallbackTranslation';
import CategoryDescription from '../CategoryDescription/CategoryDescription';
import JackpotTickerType from '../../JackpotCategory/JackpotTickerType';
import { DeviceType } from '../../../interfaces/DeviceType';

export const paddingXExpandedCategoryDesktop = 45;

interface ExpandedGameCategoryHeaderProperties {
  className?: string;
  onBackClick: VoidFunction;
  expandedComponentId: string;
  dataTestId?: string;
}

function ExpandedGameCategoryHeaderJackpotComponent({
  className, expandedComponentId, onBackClick, dataTestId,
}:
  ExpandedGameCategoryHeaderProperties) {
  const safeTranslationCode = useFallbackTranslation(expandedComponentId);
  const isMobile = useSelector((state: RootState) => state.application.deviceType)
    === DeviceType.Mobile;

  return (
    <div className={className} data-test-id={dataTestId}>
      <BackButton type="button" onClick={onBackClick} name={`${dataTestId}-button-back`} isMobile={isMobile}>
        <img src="./cms/casinoLobby/arrow_back_jackpot_lobby.svg" alt="back to main lobby" />
      </BackButton>
      <Wrapper isMobile={isMobile}>
        <TickerWrapper isMobile={isMobile}>
          <JackpotTickerType categoryCode={expandedComponentId} />
        </TickerWrapper>
        <GameInfo isMobile={isMobile}>
          <ExpandedCategoryName data-test-id={`${dataTestId}-text`} isMobile={isMobile}>
            <FormattedMessage id={safeTranslationCode} />
          </ExpandedCategoryName>
          <ExpandedCategoryDescription
            translationCode={expandedComponentId}
            isMobile={isMobile}
          />
        </GameInfo>
      </Wrapper>
    </div>
  );
}

ExpandedGameCategoryHeaderJackpotComponent.defaultProps = {
  className: undefined,
  dataTestId: 'expanded-game-category-header',
};

const BackButton = styled.button.attrs((props: { isMobile: boolean }) => props)`
  ${({ isMobile }) => `
    left: ${isMobile ? paddings.pagePadding : paddingXExpandedCategoryDesktop}px;
  `};
  ${({ isMobile }) => !isMobile && css`
    top: 26px;
  `};
  position: absolute;
  padding: 0;
  background: transparent;
  border: 0;
`;

const GameInfo = styled.div.attrs((props: { isMobile: boolean }) => props)`
  display: flex;
  flex-direction: column;
  ${({ isMobile }) => isMobile && css`
    justify-content: center;
    align-items: center;
  `};
`;

const ExpandedCategoryName = styled.div.attrs((props: { isMobile: boolean }) => props)`
  font-weight: 500;
  font-family: "DM Sans";
  ${({ isMobile }) => `
    font-size: ${isMobile ? 16 : 24}px;
    line-height: ${isMobile ? 24 : 32}px;
    margin-top: ${isMobile ? 0 : 20}px;
    margin-left: ${isMobile ? 0 : paddingXExpandedCategoryDesktop}px;
  `};
`;

const TickerWrapper = styled.div.attrs((props: { isMobile: boolean }) => props)`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ isMobile }) => `
    margin-top: ${isMobile ? 20 : 40}px;
    margin-bottom: ${isMobile ? 25 : 0}px;
  `};
`;

const Wrapper = styled.div.attrs((props: { isMobile: boolean }) => props)`
  display: flex;
  ${({ isMobile }) => `
    flex-direction: ${isMobile ? 'column-reverse' : 'column'};
  `};
`;

const ExpandedCategoryDescription = styled(CategoryDescription).attrs((props: { isMobile: boolean }) => props)`
  color: ${colors.white};
  font-family: "DM Sans";
  ${({ isMobile }) => `
    font-size: ${isMobile ? 12 : 16}px;
    line-height: ${isMobile ? 16 : 24}px;
    margin-left: ${isMobile ? 0 : paddingXExpandedCategoryDesktop}px;
  `};
`;

const ExpandedGameCategoryHeaderJackpot = styled(ExpandedGameCategoryHeaderJackpotComponent)`
  position: relative;
  padding-top: 15px;
  color: ${colors.white};
`;

export default ExpandedGameCategoryHeaderJackpot;
