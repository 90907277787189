import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../interfaces';
import GameDetailsMobile from './GameDetailsView/Mobile/GameDetailsMobile';

interface MobileDetailsWrapperProperties {
  className?: string;
}

function MobileDetailsWrapperComponent({ className }: MobileDetailsWrapperProperties) {
  const detailedGame = useSelector((state: RootState) => state.lobby.detailedGame);

  if (!detailedGame) {
    return null;
  }

  return (
    <GameDetailsMobile className={className} />
  );
}

MobileDetailsWrapperComponent.defaultProps = {
  className: undefined,
};

export default MobileDetailsWrapperComponent;
