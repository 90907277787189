import styled, { css } from 'styled-components';

interface ImgVisibilityWrapperProps {
  isVisible: boolean;
}

const ImgVisibilityWrapper = styled.div.attrs((
  props: ImgVisibilityWrapperProps,
) => props)`
  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  ${({ isVisible }) => !isVisible && css`
    display: none;
  `};
  ${({ isVisible }) => isVisible && css`
    display: block;
  `};
`;

export default ImgVisibilityWrapper;
