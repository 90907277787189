import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import FavoriteStar from './FavoriteStar';
import {
  GameCategoryItem, RootState,
} from '../../../interfaces';
import JackpotTicker from './JackpotTicker';
import Ribbons from './Ribbons';
import HotPayout from './HotPayout';
import { colors } from '../../../constants/style-variables';
import GameItemClickAction from '../../../interfaces/GameItemClickAction';
import { TileSize } from '../../../interfaces/TileSize';
import Picture from '../../rsi/Image/Picture';
import { Translations } from '../../../constants/translations';
import { IMG_SIZE_HEIGHT, IMG_SIZE_WIDTH } from '../../../constants/image-variables';
import { SKELETON_HEIGHT_PERCENTAGE } from '../../../constants/skeleton-loader';
import useClientRect from '../../../hooks/useClientRect';
import { GAME_TILE_BORDER_RADIUS } from '../../../constants/category-variables';

interface GameItemProperties {
  item: GameCategoryItem;
  size: TileSize;
  className?: string;
  onClick?: any;
  isMobileOneClick?: boolean;
  dataTestId?: string;
  customWidth?: number;
  customHeight?: number;
  withJackpotItem?: boolean;
}

function GameItemComponent({
  item,
  size,
  className,
  onClick,
  isMobileOneClick,
  dataTestId,
  customWidth,
  customHeight,
  withJackpotItem,
}: GameItemProperties) {
  const [rect, ref] = useClientRect();
  const favoriteGames = useSelector((state: RootState) => state.lobby.favoriteGames);
  const expandedComponentId = useSelector((state: RootState) => state.lobby.expandedComponentId);
  const isFavoriteOrRecentRolledUp = Boolean(isMobileOneClick) && !expandedComponentId;

  const hasJP: boolean = item?.flags?.includes('PROGRESSIVE') || item?.flags?.includes('GLOBAL_PROGRESSIVE');
  const isFavorite: boolean = favoriteGames.includes(item.code);

  const width = customWidth ?? IMG_SIZE_WIDTH.get(size);
  const height = customHeight ?? IMG_SIZE_HEIGHT.get(size);
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

  const handleImageLoad = useCallback(() => {
    setIsImageLoaded(true);
  }, []);

  if (!item || !width || !height) {
    return null;
  }

  return (
    <div className={className} data-test-id={dataTestId}>
      <ClickWrapper
        onClick={() => onClick(GameItemClickAction.GENERAL)}
        isRecentOrFavoritesCategory={Boolean(isMobileOneClick)}
      >
        <Ribbons flags={item.flags} />
        {isImageLoaded && (
          <BottomRow size={size}>
            {hasJP && !item.payoutPercentage && <JackpotTicker gameCode={item.code} />}
            {item.payoutPercentage && <HotPayout payout={item.payoutPercentage} />}
            {isFavorite && <FavoriteStar />}
          </BottomRow>
        )}
        <ImageWrapper>
          {isMobileOneClick && <PlayIcon src="./cms/casinoLobby/play.svg" alt="play game" />}
          {withJackpotItem ? (
            <ImageBorder ref={ref} width={customWidth}>
              <GamePicture
                isFavoriteOrRecentRolledUp={isFavoriteOrRecentRolledUp}
                className="game-image"
                gameCode={item.code}
                customWidth={width}
                containerRect={rect}
              >
                <Image
                  loading="lazy"
                  alt={item.name}
                  width={`${width}`}
                  height={`${height}`}
                  onLoad={handleImageLoad}
                />
              </GamePicture>
            </ImageBorder>
          ) : (
            <div ref={ref}>
              <GamePicture
                isFavoriteOrRecentRolledUp={isFavoriteOrRecentRolledUp}
                className="game-image"
                gameCode={item.code}
                customWidth={width}
                containerRect={rect}
              >
                <Image
                  loading="lazy"
                  alt={item.name}
                  width={`${width}`}
                  height={`${height}`}
                  onLoad={handleImageLoad}
                />
              </GamePicture>
            </div>
          )}
        </ImageWrapper>
      </ClickWrapper>
      {isMobileOneClick && isImageLoaded && (
        <GameInfoRow
          onClick={() => onClick(GameItemClickAction.GAME_DETAILS)}
          data-test-id={`info-message-${item.name}`}
        >
          <FormattedMessage id={Translations.GAME_INFO} />
        </GameInfoRow>
      )}
    </div>
  );
}

GameItemComponent.defaultProps = {
  className: undefined,
  onClick: undefined,
  isMobileOneClick: false,
  dataTestId: 'game-item',
  customWidth: undefined,
  customHeight: undefined,
  withJackpotItem: false,
};

const getBottomRowFontSize = (size: TileSize) => {
  switch (size) {
    case TileSize.S: {
      return 12;
    }
    case TileSize.M: {
      return 14;
    }
    default: {
      return 20;
    }
  }
};

const ClickWrapper = styled.div.attrs((props: { isRecentOrFavoritesCategory: boolean }) => props)`
  cursor: pointer;
  position: relative;
  ${({ isRecentOrFavoritesCategory }) => !isRecentOrFavoritesCategory && css`
    height: 100%;
  `};
`;

const GamePicture = styled(Picture)`
  display: block;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const jackpotBorderWidth = 3;

const ImageBorder = styled.div.attrs((props: { width: number }) => props)`
  ${({ width }) => `
    width: 100%;
    height: ${(width && width * SKELETON_HEIGHT_PERCENTAGE) + 1}px;
  `};
  border-radius: ${GAME_TILE_BORDER_RADIUS}px;
  padding: 2px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: linear-gradient(132.71deg, #FDB61B 32.35%, #0F27FB 85.12%);
  &:hover {
    &:after {
      position: absolute;
      z-index: 0;
      border-left: ${jackpotBorderWidth}px solid rgb(253, 182, 27);
      border-right: ${jackpotBorderWidth}px solid rgb(15, 39, 251);
      background-image: linear-gradient(89.85deg, rgb(253, 182, 27) 19.65%, rgb(15, 39, 251) 75.65%), linear-gradient(89.85deg, rgb(253, 182, 27) 19.65%, rgb(15, 39, 251) 75.65%);
      background-size: 100% ${jackpotBorderWidth}px;
      background-position: 0px 0px, 0px 100%;
      background-repeat: no-repeat;
      border-radius: ${GAME_TILE_BORDER_RADIUS}px;
      filter: blur(${jackpotBorderWidth}px);
      content: "";
      height: calc(100% + ${jackpotBorderWidth * 2}px);
      width: calc(100% + ${jackpotBorderWidth * 2}px);
      top: -${jackpotBorderWidth}px;
      left: -${jackpotBorderWidth}px;
      mix-blend-mode: screen;
      }
    }
`;

const Image = styled.img`
  border-radius: ${GAME_TILE_BORDER_RADIUS}px;
  width: 100%;
  height: auto;
`;

const PlayIcon = styled.img`
  position: absolute;
  width: 40px;
  height: 35px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const GameInfoRow = styled.div`
  background: ${colors.blueDark};
  border-radius: 4px;
  margin-top: -5px;
  padding: 13px 8px 8px 8px;
  color: ${colors.white};
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
`;

const BottomRow = styled.div.attrs((props: { size: TileSize }) => props)`
  position: absolute;
  right: 2px;
  left: 2px;
  bottom: 2px;
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;
  font-size: ${(props) => getBottomRowFontSize(props.size)}px;
`;

const GameItem = styled(GameItemComponent)`
  position: relative;
  height: 100%;
`;

export default GameItem;
