import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { JackpotPotType, RootState } from '../../../interfaces';
import { colors } from '../../../constants/style-variables';
import { useJackpotGameTicker } from '../../../hooks';
import { VerticalVirtualScrollContext } from '../../rsi/Scroll/VerticalVirtualScroll';
import { HorizontalVirtualScrollContext } from '../../rsi/Scroll/HorizontalVirtualScroll';
import useClientRect from '../../../hooks/useClientRect';

interface JpProperties {
  className?: string;
  gameCode: string;
}

function JackpotTickerComponent({ gameCode, className }: JpProperties) {
  const [rect, ref] = useClientRect();
  const inView = useContext(VerticalVirtualScrollContext);
  const horizontalScrollContext = useContext(HorizontalVirtualScrollContext);
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const rsiFormat = useSelector((state: RootState) => state.application.rsiFormat);
  const getGameJpValue = useJackpotGameTicker(gameCode, JackpotPotType.PROGRESSIVE, !isVisible);

  useEffect(() => {
    /**
     * needed since JackpotTicker is used in the expanded category, where there's no horizontal
     * scrolling
     */
    if (Object.keys(horizontalScrollContext).length !== 0) {
      const inViewHorizontal = horizontalScrollContext.isInView(rect);
      setIsVisible(inViewHorizontal && inView);
      return;
    }

    setIsVisible(inView);
  }, [horizontalScrollContext, horizontalScrollContext.scrollLeft, inView, rect]);

  if (!getGameJpValue || !rsiFormat) {
    return null;
  }

  return (
    <div ref={ref} className={className}>
      {rsiFormat.Currency(getGameJpValue)}
    </div>
  );
}

JackpotTickerComponent.defaultProps = {
  className: undefined,
};

const JackpotTicker = styled(JackpotTickerComponent)`
  flex-grow: 1;
  background: ${colors.yellow};
  border: 1px solid ${colors.yellow};
  box-shadow: 0 1px 6px ${colors.yellow}, 0px 0px 2px rgba(0, 0, 0, .1);
  border-radius: 4px;
  text-align: center;
  font-weight: 700;
  font-family: monospace;
  color: ${colors.blueDark};
`;

export const DetailsJackpotTicker = styled(JackpotTickerComponent)`
  background: ${colors.yellow};
  border: 1px solid ${colors.yellow};
  border-radius: 4px;
  text-align: center;
  font-weight: 700;
  font-family: monospace;
  font-size: 12px;
  padding: 0 12px;
  line-height: 16px;
`;

export default JackpotTicker;
