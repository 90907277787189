import React from 'react';
import styled from 'styled-components';

function BubbleComponent({ className }: {className?: string}) {
  return <div className={className} />;
}

BubbleComponent.defaultProps = {
  className: undefined,
};

const BubbleDesktop9 = styled(BubbleComponent)`
  position: absolute;
  width: 56px;
  height: 56px;
  background: radial-gradient(50% 50% at 50% 50%, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.58) 58%, rgba(255, 255, 255, 0.27) 68%, rgba(255, 255, 255, 0.07) 89%, rgba(255, 255, 255, 0) 100%);
  background-blend-mode: overlay;
  mix-blend-mode: overlay;
  border-radius: 14px;
  transform: matrix(-0.93, -0.37, -0.37, 0.93, 0, 0);
  top: 100px;
  left: -22px;
`;

export default BubbleDesktop9;
