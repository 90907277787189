import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../interfaces';
import { lobbyActions } from '../store/actions';
import SearchViewGames from './GameCategory/SearchView/SearchViewGames';
import ExpandedCategoryGames from './GameCategory/ExpandedGameCategory/ExpandedCategoryGames';

import MobileDetailsWrapperComponent from './GameCategory/MobileDetailsWrapper';
import LobbyVisibilityController from './LobbyVisibilityController';
import { useTypedDispatch } from '../store';
import SkeletonLobby from './Loading/SkeletonLobby';
import { SKELETON_CATEGORIES_IN_LOBBY, SKELETON_GAMES_PER_ROW } from '../constants/skeleton-loader';
import LobbyLazyLoading from './rsi/LazyLoading/LobbyLazyLoading';
import LobbyComponentVS from './LobbyComponent/LobbyComponentVS';
import useConfigs from '../hooks/useConfigs';

interface LobbyContainerProperties {
  className?: string;
  lobbyId: string;
  dataTestId?: string;
}

function LobbyContainerComponent({
  lobbyId,
  className,
  dataTestId,
}: LobbyContainerProperties) {
  const dispatch = useTypedDispatch();
  const clientType = useSelector(
    (state: RootState) => state.application.clientType,
  );
  const components = useSelector((state: RootState) => state.lobby.components);
  const useFullscreenDetails = useSelector(
    (state: RootState) => state.layout.useFullscreenDetails,
  );
  const { casinoLobby } = useConfigs(['casinoLobby']);
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);

  const setRef = useCallback((ref: React.SetStateAction<HTMLDivElement | null>) => {
    setContainerRef(ref);
  }, []);

  useEffect(() => {
    if (clientType && lobbyId) {
      dispatch(
        lobbyActions.getLobby(
          rsiApi.getPlayerDataByField('RO_CASINO_LOBBY_CODE') || lobbyId,
          clientType,
          false,
        ),
      );
    }
  }, [dispatch, lobbyId, clientType]);

  if (components.length > 0) {
    return (
      <div className={className} data-test-id={dataTestId}>
        {useFullscreenDetails && <MobileDetailsWrapperComponent />}

        <SearchViewGames />

        <ExpandedCategoryGames />

        <LobbyVisibilityController containerRef={containerRef} />

        <div ref={setRef} data-testid="lobby-components-wrapper">
          {casinoLobby?.enableLazyLoadingForGameCategories ? (
            <LobbyLazyLoading components={components} />
          ) : (
            components.map((component, index) => (
              <LobbyComponentVS
                key={component.code + component.type}
                component={component}
                lastChild={index + 1 === components.length}
              />
            ))
          )}
        </div>
      </div>
    );
  }

  return (
    <SkeletonLobby
      gameRows={SKELETON_CATEGORIES_IN_LOBBY}
      gamesPerRow={SKELETON_GAMES_PER_ROW}
    />
  );
}

LobbyContainerComponent.defaultProps = {
  className: undefined,
  dataTestId: 'lobby-container',
};

const LobbyContainer = styled(LobbyContainerComponent)`
  height: 100%;
  background-color: ${(props: any) => props.theme.backgroundColor};
`;

export default LobbyContainer;
