import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { colors } from '../../../../constants/style-variables';
import { Translations } from '../../../../constants/translations';

interface DailyAndHourlyJackpotProperties {
  isDaily: boolean;
  isMobile: boolean;
  sum?: string;
  className?: string;
  isHot: boolean;
}

const getTitleTranslation = (isHot: boolean, isDaily: boolean): Translations | undefined => {
  if (isHot) {
    return isDaily
      ? Translations.DAILY_HOT_CATEGORY_JACKPOT
      : Translations.HOURLY_HOT_CATEGORY_JACKPOT;
  }
  if (!isHot) {
    return isDaily
      ? Translations.DAILY_CATEGORY_JACKPOT
      : Translations.HOURLY_CATEGORY_JACKPOT;
  }
  return undefined;
};

function DailyAndHourlyJackpotComponent({
  isDaily, isMobile, sum, className, isHot,
}: DailyAndHourlyJackpotProperties) {
  if (!sum) {
    return null;
  }
  return (
    <div className={className}>
      <DailyAndHourlyTicker isDaily={isDaily} isMobile={isMobile} isHot={isHot}>
        {isHot && (
          <HotIcon
            src="./cms/casinoLobby/daily_hourly_hot_title.svg"
            alt="hot icon"
            isMobile={isMobile}
          />
        )}
        <FormattedMessage id={getTitleTranslation(isHot, isDaily)} />
      </DailyAndHourlyTicker>
      <SumDailyAndHourlyJackpot isMobile={isMobile} isDaily={isDaily} isHot={isHot}>
        {sum?.replaceAll(',', ', ')}
      </SumDailyAndHourlyJackpot>
    </div>
  );
}

DailyAndHourlyJackpotComponent.defaultProps = {
  className: undefined,
  sum: undefined,
};

const DailyAndHourlyTicker = styled.div.attrs((props: { isDaily: boolean; isMobile: boolean; isHot: boolean; }) => props)`
  display: flex;
  align-items: center;

  ${({ isMobile }) => `
    font-size: ${isMobile ? '10px' : '14px'};
    padding: ${isMobile ? '0px 4px' : '0px 8px'};
    height: ${isMobile ? 12 : 26}px;
    border-radius: ${isMobile ? '0px 0px 2px 2px' : '0px 0px 4px 4px'};
    margin: auto;
  `}

  ${({ isDaily, isHot }) => !isHot && css`
    background: ${isDaily ? 'rgb(15, 23, 251)' : 'rgb(138, 38, 238)'};
    box-shadow: rgb(27 0 83 / 25%) 0px 3.28132px 3.28132px;
  `}

  ${({ isHot, isMobile }) => isHot && css`
    background: linear-gradient(${isMobile ? 273.7 : 93.7}deg, #CC00FF 11.43%, #0F17FB 84.61%);
    box-shadow: 1.73066px 3.46131px 3.46131px rgba(27, 0, 83, 0.25);
  `}

  color: ${colors.white};
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
`;

const HotIcon = styled.img.attrs((props: { isMobile: boolean;}) => props)`
  margin-right: 4px;
  ${({ isMobile }) => `
    width: ${isMobile ? '8px' : '12px'};
  `}
`;

const SumDailyAndHourlyJackpot = styled.div.attrs((props: { isDaily: boolean; isMobile: boolean; isHot: boolean; }) => props)`

  :first-letter {
    ${({ isHot }) => !isHot && css`
      color: ${colors.gold};
    `}
    line-height: 100%;
    padding-right: 5px;
    ${({ isMobile }) => `
      font-size: ${isMobile ? '22px' : '34px'};
    `}
  }

  ${({ isMobile }) => `
    font-size: ${isMobile ? '20px' : '30px'};
    margin-top: ${isMobile ? '5px' : '7px'};
    margin-bottom: ${isMobile ? '14px' : '15px'};
  `}
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  color: ${colors.white};
  ${({ isMobile }) => isMobile && css`
    white-space: pre;
    padding: 0px 20px;
  `}
  ${({ isHot }) => isHot && css`
    text-shadow: 1.5px 1.5px 2px rgba(53, 0, 22, 0.35);
  `}

  ${({ isDaily, isMobile }) => !isDaily && !isMobile && css`
    margin-right: 20px;
    margin-left: 30px;
  `}

  ${({ isDaily, isMobile }) => (isDaily && !isMobile) && css`
    margin-left: 20px;
    margin-right: 20px;
  `}
`;

const DailyAndHourlyJackpot = styled(DailyAndHourlyJackpotComponent)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default DailyAndHourlyJackpot;
