import { AnyAction } from 'redux';
import { ApplicationState, Orientations } from '../../interfaces';
import { ApplicationActionTypes } from '../actionTypes';

const initialState: ApplicationState = {
  rsiFormat: null,
  clientType: null,
  deviceType: null,
  width: undefined,
  orientation: Orientations.Landscape,
};

export default (
  state = initialState,
  { type, payload }: AnyAction = { type: ApplicationActionTypes.DEFAULT },
) => {
  switch (type) {
    case ApplicationActionTypes.SET_FORMAT: {
      return {
        ...state,
        rsiFormat: payload,
      };
    }
    case ApplicationActionTypes.SET_CLIENT_TYPE: {
      return {
        ...state,
        clientType: payload,
      };
    }
    case ApplicationActionTypes.SET_DEVICE_TYPE: {
      return {
        ...state,
        deviceType: payload,
      };
    }
    case ApplicationActionTypes.SET_WIDTH: {
      return {
        ...state,
        width: payload,
      };
    }
    case ApplicationActionTypes.SET_ORIENTATION: {
      return {
        ...state,
        orientation: payload,
      };
    }
    default:
      return state;
  }
};
