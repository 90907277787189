import { combineReducers } from 'redux';
import lobby from './lobby';
import auth from './auth';
import application from './application';
import search from './search';
import jackpots from './jackpots';
import layout from './layout';
import gamingMode from './gamingMode';

export default combineReducers({
  application,
  lobby,
  search,
  jackpots,
  auth,
  layout,
  gamingMode,
});
