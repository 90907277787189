import React from 'react';
import styled from 'styled-components';

function BubbleComponent({ className }: {className?: string}) {
  return <div className={className} />;
}

BubbleComponent.defaultProps = {
  className: undefined,
};

const BubbleDesktop1 = styled(BubbleComponent)`
  position: absolute;
  width: 45px;
  height: 45px;
  background: radial-gradient(50% 50% at 50% 50%, rgb(250, 140, 70) 0%, rgba(250, 140, 70, 0.58) 38%, rgba(250, 140, 70, 0.27) 68%, rgba(250, 140, 70, 0.07) 89%, rgba(250, 140, 70, 0) 100%);
  background-blend-mode: screen;
  mix-blend-mode: screen;
  border-radius: 14px;
  transform: matrix(0.98, -0.2, -0.2, -0.98, 0, 0);
  top: 40px;
  left: 10px;
`;

export default BubbleDesktop1;
