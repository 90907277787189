enum LobbyActionTypes {
  GET_LOBBY_CONFIGURATION_REQUEST= 'lobby/GET_LOBBY_CONFIGURATION_REQUEST',
  GET_LOBBY_CONFIGURATION_SUCCESS= 'lobby/GET_LOBBY_CONFIGURATION_SUCCESS',
  GET_LOBBY_CONFIGURATION_FAILURE= 'lobby/GET_LOBBY_CONFIGURATION_FAILURE',

  GET_EXPANDED_CATEGORY_GAMES_REQUEST= 'category/GET_EXPANDED_CATEGORY_GAMES_REQUEST',
  GET_EXPANDED_CATEGORY_GAMES_SUCCESS= 'category/GET_EXPANDED_CATEGORY_GAMES_SUCCESS',
  GET_EXPANDED_CATEGORY_GAMES_FAILURE= 'category/GET_EXPANDED_CATEGORY_GAMES_FAILURE',
  GET_LOBBY_CATEGORY_GAMES_SUCCESS= 'category/GET_LOBBY_CATEGORY_GAMES_SUCCESS',

  GET_GAME_DETAILS_REQUEST= 'category/GET_GAME_DETAILS_REQUEST',
  GET_GAME_DETAILS_SUCCESS= 'category/GET_GAME_DETAILS_SUCCESS',
  GET_GAME_DETAILS_FAILURE= 'category/GET_GAME_DETAILS_FAILURE',

  SET_EXPANDED_CATEGORY= 'lobby/SET_EXPANDED_CATEGORY',
  SET_DETAILED_GAME= 'lobby/SET_DETAILED_GAME',
  SET_DETAILED_GAME_DETAILS = 'lobby/SET_DETAILED_GAME_DETAILS',
  SET_GAME_IMAGE_SRC= 'lobby/SET_GAME_IMAGE_SRC',
  SET_CUSTOM_GAME_IMAGES= 'lobby/SET_CUSTOM_GAME_IMAGES',
  SET_FAVORITE_GAMES= 'lobby/SET_FAVORITE_GAMES',
  SET_LOBBY_TRANSLATIONS= 'lobby/SET_LOBBY_TRANSLATIONS',

  UPDATE_TRANSLATIONS= 'lobby/UPDATE_TRANSLATIONS',
  ADD_MISSING_TRANSLATION_VALUE= 'lobby/ADD_MISSING_TRANSLATION_VALUE',

  DEFAULT = 'lobby/DEFAULT',
}

export default LobbyActionTypes;
