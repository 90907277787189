import { useCallback, useState } from 'react';

type ClientRectHook = [DOMRect | undefined, (node?: Element | null) => void];

const useClientRect = (): ClientRectHook => {
  const [rect, setRect] = useState<DOMRect | undefined>();
  const ref = useCallback((node?: Element | null) => {
    if (!node) {
      return;
    }

    setRect(node.getBoundingClientRect());
  }, []);

  return [rect, ref];
};

export default useClientRect;
