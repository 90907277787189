import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../interfaces';
import { lobbyActions, searchActions } from '../store/actions';
import eventBus, { eventTypes } from '../services/eventBus';

/**
 * Handles search functionality.
 * - Search results are displayed as expended category games
 * - Minimum of 2 character required for activating search
 * - Search closes opened game details automatically
 * - When closing a search then the user is redirected back to previously opened expanded category
 * (if it was opened)
 */
export default (): void => {
  const dispatch = useDispatch();
  const expandedComponentId = useSelector((state: RootState) => state.lobby.expandedComponentId);
  const searchString = useSelector((state: RootState) => state.search.searchString);
  const prevExpCatType = useSelector((state: RootState) => state.search.prevExpCatType);

  useEffect((): void => {
    if (searchString.length === 0) {
      if (prevExpCatType) {
        dispatch(lobbyActions.setExpandedCategory(prevExpCatType));
        dispatch(searchActions.setPreviousExpandedCategory(null));
      }
      dispatch(searchActions.setSearchVisible(false));
    } else {
      if (expandedComponentId) {
        dispatch(searchActions.setPreviousExpandedCategory(expandedComponentId));
        dispatch(lobbyActions.setExpandedCategory(null));
      }
      dispatch(searchActions.setSearchVisible(true));
      dispatch(lobbyActions.setDetailedGame(null, null, null));
    }
  }, [dispatch, searchString, expandedComponentId, prevExpCatType]);

  useEffect(() => {
    const gameListSearchSubscription = eventBus.subscribe(
      eventTypes.CASINO_SET_GAMELIST_SEARCH_STRING,
      (searchText: string) => {
        dispatch(searchActions.setSearchString(searchText));
      },
    );

    return () => {
      gameListSearchSubscription.unsubscribe();
    };
  }, [dispatch]);
};
