import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { GameCategoryUpdateType, RootState } from '../../../interfaces';
import { lobbyActions } from '../../../store/actions';
import ExpandedGameCategoryHeader from './ExpandedGameCategoryHeader';
import InfiniteScrollGames from '../InfiniteScrollGames';
import { useTypedDispatch } from '../../../store';
import CategoryDescription from '../CategoryDescription/CategoryDescription';
import CategoryDoesNotExistError from '../../../errors/CategoryDoesNotExistError';
import useJackpotCategory from '../../../hooks/useJackpotCategory';
import ExpandedGameCategoryHeaderJackpot from './ExpandedGameCategoryHeaderJackpot';
import BackgroundJackpotCategory
  from './BackgroundJackpotCategory/BackgroundJackpotCategory';

interface CategoryGamesProperties {
  className?: string;
}

function CategoryGamesComponent({ className }: CategoryGamesProperties) {
  const dispatch = useTypedDispatch();
  const expandedComponentId = useSelector((state: RootState) => state.lobby.expandedComponentId);
  const sourceType = useSelector((state: RootState) => (
    state.lobby.expandedCategoryGames?.sourceType
  ));
  const expandedCategoryCode = useSelector((state: RootState) => (
    state.lobby.expandedCategoryGames?.code
  ));
  const { withJackpot } = useJackpotCategory(expandedCategoryCode);
  const handleClick = useCallback(() => {
    dispatch(lobbyActions.setExpandedCategory(null));
  }, [dispatch]);
  const clientType = useSelector((state: RootState) => state.application.clientType);
  const selectorHandler = useCallback((state: RootState) => (
    state.lobby.expandedCategoryGames?.games || null
  ), []);

  const fetchHandler = useCallback((page: number) => {
    if (clientType && expandedComponentId) {
      new Promise((resolve) => {
        const result = dispatch(lobbyActions.getExpandedCategoryGames(
          expandedComponentId,
          clientType,
          GameCategoryUpdateType.OnlyExpCategory,
          page,
        ));
        resolve(result);
      })
        .catch((err) => {
          if (err instanceof CategoryDoesNotExistError) {
            RSINavigationHandler.replace('?page=all-games');
          }
        });
    }
  }, [dispatch, expandedComponentId, clientType]);

  if (!expandedComponentId) {
    return null;
  }

  return (
    <div className={className}>
      <CategoryBackground
        withJackpot={withJackpot}
      >
        {withJackpot && <BackgroundJackpotCategory />}
        {withJackpot ? (
          <ExpandedGameCategoryHeaderJackpot
            expandedComponentId={expandedComponentId}
            onBackClick={handleClick}
          />
        ) : (
          <>
            <ExpandedGameCategoryHeader
              translationCode={expandedComponentId}
              onBackClick={handleClick}
            />
            <ExpandedCategoryDescription translationCode={expandedComponentId} />
          </>
        )}
        <InfiniteScrollGames
          fetchHandler={fetchHandler}
          selectorHandler={selectorHandler}
          sourceType={sourceType}
          dataTestId={`category-${expandedComponentId}`}
          scrollToTop
        />
      </CategoryBackground>
    </div>
  );
}

CategoryGamesComponent.defaultProps = {
  className: undefined,
};

const CategoryBackground = styled.div.attrs((props: { withJackpot: boolean }) => props)`

${({ withJackpot }) => withJackpot && css`
    position: relative;
    overflow: hidden;
    background: linear-gradient(93.53deg, #08012D -2.71%, #0026ED 55.73%, #003171 97.52%);
    padding-bottom: 8px;
  `};
`;

const ExpandedCategoryDescription = styled(CategoryDescription)`
  text-align: center;
`;

const ExpandedCategoryGames = styled(CategoryGamesComponent)`
  padding: 0px;
`;

export default ExpandedCategoryGames;
