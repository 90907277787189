import React, { useCallback, useEffect, useState } from 'react';
import GameItem from '../GameItem/GameItem';
import GameItemClickAction from '../../../interfaces/GameItemClickAction';
import {
  GameCategoryItem, GridComponent as BaseGridComponent,
} from '../../../interfaces';
import { TileSize } from '../../../interfaces/TileSize';
import { paddings } from '../../../constants/style-variables';
import GridItem from './GridItem/GridItem';
import useMultiRowTemplate from '../../../hooks/useMultiRowTemplate';
import { IMG_SIZE_HEIGHT } from '../../../constants/image-variables';

export interface GridComponent extends BaseGridComponent {
  needsMargin?: boolean;
}

interface MultiRowGridItemProps {
  game: GameCategoryItem;
  gridComponent: GridComponent;
  onClick: any;
  dataTestId: string;
  isRecentOrFavoritesCategory: boolean;
  withJackpot?: boolean;
  smallestWidth?: number;
}

function MultiRowGridItem({
  game,
  gridComponent,
  onClick,
  dataTestId,
  isRecentOrFavoritesCategory,
  withJackpot,
  smallestWidth,
}: MultiRowGridItemProps) {
  const [column, setColumn] = useState('');
  const [row, setRow] = useState('');
  const [itemWidth, setItemWidth] = useState<number>();
  const [tileSize, setTileSize] = useState<TileSize>(TileSize.L);
  const { getTileSize } = useMultiRowTemplate();

  const setCoordinates = useCallback(() => {
    const {
      x,
      y,
      width,
      height,
    } = gridComponent;
    // Needed since x and y are sent starting from 0
    const baseColumn = x + 1;
    const baseRow = y + 1;

    const expandedColumn = `${baseColumn} / ${baseColumn + width}`;
    const expandedRow = `${baseRow} / ${baseRow + height}`;

    const columnCoordinate = width > 1 ? expandedColumn : `${baseColumn}`;
    const rowCoordinate = height > 1 ? expandedRow : `${baseRow}`;
    setColumn(columnCoordinate);
    setRow(rowCoordinate);
  }, [gridComponent]);

  const setItemSpatialProperties = useCallback(() => {
    const { type, height, width } = gridComponent;
    const tileSizeForType = getTileSize(type);
    setTileSize(tileSizeForType);

    if (height > 1 && smallestWidth) {
      /**
       * The number of gaps is calculated based on the number of columns and  the number of rows
       * this might be reviewed once the MVP is launched and the feature needs to be improved
       */
      const gaps = paddings.multiRowFixedGap * ((height - 1) + (width - 1));
      const calculatedWidth = smallestWidth * width + gaps;
      setItemWidth(calculatedWidth);
      return;
    }

    setItemWidth(smallestWidth);
  }, [getTileSize, gridComponent, smallestWidth]);

  useEffect(() => {
    setCoordinates();
    setItemSpatialProperties();
  }, [gridComponent, setCoordinates, setItemSpatialProperties]);

  return (
    <GridItem
      column={column}
      row={row}
      width={itemWidth}
      marginLeft={gridComponent.needsMargin ? paddings.multiRowFixedGap : 0}
    >
      <GameItem
        onClick={(action: GameItemClickAction) => onClick(game.code, action)}
        item={game}
        size={tileSize}
        isMobileOneClick={isRecentOrFavoritesCategory}
        dataTestId={dataTestId}
        customWidth={itemWidth ?? 0}
        customHeight={IMG_SIZE_HEIGHT.get(tileSize) ?? 0}
        withJackpotItem={withJackpot}
      />
    </GridItem>
  );
}

MultiRowGridItem.defaultProps = {
  smallestWidth: undefined,
  withJackpot: false,
};

export default MultiRowGridItem;
