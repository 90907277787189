import React from 'react';
import SkeletonGamesRow from './SkeletonGamesRow';

interface SkeletonLobbyProperties {
  gameRows: number;
  gamesPerRow: number;
}

function SkeletonLobby({ gameRows, gamesPerRow }: SkeletonLobbyProperties) {
  return (
    <>
      {[...Array(gameRows)].map((_, index) => {
        const key = `skgr_${index}`;
        return (<SkeletonGamesRow key={key} numberOfGames={gamesPerRow} />);
      })}
    </>
  );
}

export default SkeletonLobby;
