import useConfigs from './useConfigs';

const useLocale = (): { language: string, locale: string } => {
  const { language } = useConfigs(['language']);

  const localeData = language?.locales.find(
    (l) => l.language === language.default,
  );

  return {
    language: localeData?.language || '',
    locale: localeData?.locale.replace('_', '-') || '',
  };
};

export default useLocale;
