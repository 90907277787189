import React, {
  createContext, ReactNode, useCallback, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../interfaces';
import { GameAssets } from '../../../interfaces/GameAssets';
import useConfigs from '../../../hooks/useConfigs';
import eventBus, { eventTypes } from '../../../services/eventBus';
import useGameListUpdateThrottle from '../../../hooks/useGameListUpdateThrottle';

export const GameAssetsProviderContext = createContext<GameAssets>({});

interface GameAssetsProviderProps {
  children: ReactNode;
}

type ReadOnlyGameAssetsProviderProps = Readonly<GameAssetsProviderProps>;

function GameAssetsProvider({ children }: ReadOnlyGameAssetsProviderProps) {
  const [gameAssets, setGameAssets] = useState<GameAssets>({});
  const clientType = useSelector(
    (state: RootState) => state.application.clientType,
  );
  const { api } = useConfigs(['api']);

  const fetchGameAssets = useCallback(() => {
    if (!api) {
      return;
    }

    RSIUtils.getGameImagePaths(api, clientType)
      .then((assets: GameAssets) => {
        if (Object.keys(assets).length === 0) {
          return;
        }

        setGameAssets(assets);
      });
  }, [api, clientType]);

  useGameListUpdateThrottle(fetchGameAssets);

  useEffect(() => {
    fetchGameAssets();

    const playerLoginStateSubscription = eventBus.subscribe(
      eventTypes.PORTAL_LOGIN_STATE_CHANGE,
      fetchGameAssets,
    );

    return () => {
      playerLoginStateSubscription.unsubscribe();
    };
  }, [fetchGameAssets]);

  return (
    <GameAssetsProviderContext.Provider value={gameAssets}>
      {children}
    </GameAssetsProviderContext.Provider>
  );
}

export default GameAssetsProvider;
