import React, { PropsWithChildren, RefObject } from 'react';
import useScrolling from '../../../hooks/useScrolling';
import SlideButton from './SlideButton';
import { TileSize } from '../../../interfaces/TileSize';

type ScrollingPoint = {
  [point: number]: number,
};

export interface SlideButtonProperties {
  containerRef: RefObject<HTMLDivElement>;
  childrenIsReady: boolean;
  withJackpot: boolean;
  withFlags: boolean;
  gamesAmount: number;
  scrollingPoints: ScrollingPoint;
  name?: string;
  tileSize: TileSize;
}

function ArrowWrapper({
  containerRef,
  childrenIsReady,
  gamesAmount,
  scrollingPoints,
  name,
  children,
  withJackpot,
  tileSize,
  withFlags,
}: PropsWithChildren<SlideButtonProperties>) {
  const {
    handlePrev,
    handleNext,
    hasNext,
    hasPrev,
  } = useScrolling(scrollingPoints, containerRef, childrenIsReady, gamesAmount);
  return (
    <>
      <SlideButton
        withFlags={withFlags}
        withJackpot={withJackpot}
        hidden={!hasPrev}
        className="scroll-button"
        onClick={handlePrev}
        isNext={false}
        name={`${name}-button-prev`}
        tileSize={tileSize}
      />
      {children}
      <SlideButton
        withFlags={withFlags}
        withJackpot={withJackpot}
        hidden={!hasNext}
        className="scroll-button"
        onClick={handleNext}
        isNext
        name={`${name}-button-next`}
        tileSize={tileSize}
      />
    </>
  );
}

ArrowWrapper.defaultProps = {
  children: undefined,
  name: '',
};

export default ArrowWrapper;
