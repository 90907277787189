enum SearchActionTypes {
  GET_SEARCH_REQUEST= 'search/GET_SEARCH_REQUEST',
  GET_SEARCH_SUCCESS= 'search/GET_SEARCH_SUCCESS',
  GET_SEARCH_FAILURE= 'search/GET_SEARCH_FAILURE',

  SET_SEARCH_STRING= 'search/SET_SEARCH_STRING',
  SET_PREVIOUS_EXPANDED_CATEGORY= 'search/SET_PREVIOUS_EXPANDED_CATEGORY',
  SET_SEARCH_VISIBLE= 'search/SET_SEARCH_VISIBLE',

  DEFAULT = 'search/DEFAULT',
}

export default SearchActionTypes;
