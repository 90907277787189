import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { logger } from '../services';
import { LobbyActionTypes } from '../store/actionTypes';

let missingTranslations: string[] = [];
let missingTranslationsTimeout: number;

const logMissingTranslations = () => {
  logger.error('Translations missing:', { missingTranslations });
  missingTranslations = [];
};

export default (err: { code: string; descriptor?: any; }, dispatch: Dispatch<AnyAction>) => {
  if (err.code === 'MISSING_TRANSLATION') {
    if (!missingTranslations.includes(err.descriptor?.id)) {
      missingTranslations.push(err.descriptor.id);
      dispatch({
        type: LobbyActionTypes.ADD_MISSING_TRANSLATION_VALUE,
        payload: err.descriptor.id,
      });
    }
    window.clearTimeout(missingTranslationsTimeout);
    missingTranslationsTimeout = window.setTimeout(logMissingTranslations, 3000);
    return;
  }
  throw err;
};
