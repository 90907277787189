import React from 'react';
import useJackpotCategory from '../../hooks/useJackpotCategory';
import LinkedJackpot from './LinkedJackpot/TickerDataContainer';
import ErrorBoundary from '../ErrorBoundary';

export enum StyleTypeJackpotCategory {
  LINKED_JACKPOTS = 'LINKED_JACKPOTS'
}

interface JackpotCategoryProperties {
  className?: string;
  categoryCode?: string;
}

/**
 * The jackpotTickerType component displays a ticker depending on the style
 * The hook useJackpotCategory helps to understand what kind of ticker should be displayed
 */
function JackpotTickerType({
  className, categoryCode,
}: JackpotCategoryProperties) {
  const { withJackpot, categoryStyle } = useJackpotCategory(categoryCode);

  if (!withJackpot) {
    return null;
  }

  return (
    <div className={className}>
      {(() => {
        switch (categoryStyle) {
          case StyleTypeJackpotCategory.LINKED_JACKPOTS:
            return (
              <ErrorBoundary componentName="LinkedJackpot">
                <LinkedJackpot categoryCode={categoryCode} />
              </ErrorBoundary>
            );
          default:
            return null;
        }
      })()}
    </div>
  );
}

JackpotTickerType.defaultProps = {
  className: undefined,
  categoryCode: undefined,
};

export default React.memo(JackpotTickerType);
