import { MutableRefObject, useEffect, useRef } from 'react';
import { IntersectionObserverOptions } from '../interfaces/IntersectionObserver';

/**
 * Hook for listening a HTML element moving in/out the browser's viewport area based on
 * Intersection Observer.
 * @param ref HTML element to watch
 * @param callbackHandler function to call when element's visibility changes, must accept one
 * boolean parameter (isIntersecting)
 */
export default (
  ref: MutableRefObject<HTMLDivElement | null>,
  callbackHandler: Function,
  options: IntersectionObserverOptions = {},
) => {
  const observer = useRef<null | IntersectionObserver>(null);

  useEffect(() => {
    if (!ref || !ref.current || !callbackHandler) {
      return () => {};
    }

    observer.current = new IntersectionObserver(([entry]) => {
      const isElementIntersecting = entry.isIntersecting;
      callbackHandler(isElementIntersecting);
    }, options);

    observer.current.observe(ref.current);

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [ref, callbackHandler, options]);
};
