import {
  MutableRefObject, useCallback, useEffect, useRef, useState,
} from 'react';

import useIntersectionObserver from './useIntersectionObserver';
import { IntersectionObserverOptions } from '../interfaces/IntersectionObserver';

const options: IntersectionObserverOptions = {
  delay: 100,
  rootMargin: '300px 0px 300px 0px',
};

export default (
  ref: MutableRefObject<HTMLDivElement | null>,
  totalComponents: number,
) => {
  const isElementIntersectingRef = useRef<boolean>(false);
  const [counter, setCounter] = useState(3);

  const callbackHandler = useCallback((isElementIntersecting: boolean) => {
    isElementIntersectingRef.current = isElementIntersecting;
    if (isElementIntersecting && counter < totalComponents) {
      setCounter(counter + 1);
    }
  }, [totalComponents, counter]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isElementIntersectingRef.current && counter < totalComponents) {
        callbackHandler(true);
      }
    }, 300);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [callbackHandler, counter, totalComponents]);

  useIntersectionObserver(ref, callbackHandler, options);

  return counter;
};
