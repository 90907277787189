import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { GameCategoryItem, GameImage, RootState } from '../../../../interfaces';
import { lobbyActions } from '../../../../store/actions';
import ImageCarouselDesktop from './ImageCarouselDesktop';
import GameDetailsButtons from '../Shared/GameDetailsButtons';
import { breakpoints, colors } from '../../../../constants/style-variables';
import IconButton from '../../../rsi/Button/IconButton';
import { useTypedDispatch } from '../../../../store';
import usePayline from '../../../../hooks/usePayline';
import Ribbons from '../../Ribbons/Ribbons';
import LargestWinSection from './LargestWinSection';
import getTransferredDollarsIntoCents from '../../../../utils/getTransferredDollarsIntoCents';
import { Translations } from '../../../../constants/translations';

interface GameDetailsDesktopProperties {
  className?: string;
  game?: GameCategoryItem;
  withJackpot?: boolean;
}

const componentPadding = 24;
const rightPanelHeight = 214;
const headerHeight = 30;
const headerPadding = 16;
const headerBlock = headerHeight + headerPadding;
const buttonsHeight = 40;
// this measure is from GameDetailsButtons
const buttonsPadding = 24;
const buttonsBlock = buttonsHeight + buttonsPadding;
const readMoreButtonHeight = 20;
const readMoreButtonPadding = 3;
const readMoreButtonBlock = readMoreButtonHeight + readMoreButtonPadding;
const marginAfterDescription = 19;
const componentHeight = componentPadding * 2
  + headerBlock
  + rightPanelHeight
  + buttonsBlock
  + marginAfterDescription
  + readMoreButtonBlock;

/**
 * Game details component for desktop layout. The text needs to be wrapped around game image and
 * there must be possibility to expand/close long text description.
 * - Text container needs to use block and not flex layout, otherwise there will be no wrapping
 * around floating image.
 * - Long texts are cut off with overflow.
 * - Panel component needs to be in full height from the start, otherwise cannot calculate the
 * height for fixed img placeholder (cannot rely on img itself as that is loaded dynamically and
 * comes few milliseconds later). If there is a need for an opening sliding animation then that
 * animation needs to happen in a parent wrapper component.
 * - Panel's height in smaller devices (like tablets) is smaller.
 */
function GameDetailsDesktop({ game, className, withJackpot }: GameDetailsDesktopProperties) {
  const clientType = useSelector((state: RootState) => state.application.clientType);
  const detailedGame = useSelector((state: RootState) => state.lobby.detailedGame);
  const detailedGameInfo = useSelector((state: RootState) => state.lobby.detailedGameInfo);
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const width = useSelector((state: RootState) => state.application.width);

  const rsiFormat = useSelector((state: RootState) => state.application.rsiFormat);
  const paylineSplit = getTransferredDollarsIntoCents(rsiFormat, detailedGameInfo?.minBet);
  const payline = usePayline(detailedGameInfo)?.split(`${paylineSplit}`);

  const [hasExpandButton, setHasExpandButton] = useState(false);
  const [textExpanded, setTextExpanded] = useState(false);

  const hasSmallPanel = width !== undefined && width < breakpoints['screen-md-min'];

  const dispatch = useTypedDispatch();
  const onExitClick = () => {
    dispatch(lobbyActions.setDetailedGame(null, null, null));
  };

  /**
   * Fetches details info for the item game if it's missing. Needs to check item and game code match
   * because the game code may change when the current/old detail panel is still opened.
   */
  useEffect(() => {
    if (clientType && !detailedGameInfo
      && detailedGame && detailedGame.item.code === game?.code) {
      setTextExpanded(false);
      dispatch(lobbyActions.fetchGamesDetails(
        detailedGame.item.code,
        isLoggedIn ? 'REAL' : 'FUN',
        detailedGame.item.gameProvider,
        clientType,
      ));
    }
  }, [dispatch, game, clientType, detailedGameInfo, isLoggedIn, detailedGame]);

  const measureRef = useCallback((node: HTMLInputElement | null) => {
    if (!node) {
      return;
    }
    setHasExpandButton(node.scrollHeight > node.offsetHeight);
  }, []);

  if (!detailedGameInfo || !detailedGame || detailedGame.item?.code !== game?.code) {
    return null;
  }

  const images: GameImage[] = [{
    gameCode: game?.code,
    gameName: detailedGameInfo.gameName,
  }];

  return (
    <GameDetailsDesktopComponent
      className={className}
      textExpanded={textExpanded}
      height={componentHeight}
      data-test-id="game-details-desktop"
      withJackpot={withJackpot}
    >
      <Header>
        <GameName>
          {detailedGame.item.name}
        </GameName>
        <HeaderRightSideContainer>
          <RibbonsContainer>
            {payline && (
              <Payline>
                {payline[0]}
                <strong>{paylineSplit}</strong>
                {payline[1]}
              </Payline>
            )}
            <Ribbons item={game} />
          </RibbonsContainer>
          <ExitButton imageName="icon-exit.png" onClick={onExitClick} />
        </HeaderRightSideContainer>
      </Header>
      <ContentWrapper>
        <LeftSideWrapper textExpanded={textExpanded}>
          <GameDescriptionWrapper>
            <GameDescription
              ref={measureRef}
              hasSmallPanel={hasSmallPanel}
              textExpanded={textExpanded}
            >
              {detailedGameInfo.gameDescription}
            </GameDescription>
            {hasExpandButton
              && (
                <ReadButton onClick={() => setTextExpanded(!textExpanded)}>
                  <FormattedMessage
                    id={textExpanded ? Translations.READ_LESS : Translations.READ_MORE}
                  />
                </ReadButton>
              )}
          </GameDescriptionWrapper>
          <LargestWinSection detailedGameInfo={detailedGameInfo} />
        </LeftSideWrapper>
        <RightSideWrapper height={rightPanelHeight}>
          <ImageCarouselDesktop images={images} code={detailedGame.item.code} />
        </RightSideWrapper>
      </ContentWrapper>
      <GameDetailsButtonsDesktop
        gameData={detailedGame.item}
        logData={detailedGame.logData}
        onClick={onExitClick}
        isLoggedIn={isLoggedIn}
        disableDemoMode={detailedGameInfo.demoModeDisabled}
      />
    </GameDetailsDesktopComponent>
  );
}

GameDetailsDesktop.defaultProps = {
  className: undefined,
  game: undefined,
  withJackpot: false,
};

interface TextExpandedProps {
  textExpanded: boolean;
}

const LeftSideWrapper = styled.div<TextExpandedProps>`
  ${(props) => !props.textExpanded && css`
    display: flex;
    flex-direction: column;
    width: 45%;
  `}

  background-color: transparent;
`;

interface RightSideWrapperProps {
  height: number;
}

/*
* Image will take 55% of the panel's width, but needs to have left margin to have a gap between
* text description and the image on the smaller screen sizes
* */
const RightSideWrapper = styled.div<RightSideWrapperProps>`
  float: right;
  width: calc(55% - 16px);
  margin-left: 24px;
  background-color: ${colors.blueDark};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${(props) => props.height}px;
`;

const GameDetailsButtonsDesktop = styled(GameDetailsButtons)`
  padding-bottom: 0px;
  justify-content: space-around;
  max-width: fit-content;

  .button {
    height: ${buttonsHeight}px;
    min-width: 90px;
    width: 95px;
  }

  .button-login {
    color: ${colors.white};
    border: solid 1.5px ${colors.white};
  }

  .button-favorite {
    color: ${colors.white};
    width: 100%;
    padding-left: 24px;
  }

  .button-demo {
    color: ${colors.white};
  }
`;

const ExitButton = styled(IconButton)`
  border: 0;
  background-color: transparent;
  width: 13px;
  height: 13px;

  img {
    background-color: ${colors.blueDark};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${headerPadding}px;
`;

const Payline = styled.span`
  font-size: 12px;
  line-height: 15px;
  color: ${colors.white};
`;

const ContentWrapper = styled.div`
  display: flex;
`;

const GameName = styled.div`
  font-weight: 600;
  font-size: 24px;
  color: ${colors.white};
`;

const HeaderRightSideContainer = styled.div`
  display: flex;
  height: 18px;
`;

const RibbonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
  gap: 0 4px;
`;

const ReadButton = styled.div`
  color: ${colors.white};
  cursor: pointer;
  text-decoration: underline;
  padding-top: ${readMoreButtonPadding}px;
`;

const GameDescriptionWrapper = styled.div`
  margin-bottom: ${marginAfterDescription}px;
`;

interface GameDescriptionProps {
  textExpanded: boolean;
  hasSmallPanel: boolean;
}

const GameDescription = styled.div<GameDescriptionProps>`
  ${(props) => !props.textExpanded && css`
    overflow: hidden;
    height: 140px;
  `}
  font-size: 16px;
  line-height: 28px;
  color: ${colors.white};
`;

interface DetailsDesktopProps {
  textExpanded: boolean;
  height: number;
  withJackpot?: boolean;
}

const GameDetailsDesktopComponent = styled.div<DetailsDesktopProps>`
  ${(props) => !props.textExpanded && css`
    max-height: ${props.height}px;
    display: flex;
    flex-direction: column;
  `}

  ${({ withJackpot }) => withJackpot && css`
    border-bottom-right-radius: 14px;
    border-bottom-left-radius: 14px;
  `}

  position: relative;
  background-color: ${colors.blueDark};
  width: 100%;
  padding: ${componentPadding}px;
`;

export default GameDetailsDesktop;
