import { RefObject, useEffect } from 'react';
// @ts-ignore
import { fromEvent } from 'rxjs';
import { tap, throttleTime } from 'rxjs/operators';

const useScrollEvent = (
  ref: RefObject<HTMLDivElement>,
  callback: (event: Event) => void,
  interval: number = 1000,
) => {
  useEffect(() => {
    if (!ref.current) {
      return () => {};
    }

    const eventSubscription = fromEvent(ref.current, 'scroll')
      .pipe(
        throttleTime(interval),
        tap(callback),
      )
      .subscribe();

    return () => {
      eventSubscription.unsubscribe();
    };
  }, [ref, callback, interval]);
};

export default useScrollEvent;
