import { AnyAction } from 'redux';
import { gamesReducer } from '../../utils';
import { SearchState } from '../../interfaces';
import { SearchActionTypes } from '../actionTypes';

const initialState: SearchState = {
  searchString: '',
  searchVisible: false,
  games: null,
  prevExpCatType: null,
};

export default (
  state = initialState,
  { type, payload }: AnyAction = { type: SearchActionTypes.DEFAULT },
) => {
  switch (type) {
    case SearchActionTypes.GET_SEARCH_SUCCESS: {
      const updatedGamesList = gamesReducer(state.games, payload);
      if (updatedGamesList) {
        return {
          ...state,
          games: updatedGamesList,
        };
      }
      return state;
    }
    case SearchActionTypes.SET_SEARCH_STRING: {
      return {
        ...state,
        games: null,
        searchString: payload,
      };
    }
    case SearchActionTypes.SET_PREVIOUS_EXPANDED_CATEGORY:
      return {
        ...state,
        prevExpCatType: payload,
      };
    case SearchActionTypes.SET_SEARCH_VISIBLE: {
      if (state.searchVisible !== payload) {
        return {
          ...state,
          searchVisible: payload,
        };
      }
      return state;
    }
    default:
      return state;
  }
};
