import React from 'react';
import styled from 'styled-components';
import { BannerConfig } from '../../interfaces';
import logger from '../../services/logger';

export const CONTAINER_CLASS_NAME = 'lobby-banner';
export const getContainerId = (bannerId: number) => `${CONTAINER_CLASS_NAME}-${bannerId}`;

interface BannerProperties {
  bannerConfig: BannerConfig;
}

/**
 * @param bannerConfig
 * @constructor
 */
function BannerComponent({ bannerConfig }: BannerProperties) {
  if (!RSIClient || !RSIClient?.UMDLoader || !RSIClient?.modules?.RSI_BANNERS) {
    logger.error(`Failed to load the rsi-bannerMFE(bannerID:${bannerConfig?.id}). Please ensure that RSIClient, UMDLoader, and RSI_BANNERS modules are properly initialized.`);
    return null;
  }
  const UMDLoader = RSIClient?.UMDLoader;
  const containerId = getContainerId(bannerConfig.id);
  return (
    <UMDLoader
      name={RSIClient.modules.RSI_BANNERS}
      containerId={containerId}
      props={{
        initialState: { bannerConf: bannerConfig },
      }}
    />
  );
}

const MemoizedBannerComponent = React.memo(
  BannerComponent,
  (prevProps, nextProps) => prevProps.bannerConfig.id === nextProps.bannerConfig.id,
);

const StyledBanner = styled(MemoizedBannerComponent)`
  width: 100%;
  position: relative;
`;

export default StyledBanner;
