import React, { createContext, ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';

export const VerticalVirtualScrollContext = createContext(true);

interface VirtualScrollProps {
  children: ReactNode;
}

function VerticalVirtualScroll({ children }: VirtualScrollProps) {
  const { ref, inView } = useInView();

  return (
    <VerticalVirtualScrollContext.Provider value={inView}>
      <div ref={ref}>
        {children}
      </div>
    </VerticalVirtualScrollContext.Provider>
  );
}

export default VerticalVirtualScroll;
