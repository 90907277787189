import React from 'react';
import styled from 'styled-components';

interface NewsFeedProperties {
  className?: string;
}

/**
 * Displays newsfeed in main lobby.
 */
function NewsFeedComponent({ className }: NewsFeedProperties) {
  const UMDLoader = RSIClient?.UMDLoader;
  return (
    <div id="rsi-newsfeed-lobby" className={className}>
      <UMDLoader
        name={window.RSIClient.modules.RSI_NEWSFEED}
      />
    </div>
  );
}

NewsFeedComponent.defaultProps = {
  className: undefined,
};

const NewsFeed = styled(NewsFeedComponent)`
  width: 100%;
  position: relative;
`;

export default React.memo(NewsFeed);
