import React from 'react';
import styled from 'styled-components';
import SkeletonLoader from './SkeletonLoader';
import { paddings } from '../../constants/style-variables';
import { TileSize } from '../../interfaces/TileSize';
import { IMG_SIZE_HEIGHT, IMG_SIZE_WIDTH } from '../../constants/image-variables';

interface SkeletonGamesRowProperties {
  numberOfGames: number;
}

function SkeletonGamesRow({ numberOfGames }: SkeletonGamesRowProperties) {
  const width = IMG_SIZE_WIDTH.get(TileSize.S);
  const height = IMG_SIZE_HEIGHT.get(TileSize.S);
  return (
    <CategoryWrapper>
      <SkeletonLoader width={134} height={20} />
      <GamesWrapper>
        {[...Array(numberOfGames)].map((_, index) => {
          const key = `skg_${index}`;
          return (
            <SkeletonLoader
              key={key}
              width={width}
              height={height}
              minWidth={134}
              minHeight={84}
              hasAspectRatio
            />
          );
        })}
      </GamesWrapper>
    </CategoryWrapper>
  );
}

const GamesWrapper = styled.div`
  margin: 12px 0;
  display: grid;
  gap: ${paddings.gameTileGapWidth.get(TileSize.S)}px;
  grid-template-columns: repeat(6, 1fr);
`;

const CategoryWrapper = styled.div`
  margin: 12px 18px;
`;

export default SkeletonGamesRow;
