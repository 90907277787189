import { RsiFormat } from '../interfaces';

const getTransferredDollarsIntoCents = (
  rsiFormat: RsiFormat | null,
  sumInDollars?: number | string | null,
): null | string => {
  const numInCurrencyNumber = Number(sumInDollars);
  if (
    Number.isNaN(numInCurrencyNumber)
    || typeof numInCurrencyNumber !== 'number'
    || !rsiFormat
    || sumInDollars == null) {
    return null;
  }
  return rsiFormat.Currency(numInCurrencyNumber * 100);
};

export default getTransferredDollarsIntoCents;
