import { createGlobalStyle, css } from 'styled-components';
import { breakpoints, colors } from '../constants/style-variables';

// stylelint-disable selector-max-id
const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Archivo';
    src: url('../resources/fonts/Archivo/Archivo-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
  }
  ${(props: any) => props.theme.isMobile && css`

    .lobby-container {
      margin: 0px -15px;
      padding-bottom: 8px;
    }

    .all-games-banner {
      margin-left: -15px;
      margin-right: -15px;
    }

    .main-page-view-all-games {
      background-color: ${colors.white};
      min-height: 213px;
      width: 100%;
      .page-content {
        margin: 0;
      }
    }
  `};

  .use-soft-hibernate-mode {
    display: none;
  }

  .main-page-view-all-games {
    .page-content {
      margin-top: 0;
    }
  }

  @media (min-width: ${breakpoints['screen-md-min']}px) {

    .all-game-list {
      padding-top: 10px;
    }
  }

  body.page-all-games .page-main-content-inner {
    min-height: 100px;
  }

  .main-page-view-all-games_without_legacy_banners {

    width: 100% !important;

    > .row > .col-sm-12 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .row .col-sm-12 .bvoffsete1 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
`;

export default GlobalStyle;
