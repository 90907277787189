import React, { ErrorInfo, ReactElement } from 'react';
import { logger } from '../../services';

type ErrorBoundaryProps = {
  componentName: string;
  children: ReactElement | ReactElement[];
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    logger.error('Error rendering', error);

    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { componentName } = this.props;
    logger.error(`Error rendering ${componentName}`, error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return null;
    }

    return children;
  }
}

export default ErrorBoundary;
