/* eslint-disable import/prefer-default-export */

export enum Translations {
  CATEGORY_EMPTY = 'CATEGORY_EMPTY',
  DETAILS_WON = 'DETAILS_WON',
  EXPAND_MORE_GAMES = 'EXPAND_MORE_GAMES',
  FAVORITE_GAME = 'FAVORITE_GAME',
  GAME_DETAILS_LARGEST_WIN_INFO = 'GAME_DETAILS_LARGEST_WIN_INFO',
  GAME_DETAILS_MINBET_INFO = 'GAME_DETAILS_MINBET_INFO',
  GAME_DETAILS_PAYLINES_INFO = 'GAME_DETAILS_PAYLINES_INFO',
  GAME_INFO = 'GAME_INFO',
  HOT_PAYOUT_PERCENTAGE = 'HOT_PAYOUT_PERCENTAGE',
  LOGIN = 'LOGIN',
  PLAY_DEMO = 'PLAY_DEMO',
  PLAY_GAME = 'PLAY_GAME',
  READ_LESS = 'READ_LESS',
  READ_MORE = 'READ_MORE',
  SEARCH = 'SEARCH',
  SEARCH_MIN_REQUIRES = 'SEARCH_MIN_REQUIRES',
  SEARCH_RESULT_EMPTY = 'SEARCH_RESULT_EMPTY',
  SIGNUP = 'SIGNUP',
  TILE_MORE_GAMES = 'TILE_MORE_GAMES',
  UNFAVORITE_GAME = 'UNFAVORITE_GAME',
  HOURLY_CATEGORY_JACKPOT = 'HOURLY_CATEGORY_JACKPOT',
  DAILY_CATEGORY_JACKPOT = 'DAILY_CATEGORY_JACKPOT',
  DAILY_HOT_CATEGORY_JACKPOT = 'DAILY_HOT_CATEGORY_JACKPOT',
  HOURLY_HOT_CATEGORY_JACKPOT = 'HOURLY_HOT_CATEGORY_JACKPOT',
  PROGRESSIVE = 'PROGRESSIVE',
}
