import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { colors } from '../../../constants/style-variables';
import { Translations } from '../../../constants/translations';

interface HpProperties {
  className?: string;
  payout: number | string;
}

function HotPayoutComponent({ className, payout }: HpProperties) {
  if (!payout) {
    return null;
  }
  return (
    <div className={className}>
      <FormattedMessage id={Translations.HOT_PAYOUT_PERCENTAGE} values={{ percentage: payout }} />
    </div>
  );
}

HotPayoutComponent.defaultProps = {
  className: undefined,
};

const HotPayout = styled(HotPayoutComponent)`
  flex-grow: 1;
  background: ${colors.blue3};
  border: 1px solid ${colors.blue3};
  box-shadow: 0px 1px 6px ${colors.blue3}, 0px 0px 2px rgba(0, 0, 0, .1);
  border-radius: 4px;
  text-align: center;
  font-weight: 700;
  color: ${colors.blueLight2};
`;

export const DetailsHotPayout = styled(HotPayout)`
  box-shadow: none;
  padding: 0 12px;
  line-height: 16px;
  font-size: 12px;
  flex-grow: 0;
`;

export default HotPayout;
