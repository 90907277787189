import styled, { css } from 'styled-components';

interface SkeletonLoaderProperties {
  width?: number | string;
  minWidth?: number | string;
  height?: number | string;
  minHeight?: number | string;
  hasAspectRatio?: boolean;
}

/**
 * WARNING: do not add any kind of animation to these css styles, this might impact the general
 * performance of casino-lobby since this component is meant to be placed on every game tile
 * while its image is being fetched.
 */
const SkeletonLoader = styled.div<SkeletonLoaderProperties>`
  border-radius: 4px;
  background: #CDD5DC;
  ${(props) => (
    props.hasAspectRatio
      ? `aspect-ratio: auto ${props.width ?? 0} / ${props.height ?? 0};`
      : `width: ${props.width ?? 0}px; height: ${props.height ?? 0}px;`
  )};
  ${(props) => props && css`
    min-width: ${props.minWidth ?? 0}px;
    min-height: ${props.minHeight ?? 0}px;
  `};
`;

export default SkeletonLoader;
