const debounce = (callback: Function, delay: number, performFirstCall?: boolean) => {
  let timerId: any;

  return (...args: any) => {
    if (performFirstCall && !timerId) {
      callback(...args);
    }

    clearTimeout(timerId);

    timerId = setTimeout(() => callback(...args), delay);
  };
};

export default debounce;
