import { AnyAction } from 'redux';
import { AuthActionTypes } from '../actionTypes';
import { AuthState } from '../../interfaces';

const initialState: AuthState = {
  isLoggedIn: false,
  isInternal: false,
};

export default (
  state = initialState,
  { type, payload }: AnyAction = { type: AuthActionTypes.DEFAULT },
) => {
  switch (type) {
    case AuthActionTypes.SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: payload,
      };
    case AuthActionTypes.SET_IS_INTERNAL:
      return {
        ...state,
        isInternal: payload,
      };
    default:
      return state;
  }
};
