import React from 'react';
import styled from 'styled-components';

function BubbleComponent({ className }: {className?: string}) {
  return <div className={className} />;
}

BubbleComponent.defaultProps = {
  className: undefined,
};

const BubbleMobile3 = styled(BubbleComponent)`
  position: absolute;
  width: 55px;
  height: 55px;
  top: 72px;
  right: -25px;
  background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0.58) 38%, rgba(255, 255, 255, 0.27) 68%, rgba(255, 255, 255, 0.07) 89%, rgba(255, 255, 255, 0) 100%);
  background-blend-mode: overlay;
  mix-blend-mode: overlay;
  border-radius: 14px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
`;

export default BubbleMobile3;
