import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../interfaces';
import { StyleTypeJackpotCategory } from '../components/JackpotCategory/JackpotTickerType';

type jackpotCategory = | {withJackpot : true, categoryStyle : StyleTypeJackpotCategory}
  | {withJackpot : false, categoryStyle : undefined};

/**
 * The hook useJackpotCategory category helps to understand whether it is a jackpot category or not.
 * The category has to have its own jackpot. It could be the main, or the hourly, or the daily
 * jackpots.
 */
const useJackpotCategory = (gameCategoryCode?: string): jackpotCategory => {
  const components = useSelector((state: RootState) => state.lobby.components);
  const [jackpotCategory, setJackpotCategory] = useState<jackpotCategory>(
    { withJackpot: false, categoryStyle: undefined },
  );

  useEffect(() => {
    const chosenGameCategory = components.find((category) => category.code === gameCategoryCode);
    if (chosenGameCategory?.data?.styleType) {
      setJackpotCategory(
        { withJackpot: true, categoryStyle: chosenGameCategory?.data?.styleType },
      );
    } else {
      setJackpotCategory({ withJackpot: false, categoryStyle: undefined });
    }
  }, [gameCategoryCode, components]);

  return jackpotCategory;
};

export default useJackpotCategory;
