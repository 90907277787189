import React from 'react';
import styled from 'styled-components';
import ScrollDot from '../../../rsi/Menu/MenuDot';
import IconButton from '../../../rsi/Button/IconButton';

interface ScrollTrackerDesktopProperties {
  className?: string;
  imageCodes: string[];
  activeImageIndex: number;
  setActiveImage: any;
  scrollNext?: any;
  scrollPrev?: any;
}

// eslint-disable-next-line max-len
function ScrollTrackerDesktopComponent({
  className, imageCodes, scrollPrev, scrollNext, activeImageIndex, setActiveImage,
}: ScrollTrackerDesktopProperties) {
  return (
    <div className={className}>
      <ScrollPrev className="scroll-prev-img" imageName="icon-down-carrot.png" onClick={scrollPrev} />
      <DotWrapper>
        {imageCodes.map((code, i) => (
          <ScrollDot
            className={`scroll-dot-${code}`}
            active={activeImageIndex === i}
            onClick={() => setActiveImage(i)}
          />
        ))}
      </DotWrapper>
      <ScrollNext className="scroll-prev-img" imageName="icon-down-carrot.png" onClick={scrollNext} />
    </div>
  );
}

ScrollTrackerDesktopComponent.defaultProps = {
  className: undefined,
  scrollNext: undefined,
  scrollPrev: undefined,
};

const ScrollPrev = styled(IconButton)`
  border: 0;
  background-color: transparent;
  width: 12px;
  filter: brightness(0) invert(1);
`;

const ScrollNext = styled(IconButton)`
  border: 0;
  background-color: transparent;
  width: 12px;
  transform: rotateZ(180deg);
  filter: brightness(0) invert(1);
`;

const ScrollTrackerDesktop = styled(ScrollTrackerDesktopComponent)`
  margin-top: 15px;
  width: 60%;
  justify-content: space-evenly;
  display: inline-flex;
  flex-direction: row;
`;

const DotWrapper = styled.div`
  width: auto;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`;

export default ScrollTrackerDesktop;
