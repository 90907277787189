import React from 'react';
import styled from 'styled-components';
import { GameDetails } from '../../../../interfaces';
import { colors } from '../../../../constants/style-variables';
import usePayline from '../../../../hooks/usePayline';

interface GameDetailsPaylineMobileProperties {
  gameDetails: GameDetails | null;
}

function GameDetailsPaylineMobileComponent({ gameDetails }: GameDetailsPaylineMobileProperties) {
  const payline = usePayline(gameDetails);

  return (
    <HeaderRow>
      {payline
        && (
          <ActionSpan>{payline}</ActionSpan>
        )}
    </HeaderRow>
  );
}

const GameDetailsPaylineMobile = styled(GameDetailsPaylineMobileComponent)`
  width: 100%;
  display: block;
  padding-bottom: 5px;
`;

/**
 * Payline info is displayed between game title and game image. Usually game image comes visible
 * first, before game details get fetched from backend. The after element acts as a placeholder so
 * the image would not move it's position. Also if no info about payline then the area still needs
 * to be occupied by an empty space.
 */
const HeaderRow = styled.div`
  display: inline-flex;
  font-size: 12px;
  line-height: 12px;

  :after {
    content: '\\200b';
  }
`;

const ActionSpan = styled.span`
  color: ${colors.black};
  margin: auto;

  @media only screen and (orientation: landscape) {
    text-align: left;
    margin: 0;
  }
`;

export default GameDetailsPaylineMobile;
