import React from 'react';
import { useSelector } from 'react-redux';
import { GameCategoryItem, LobbyGameCategory, RootState } from '../../interfaces';
import GameDetailsDesktop from './GameDetailsView/Desktop/GameDetailsDesktop';

interface CatExpDetailsProperties {
  className?: string;
  category: LobbyGameCategory;
  games: GameCategoryItem[];
  withJackpot?: boolean;
}

function CategoryExpandedDetailsComponent({
  category, games, className, withJackpot,
}: CatExpDetailsProperties) {
  const detailedGame = useSelector((state: RootState) => state.lobby.detailedGame);

  if (!detailedGame || detailedGame.categoryId !== category.code) {
    return null;
  }

  return (
    <GameDetailsDesktop
      className={className}
      game={games.find((game) => game.code === detailedGame.item.code)}
      withJackpot={withJackpot}
    />
  );
}

CategoryExpandedDetailsComponent.defaultProps = {
  className: undefined,
  withJackpot: false,
};

export default CategoryExpandedDetailsComponent;
