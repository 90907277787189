import React from 'react';
import LobbyComponentHandler from './LobbyComponentHandler';
import VerticalVirtualScroll from '../rsi/Scroll/VerticalVirtualScroll';
import { LobbyComponent } from '../../interfaces';

interface LobbyComponentVSProperties {
  component: LobbyComponent;
  lastChild: boolean;
}

function LobbyComponentVS({ component, lastChild }: LobbyComponentVSProperties) {
  return (
    <VerticalVirtualScroll>
      <LobbyComponentHandler
        item={component}
        lastChild={lastChild}
      />
    </VerticalVirtualScroll>
  );
}

export default LobbyComponentVS;
