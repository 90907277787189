const requestApiHeaders = () => {
  const headers: any = {
    'Content-Type': 'application/json; charset=utf-8',
  };
  if (rsiApi.getSessionId()) {
    headers['X-Auth-Token'] = rsiApi.getSessionId();
  }

  return headers;
};

export default requestApiHeaders;
