import { v4 as uuidv4 } from 'uuid';

export type TagSegment = {
  id: string;
  type: 'text' | 'anchor';
  content: string;
  href?: string;
}

export function parseTags(inputString: string | undefined): TagSegment[] {
  if (!inputString) {
    return [];
  }
  const regex = /<anchor(?:\s+href="([^"]*)")?>(.*?)<\/anchor>|[^<]+/gi;
  const segments: TagSegment[] = [];

  let match = regex.exec(inputString);
  while (match !== null) {
    const id = uuidv4();
    const [, href = '', content] = match;
    if (content) {
      let path;
      try {
        const url = new URL(href);
        path = url.search + url.hash;
      } catch {
        if (href.startsWith('/')) {
          path = href.replace('/', '');
        } else {
          path = href;
        }
      }

      segments.push({
        type: 'anchor',
        href: path || '',
        content,
        id,
      });
    } else {
      segments.push({ type: 'text', content: match[0], id });
    }
    match = regex.exec(inputString);
  }

  return segments;
}
