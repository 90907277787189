import { useCallback } from 'react';
import { TileSize } from '../interfaces/TileSize';
import { MultiRowTemplate } from '../interfaces';
import { GridComponent } from '../components/GameCategory/MultiRowGridItem/MultiRowGridItem';
import { paddings } from '../constants/style-variables';

const TEMPLATE_BY_SIZE = {
  [TileSize.S]: {
    dimensions: {
      width: 1,
      height: 1,
    },
    components: [
      {
        type: '67:42-img-small',
        width: 1,
        height: 1,
        x: 0,
        y: 0,
      },
    ],
  },
  [TileSize.M]: {
    dimensions: {
      width: 1,
      height: 1,
    },
    components: [
      {
        type: '67:42-img-medium',
        width: 1,
        height: 1,
        x: 0,
        y: 0,
      },
    ],
  },
  [TileSize.L]: {
    dimensions: {
      width: 1,
      height: 1,
    },
    components: [
      {
        type: '67:42-img-large',
        width: 1,
        height: 1,
        x: 0,
        y: 0,
      },
    ],
  },
};

const GRID_RESIZING_INDEX = 2;

const useMultiRowTemplate = () => {
  const getTemplateBy = useCallback((size: TileSize) => TEMPLATE_BY_SIZE[size], []);

  const getTileSize = useCallback((type = '') => {
    const imageSize = type.split('-')[2];
    if (imageSize === 'large') {
      return TileSize.L;
    }

    if (imageSize === 'medium') {
      return TileSize.M;
    }

    return TileSize.S;
  }, []);

  const getGridWidth = useCallback((
    hasMediumTypes: boolean,
    hasLargeTypes: boolean,
    template: MultiRowTemplate,
    tileSize?: number,
  ) => {
    const gridUsesMediumAndLarge = hasMediumTypes && hasLargeTypes;
    const gridHasBigHeight = template && template.dimensions.height > 4;
    /**
     * When the grid uses tiles of medium and large sizes and has more than 4 components on its
     * height, it starts to look way too big in the viewport, we must reduce the grid size
     */
    const gridNeedsToBeResized = tileSize && gridUsesMediumAndLarge && gridHasBigHeight;
    return tileSize && gridNeedsToBeResized ? tileSize / GRID_RESIZING_INDEX : tileSize;
  }, []);

  const getSmallestTileSize = useCallback((template: MultiRowTemplate) => {
    const tileSize: Partial<GridComponent> = {
      width: undefined,
      height: undefined,
    };

    template.components.forEach((component) => {
      if (
        (tileSize.width && tileSize.height)
        && (tileSize.width < component.width && tileSize.height < component.height)
      ) {
        return;
      }

      tileSize.width = component.width;
      tileSize.height = component.height;
    });

    return tileSize;
  }, []);

  const getComponentIncludedTypes = useCallback((template: MultiRowTemplate) => {
    const types = template.components.map(
      (component: GridComponent) => component.type,
    );

    const hasSmallTypes = types.some((type) => type.includes('small'));
    const hasMediumTypes = types.some((type) => type.includes('medium'));
    const hasLargeTypes = types.some((type) => type.includes('large'));

    return { hasSmallTypes, hasMediumTypes, hasLargeTypes };
  }, []);

  const getLargestTileSize = useCallback((template: MultiRowTemplate): TileSize => {
    const { hasMediumTypes, hasLargeTypes } = getComponentIncludedTypes(template);

    if (hasLargeTypes) {
      return TileSize.L;
    }

    if (hasMediumTypes) {
      return TileSize.M;
    }

    return TileSize.S;
  }, [getComponentIncludedTypes]);

  const getSmallestTileWidth = useCallback((
    template: MultiRowTemplate,
    tileWidthMap: Map<TileSize, number>,
  ) => {
    const { hasLargeTypes, hasMediumTypes, hasSmallTypes } = getComponentIncludedTypes(template);

    if (hasSmallTypes) {
      return getGridWidth(
        hasMediumTypes,
        hasLargeTypes,
        template,
        tileWidthMap.get(TileSize.S),
      );
    }

    /**
     * We must set TileSize.L only when the grid only has Large size components
     */
    if (!hasSmallTypes && !hasMediumTypes) {
      return tileWidthMap.get(TileSize.L);
    }

    return getGridWidth(
      hasMediumTypes,
      hasLargeTypes,
      template,
      tileWidthMap.get(TileSize.M),
    );
  }, [getGridWidth, getComponentIncludedTypes]);

  const getSmallestTileDimensions = useCallback((
    template: MultiRowTemplate,
    tileWidthMap: Map<TileSize, number>,
  ): [number | undefined, Partial<GridComponent>] => {
    const tileSmallestWidth = getSmallestTileWidth(template, tileWidthMap);
    const tileSmallestSize = getSmallestTileSize(template);
    return [tileSmallestWidth, tileSmallestSize];
  }, [getSmallestTileSize, getSmallestTileWidth]);

  const getGap = useCallback((template: MultiRowTemplate) => {
    const { dimensions, components } = template;
    const isSingleRow = dimensions.height === 1 && dimensions.width === 1;

    if (isSingleRow) {
      const singleRowSize = getTileSize(components[0].type);
      return paddings.gameTileGapWidth.get(singleRowSize);
    }

    return paddings.multiRowFixedGap;
  }, [getTileSize]);

  const getNeedsMargin = useCallback((
    components: GridComponent[],
    current: GridComponent,
  ) => {
    const previousComponents = components.filter(
      (previous) => (previous.x < current.x),
    );

    return previousComponents.some(
      (previous) => (previous.x + previous.width - 1) >= current.x,
    );
  }, []);

  const getComponentsWithMargin = useCallback((
    components: GridComponent[],
  ) => components.map((component: GridComponent) => ({
    ...component,
    needsMargin: getNeedsMargin(components, component),
  })), [getNeedsMargin]);

  const getTemplateWidthGaps = useCallback((template: MultiRowTemplate) => {
    let largestRowComponents: GridComponent[] = [];
    let largestWidth = 0;
    const componentsY = template.components.map((component: GridComponent) => component.y);
    const rows = componentsY.filter((y, index, array) => array.indexOf(y) === index);

    rows.forEach((row) => {
      const rowComponents = template.components.filter(
        (component) => component.y === row,
      );
      const rowWidth = rowComponents
        .reduce((partial, current) => partial + current.width, 0);

      if (rowWidth > largestWidth) {
        largestWidth = rowWidth;
        largestRowComponents = rowComponents;
      }
    });

    const componentsWithMargin = largestRowComponents
      .reduce((partial, current) => {
        if (current.needsMargin) {
          return partial + 1;
        }

        return partial;
      }, 0);

    const expandedComponentsGaps = largestRowComponents
      .reduce((partial, current) => {
        if (current.width > 1) {
          return partial + (current.width - 1);
        }

        return partial;
      }, 0);

    return (componentsWithMargin + expandedComponentsGaps) * paddings.multiRowFixedGap;
  }, []);

  const getNeededGridCopies = useCallback((
    totalGames: number,
    pageSize: number,
    template: MultiRowTemplate,
  ) => {
    const width = totalGames > pageSize ? pageSize : totalGames;
    return Math.ceil(width / template.components.length);
  }, []);

  const getGridWidthInPixels = useCallback((
    template: MultiRowTemplate,
    tileSmallestWidth: number,
    templateGap: number,
  ) => {
    const isSingleRow = template.dimensions.width === 1 && template.dimensions.height === 1;
    if (isSingleRow) {
      return tileSmallestWidth + templateGap;
    }

    const gaps = template.dimensions.width * templateGap;
    const extendedComponentGaps = getTemplateWidthGaps(template);

    return tileSmallestWidth * template.dimensions.width + (gaps + extendedComponentGaps);
  }, [getTemplateWidthGaps]);

  const getScrollingPoints = useCallback((
    template: MultiRowTemplate,
    totalGames: number,
    pageSize: number,
    tileSmallestWidth?: number,
    templateGap?: number,
  ) => {
    const scrollingPoints: { [point: number]: number } = {};
    if (!tileSmallestWidth || !templateGap) {
      return scrollingPoints;
    }

    const totalGridCopies = getNeededGridCopies(totalGames, pageSize, template);
    const pixels = getGridWidthInPixels(template, tileSmallestWidth, templateGap);

    const pointsLength = Array.from(
      { length: totalGridCopies },
      (_, index) => index + 1,
    );

    pointsLength.forEach((point: number) => {
      scrollingPoints[point] = pixels * point;
    });

    return scrollingPoints;
  }, [getGridWidthInPixels, getNeededGridCopies]);

  return {
    getLargestTileSize,
    getTemplateBy,
    getTileSize,
    getSmallestTileDimensions,
    getScrollingPoints,
    getGap,
    getComponentsWithMargin,
    getNeededGridCopies,
  };
};

export default useMultiRowTemplate;
