import { AnyAction } from 'redux';
import { JackpotsState } from '../../interfaces';
import { JackpotsActionTypes } from '../actionTypes';

const initialState: JackpotsState = {
  supportedGames: [],
  tickerObservers: new Map(),
};

/**
 * Updates jackpot store values. In case of an event:
 *  - UPDATE_SUPPORTED_GAMES: list of supported games comes from jackpot update message (wss). If
 *  there was a jackpot info for a game X then this game is supported, otherwise not. The order of
 *  supported games may vary (WSS vs server event). Games will be only added and not removed from
 *  supported list because different JP messages come for different games, there is no JP type
 *  defined in the protocol, just game codes.
 * @param state current state of jackpot data
 * @param action jackpot action
 */
export default (
  state = initialState,
  { type, payload }: AnyAction = { type: JackpotsActionTypes.DEFAULT },
) => {
  switch (type) {
    case JackpotsActionTypes.UPDATE_SUPPORTED_GAMES: {
      const isSame: boolean = (state.supportedGames.length === payload.length
        && state.supportedGames.every((value) => payload.includes(value)))
        || payload.every((value: string) => state.supportedGames.includes(value));
      if (isSame) {
        return state;
      }
      const mergedResult = state.supportedGames.concat(payload);
      return {
        ...state,
        supportedGames: mergedResult.filter((item, pos) => mergedResult.indexOf(item) === pos),
      };
    }
    default:
      return state;
  }
};
