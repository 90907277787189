import { AnyAction } from 'redux';
import { GamingModeState } from '../../interfaces';
import { GamingModeActionTypes } from '../actionTypes';

const initialState: GamingModeState = {
  gameOpened: false,
};

export default (
  state = initialState,
  { type, payload }: AnyAction = { type: GamingModeActionTypes.DEFAULT },
) => {
  switch (type) {
    case GamingModeActionTypes.SET_GAME_OPENED: {
      return {
        ...state,
        gameOpened: payload,
      };
    }
    default:
      return state;
  }
};
