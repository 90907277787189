import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { gamingModeActions } from '../store/actions';
import eventBus, { eventTypes } from '../services/eventBus';

export default (): void => {
  const dispatch = useDispatch();

  const handleGameClose = useCallback(() => {
    dispatch(gamingModeActions.setGameOpened(false));
  }, [dispatch]);
  const handleGameOpen = useCallback(() => {
    dispatch(gamingModeActions.setGameOpened(true));
  }, [dispatch]);

  useEffect(() => {
    const gameOpenSubscriber = eventBus.subscribe(
      eventTypes.OPEN_GAME,
      handleGameOpen,
    );
    const gameCloseSubscriber = eventBus.subscribe(
      eventTypes.CLOSE_GAME,
      handleGameClose,
    );
    const gameClientOpenSubscriber = eventBus.subscribe(
      eventTypes.OPEN_GAME_CLIENT,
      handleGameOpen,
    );
    const gameClientCloseSubscriber = eventBus.subscribe(
      eventTypes.CLOSE_GAME_CLIENT,
      handleGameClose,
    );
    const gameNativeOpenSubscriber = eventBus.subscribe(
      eventTypes.NATIVE_GAME_OPEN,
      handleGameOpen,
    );
    const gameNativeCloseSubscriber = eventBus.subscribe(
      eventTypes.NATIVE_GAME_CLOSE,
      handleGameClose,
    );

    return () => {
      gameOpenSubscriber.unsubscribe();
      gameCloseSubscriber.unsubscribe();
      gameClientOpenSubscriber.unsubscribe();
      gameClientCloseSubscriber.unsubscribe();
      gameNativeOpenSubscriber.unsubscribe();
      gameNativeCloseSubscriber.unsubscribe();
    };
  }, [handleGameOpen, handleGameClose]);
};
