import React from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import DailyAndHourlyJackpot from './DailyAndHourlyJackpot/DailyAndHourlyJackpot';
import { JackpotPotType, RootState } from '../../../interfaces';
import { colors } from '../../../constants/style-variables';
import { DeviceType } from '../../../interfaces/DeviceType';
import DigitContainer, {
  sumBorderRadius,
} from './DigitContainer/DigitContainer';
import { Translations } from '../../../constants/translations';

interface TickerProperties {
  categoryCode?: string;
  hourlyJackpot: string;
  dailyJackpot: string;
  isDailyHot: boolean;
  isHourlyHot: boolean;
  className?: string;
  pauseJackpot?: boolean;
}

const dailyBorderRadiusDesktop = '8% / 70%';
const marginTopDailyJackpotBorderMobile = 18;
const dailyRightMargin = 20;
const fastenSideMargin = 3;
const smallFastenerWidthDesktop = 8;
const bigFastenerWidthDesktop = 10 + fastenSideMargin;
const smallFastenerWidthMobile = 6;
const bigFastenerWidthMobile = 6 + fastenSideMargin;
const commonBorderWidth = 1.5;

/**
 * Ticker component displays under the category in the main lobby and inside the expanded category.
 * The category has to have its own jackpot. It could be the main, or the hourly, or the daily
 * jackpots.
 *
 * The view of Ticker depends on a device and properties.
 * Ticker on mobile devices always has fasteners on the sides. Daily and hourly jackpots are always
 * under the main jackpot. If there is only either daily or hourly jackpot, then it takes all the
 * place, that is reserved for both of them. The hourly and the daily jackpots are hidden, if
 * there's input data for them
 *
 * Ticker on desktop devices has side fasteners only if there's no input data for daily and hourly
 * jackpots. The daily and the hourly jackpots are on the sides and can be replaced with fasteners.
 */
function TickerComponent({
  className,
  categoryCode,
  hourlyJackpot,
  dailyJackpot,
  isDailyHot,
  isHourlyHot,
  pauseJackpot,
}: TickerProperties) {
  const doesHaveHourlyJackpot = Boolean(hourlyJackpot);
  const doesHaveDailyJackpot = Boolean(dailyJackpot);
  const isMobile = useSelector((state: RootState) => state.application.deviceType)
    === DeviceType.Mobile;

  const isShowSideFasteners = isMobile || (!doesHaveHourlyJackpot && !doesHaveDailyJackpot);
  const isShowDailyJackpotDesktop = !isMobile && doesHaveDailyJackpot;
  const isShowHourlyJackpotDesktop = !isMobile && doesHaveHourlyJackpot;
  const isShowDailyJackpotMobile = isMobile && doesHaveDailyJackpot;
  const isShowHourlyJackpotMobile = isMobile && doesHaveHourlyJackpot;
  const isShowJackpotMobile = isShowDailyJackpotMobile || isShowHourlyJackpotMobile;

  return (
    <div className={className}>
      <JackpotContainer>
        <FastenerContainer isShowSideFasteners={isShowSideFasteners}>
          {isShowSideFasteners && <SmallFastenerLeft isMobile={isMobile} />}
          {isShowSideFasteners && <BigFastenerLeft isMobile={isMobile} />}
          {isShowDailyJackpotDesktop
              && (
                <DailyJackpotBorderDesktop hasRightMargin={isShowDailyJackpotDesktop}>
                  <DailyJackpotDesktop
                    isDaily
                    isMobile={isMobile}
                    sum={dailyJackpot}
                    isHot={isDailyHot}
                  />
                </DailyJackpotBorderDesktop>
              )}
          <MainJackpotContainer isMobile={isMobile}>
            <MainJackpotContainerTopBorder isMobile={isMobile}>
              <MainJackpotTopTicker>
                <RibbonWrapper isMobile={isMobile} tickerType={JackpotPotType.PROGRESSIVE}>
                  <FormattedMessage id={Translations.PROGRESSIVE} />
                </RibbonWrapper>
              </MainJackpotTopTicker>
            </MainJackpotContainerTopBorder>
            <MainJackpotBackground isMobile={isMobile}>
              <SumContainerBackground>
                <TopShadowSumContainer />
                <MainShadowSumContainer />
                <DigitContainer
                  categoryCode={categoryCode}
                  isMobile={isMobile}
                  pauseJackpot={pauseJackpot}
                />
              </SumContainerBackground>
              <ShadowBottomTheCommonJackpotBackground />
            </MainJackpotBackground>
          </MainJackpotContainer>
          {isShowSideFasteners && <BigFastenerRight isMobile={isMobile} />}
          {isShowSideFasteners && <SmallFastenerRight isMobile={isMobile} />}
          {isShowHourlyJackpotDesktop
              && (
                <DailyJackpotBorderDesktop hasLeftMargin={isShowHourlyJackpotDesktop}>
                  <HourlyJackpotDesktop
                    isDaily={false}
                    isMobile={isMobile}
                    sum={hourlyJackpot}
                    isHot={isHourlyHot}
                  />
                </DailyJackpotBorderDesktop>
              )}
        </FastenerContainer>
        {isShowJackpotMobile
            && (
              <DailyJackpotOutlineBorderMobile>
                <DailyJackpotContentMobile>
                  {dailyJackpot && (
                    <DailyJackpotInlineLeftBorderMobile
                      isOnlyOneChildren={!hourlyJackpot}
                      isHot={isDailyHot}
                    >
                      <DailyJackpotMobile
                        isDaily
                        isMobile={isMobile}
                        sum={dailyJackpot}
                        isHot={isDailyHot}
                      />
                    </DailyJackpotInlineLeftBorderMobile>
                  )}
                  {hourlyJackpot && (
                    <DailyJackpotInlineRightBorderMobile
                      isOnlyOneChildren={!dailyJackpot}
                      isHot={isHourlyHot}
                    >
                      <HourlyJackpotMobile
                        isDaily={false}
                        isMobile={isMobile}
                        sum={hourlyJackpot}
                        isHot={isHourlyHot}
                      />
                    </DailyJackpotInlineRightBorderMobile>
                  )}
                </DailyJackpotContentMobile>
              </DailyJackpotOutlineBorderMobile>
            )}
      </JackpotContainer>
    </div>
  );
}

TickerComponent.defaultProps = {
  className: undefined,
  categoryCode: undefined,
  pauseJackpot: undefined,
};

const borderRadiusForWrapper = '4% / 70%';
const paddingCommonJackpot = commonBorderWidth;

const smallFastenerHeightDesktop = 29;
const smallFastenerHeightMobile = 16;
const bigFastenerHeightDesktop = 52;
const bigFastenerHeightMobile = 29;
const fastenerTopMargin = 0;
const fastenerRadius = '100px 50px';

const SmallFastenerLeft = styled.div.attrs((props: { isMobile: boolean }) => props)`
  ${({ isMobile }) => `
    height: ${isMobile ? smallFastenerHeightMobile : smallFastenerHeightDesktop}px;
    width: ${isMobile ? smallFastenerWidthMobile : smallFastenerWidthDesktop}px;
  `}
  background: linear-gradient(0deg, #FF6C00 2.97%, #FF6F00 20.97%, #FF7B00 37.97%, #FF8F00 54.97%, #FFAA00 71.97%, #FFCD00 88.97%, #FFE900 99.97%);
  border-top-left-radius: ${fastenerRadius};
  border-bottom-left-radius: ${fastenerRadius};
  margin-top: ${fastenerTopMargin}px;
`;

const BigFastenerLeft = styled.div.attrs((props: { isMobile: boolean }) => props)`
  ${({ isMobile }) => `
    height: ${isMobile ? bigFastenerHeightMobile : bigFastenerHeightDesktop}px;
    width: ${isMobile ? bigFastenerWidthMobile : bigFastenerWidthDesktop}px;
  `}
  background: linear-gradient(274.71deg, #FF6C00 28.05%, #FF6F00 39.78%, #FF7B00 50.86%, #FF8F00 61.94%, #FFAA00 73.01%, #FFCD00 84.09%, #FFE900 91.26%);
  border-top-left-radius: ${fastenerRadius};
  border-bottom-left-radius: ${fastenerRadius};
  margin-top: ${fastenerTopMargin}px;
  margin-right: -${fastenSideMargin}px;
`;

const SmallFastenerRight = styled.div.attrs((props: { isMobile: boolean }) => props)`
  ${({ isMobile }) => `
    height: ${isMobile ? smallFastenerHeightMobile : smallFastenerHeightDesktop}px;
    width: ${isMobile ? smallFastenerWidthMobile : smallFastenerWidthDesktop}px;
  `}
  background: linear-gradient(0deg, #FF6C00 3%, #FF6F00 21%, #FF7B00 38%, #FF8F00 55%, #FFAA00 72%, #FFCD00 89%, #FFE900 100%);
  border-top-right-radius: ${fastenerRadius};
  border-bottom-right-radius: ${fastenerRadius};
  margin-top: ${fastenerTopMargin}px;
`;

const BigFastenerRight = styled.div.attrs((props: { isMobile: boolean }) => props)`
  ${({ isMobile }) => `
    height: ${isMobile ? bigFastenerHeightMobile : bigFastenerHeightDesktop}px;
    width: ${isMobile ? bigFastenerWidthMobile : bigFastenerWidthDesktop}px;
  `}
  background: linear-gradient(85.29deg, #FF6C00 28.09%, #FF6F00 39.81%, #FF7B00 50.88%, #FF8F00 61.95%, #FFAA00 73.02%, #FFCD00 84.09%, #FFE900 91.26%);
  border-top-right-radius: ${fastenerRadius};
  border-bottom-right-radius: ${fastenerRadius};
  margin-top: ${fastenerTopMargin}px;
  margin-left: -${fastenSideMargin}px;
`;

const dailyJackpotBorder = commonBorderWidth;

const DailyJackpotBorderDesktop = styled.div.attrs((props: { hasRightMargin: boolean; hasLeftMargin: boolean; }) => props)`
  border-radius: ${dailyBorderRadiusDesktop};
  min-width: 200px;
  background: linear-gradient(238.93deg, #FFD900 38.38%, #8C00F1 66.57%, #0D17FF 92.58%);
  padding: ${dailyJackpotBorder}px;
  ${({ hasRightMargin, hasLeftMargin }) => `
    margin-right: -${hasRightMargin ? dailyRightMargin : 0}px;
    margin-left: -${hasLeftMargin ? dailyRightMargin : 0}px;
  `}
`;

const dailyJackpotOutlineBorderRadiusMobile = '52px 70px';

const DailyJackpotOutlineBorderMobile = styled.div`
  margin-top: -${marginTopDailyJackpotBorderMobile}px;
  width: calc(100% - ${2 * (smallFastenerWidthMobile + bigFastenerWidthMobile) - 2 * paddingCommonJackpot}px);
  overflow: hidden;
  border-bottom-left-radius: ${dailyJackpotOutlineBorderRadiusMobile};
  border-bottom-right-radius: ${dailyJackpotOutlineBorderRadiusMobile};
`;

const DailyJackpotInlineLeftBorderMobile = styled.div.attrs((props: { isOnlyOneChildren: boolean; isHot: boolean; }) => props)`
  border-bottom-left-radius: ${dailyJackpotOutlineBorderRadiusMobile};
  background: linear-gradient(90deg, #FFD900 -0.58%, #8C00F1 51.76%, #0D17FF 100.08%);

  padding: 0 0 ${commonBorderWidth}px ${commonBorderWidth}px;

  & > div {
    border-bottom-left-radius: ${dailyJackpotOutlineBorderRadiusMobile};
  }

  ${({ isOnlyOneChildren, isHot }) => isOnlyOneChildren && !isHot && css`
    width: 100%;
    & > div {
      padding-left: 0px;
      border-bottom-right-radius: ${dailyJackpotOutlineBorderRadiusMobile};
      background: linear-gradient(270deg, #002C95 -10.53%, #00294B 52.86%);
    }
  `};

  ${({ isOnlyOneChildren, isHot }) => !isOnlyOneChildren && !isHot && css`
    width: 50%;
    & > div {
      padding-left: 13px;
      border-bottom-right-radius: 0;
      background: linear-gradient(90deg, #002C95 0.73%, #00294B 102.65%);
    }
  `};

  ${({ isOnlyOneChildren, isHot }) => isOnlyOneChildren && isHot && css`
    width: 100%;
    & > div {
      padding-left: 0px;
      border-bottom-right-radius: ${dailyJackpotOutlineBorderRadiusMobile};
      background: linear-gradient(149.4deg, #D844E1 29.43%, #DF2C27 63.87%, #F5803A 105.87%);
    }
  `};

  ${({ isOnlyOneChildren, isHot }) => !isOnlyOneChildren && isHot && css`
    width: 50%;
    & > div {
      padding-left: 13px;
      border-bottom-right-radius: 0;
      background: linear-gradient(149.4deg, #D844E1 29.43%, #DF2C27 63.87%, #F5803A 105.87%);
    }
  `};
`;

const DailyJackpotInlineRightBorderMobile = styled.div.attrs((props: { isOnlyOneChildren: boolean; isHot: boolean; }) => props)`
  border-bottom-right-radius: ${dailyJackpotOutlineBorderRadiusMobile};
  background: linear-gradient(270deg, #FFD900 5.26%, #8C00F1 48.43%, #0D17FF 88.28%);
  padding: 0 ${commonBorderWidth}px ${commonBorderWidth}px ${commonBorderWidth}px;

  & > div {
    border-bottom-right-radius: ${dailyJackpotOutlineBorderRadiusMobile};
  }

  ${({ isOnlyOneChildren, isHot }) => isOnlyOneChildren && !isHot && css`
    width: 100%;
    & > div {
      padding-right: 0px;
      border-bottom-left-radius: ${dailyJackpotOutlineBorderRadiusMobile};
      background: linear-gradient(270deg, #002C95 -10.53%, #00294B 52.86%)';
    }
  `};

  ${({ isOnlyOneChildren, isHot }) => !isOnlyOneChildren && !isHot && css`
    width: 50%;
    & > div {
      padding-right: 13px;
      border-bottom-left-radius: 0;
      background: linear-gradient(270deg, #002C95 0.73%, #00294B 102.65%);
    }
  `};

  ${({ isOnlyOneChildren, isHot }) => isOnlyOneChildren && isHot && css`
    width: 100%;
    & > div {
      padding-right: 0px;
      border-bottom-left-radius: ${dailyJackpotOutlineBorderRadiusMobile};
      background: linear-gradient(149.4deg, #D844E1 29.43%, #DF2C27 63.87%, #F5803A 105.87%);
    }
  `};

  ${({ isOnlyOneChildren, isHot }) => !isOnlyOneChildren && isHot && css`
    width: 50%;
    & > div {
      padding-right: 13px;
      border-bottom-left-radius: 0;
      background: linear-gradient(149.4deg, #D844E1 29.43%, #DF2C27 63.87%, #F5803A 105.87%);
    }
  `};
`;

const DailyJackpotContentMobile = styled.div`
  width: 100%;
  display: flex;
`;

const JackpotContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
`;

const FastenerContainer = styled.div.attrs((props: { isShowSideFasteners: boolean }) => props)`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;

  ${({ isShowSideFasteners }) => `
    align-items: ${isShowSideFasteners ? 'center' : 'stretch'};
  `}
`;

const MainJackpotContainer = styled.div.attrs((props: { isMobile: boolean }) => props)`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0px ${paddingCommonJackpot}px ${paddingCommonJackpot}px ${paddingCommonJackpot}px;
  border-radius: ${borderRadiusForWrapper};
  background: linear-gradient(90deg, #FFD900 -0.2%, #8C00F1 52.01%, #0D17FF 100.2%);
  ${({ isMobile }) => isMobile && css`
    width: 100%;
  `}
`;

const commonJackpotContainerHatBorderRadius = '100% 100%';

const mainJackpotContainerTopBorderLeftDesktop = 6;
const mainJackpotContainerTopBorderLeftMobile = 6;
const MainJackpotContainerTopBorder = styled.div.attrs((props: { isMobile: boolean }) => props)`
  position: absolute;
  border-top-left-radius: ${commonJackpotContainerHatBorderRadius};
  border-top-right-radius: ${commonJackpotContainerHatBorderRadius};
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(90deg, rgb(255, 217, 0) -0.2%, rgb(140, 0, 241) 52.01%, rgb(13, 23, 255) 100.2%);
  ${({ isMobile }) => `
    top: ${isMobile ? -10 : -15}px;
    left: ${isMobile ? mainJackpotContainerTopBorderLeftMobile : mainJackpotContainerTopBorderLeftDesktop}px;
    width: ${isMobile ? `calc(100% - ${mainJackpotContainerTopBorderLeftMobile * 2}px)` : `calc(100% - ${mainJackpotContainerTopBorderLeftDesktop * 2}px)`};
    padding: ${isMobile ? 1 : 2}px;
  `};
`;

const MainJackpotTopTicker = styled.div`
  height: 100%;
  border-top-left-radius: ${commonJackpotContainerHatBorderRadius};
  border-top-right-radius: ${commonJackpotContainerHatBorderRadius};
  background: linear-gradient(0deg, #003D6F -10.28%, #00294B 30.4%);
  display: flex;
  justify-content: center;
`;

const MainJackpotBackground = styled.div.attrs((props: { isMobile: boolean }) => props)`
  ${({ isMobile }) => `padding: ${isMobile ? '10px 7px 5px 6px' : '16px 11px 9px 10px'};`}
  border-radius: ${borderRadiusForWrapper};
  background: linear-gradient(0deg, #003D6F -10.28%, #00294B 30.4%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 0;
  height: 100%;
`;

const ShadowBottomTheCommonJackpotBackground = styled.div`
  background-blend-mode: overlay;
  mix-blend-mode: overlay;
  opacity: 0.5;
  background: radial-gradient(40em 8em at 50% -42%, transparent 49%, #003D6F -10.28%, #00294B 30.39%);
  border-bottom-left-radius: 15px 90px;
  border-bottom-right-radius: 15px 90px;
  height: 48px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
`;

const ribbonWrapperFontSizeDesktop = 14;
const ribbonWrapperFontSizeMobile = 10;
const ribbonWrapperPaddingYMobile = 2;
const ribbonWrapperPaddingTopDesktop = 5;
const ribbonWrapperPaddingBottomDesktop = 3;
const RibbonWrapper = styled.span.attrs((props: { isMobile: boolean; tickerType: JackpotPotType; }) => props)`
  ${({ isMobile }) => `
    font-size: ${isMobile ? ribbonWrapperFontSizeMobile : ribbonWrapperFontSizeDesktop}px;
    border-radius: ${isMobile ? '4px 4px 0px 0px' : '3px 3px 0px 0px'};
    padding: ${isMobile ? `${ribbonWrapperPaddingYMobile}px 5px` : `${ribbonWrapperPaddingTopDesktop}px 12px ${ribbonWrapperPaddingBottomDesktop}px 12px`};
    margin-top: ${isMobile ? 5 : 7}px;
    min-height: ${isMobile
    ? ribbonWrapperFontSizeMobile + 2 * ribbonWrapperPaddingYMobile
    : ribbonWrapperFontSizeDesktop
  + ribbonWrapperPaddingTopDesktop
  + ribbonWrapperPaddingBottomDesktop
}px;
  `};
  ${({ tickerType }: {tickerType: JackpotPotType}) => {
    switch (tickerType) {
      case JackpotPotType.PROGRESSIVE:
        return `
          background-color: ${colors.gold};
          color: ${colors.black};
        `;
      default:
        return `
          background-color: ${colors.red};
          color: ${colors.white};
        `;
    }
  }};
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: 700;
  font-family: "DM Sans";
  line-height: 100%;
  background-color: ${colors.gold};
  height: fit-content;
  z-index: 1;
`;

const paddingSumContainerBackground = commonBorderWidth;

const SumContainerBackground = styled.div`
  position: relative;
  padding: ${paddingSumContainerBackground}px;
  border-radius: ${sumBorderRadius};
  background: linear-gradient(238.93deg, #FFD900 38.38%, #8C00F1 66.57%, #0D17FF 92.59%);
`;

const TopShadowSumContainer = styled.div`
  height: 36px;
  background: linear-gradient(180deg, #000AFF -2.98%, rgba(255, 255, 255, 0) 85.57%);
  position: absolute;
  top: ${paddingSumContainerBackground}px;
  left: 0px;
  width: calc(100% - ${paddingSumContainerBackground * 2}px);
  border-radius: 2% / 70%;
`;

const MainShadowSumContainer = styled.div`
  height: 100%;
  background: radial-gradient(41.98% 64.61% at 51.38% 52.5%, rgba(0, 0, 0, 0.3) 25%, rgba(36, 31, 32, 0.8) 100%);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  border-radius: 2% / 70%;
  opacity: 0.5;
`;

const DailyJackpotDesktop = styled(DailyAndHourlyJackpot).attrs((props: { isHot: boolean; }) => props)`

  ${({ isHot }) => !isHot && css`
    background: linear-gradient(90deg, #023DCA 22.86%, #002848 73.33%);
  `}

  ${({ isHot }) => isHot && css`
    background: linear-gradient(174.9deg, #D844E1 31.5%, #DF2C27 61.26%, #F5803A 97.55%);
  `}

  border-radius: ${dailyBorderRadiusDesktop};
  padding: 0px 0px 0px 22px;

  & > div {
    transform: translateX(-${dailyRightMargin}px);
  }
`;

const HourlyJackpotDesktop = styled(DailyAndHourlyJackpot).attrs((props: { isHot: boolean; }) => props)`

  ${({ isHot }) => !isHot && css`
    background: linear-gradient(270deg, #023DCA 22.86%, #002848 73.33%);
  `}

  ${({ isHot }) => isHot && css`
    background: linear-gradient(174.9deg, #D844E1 31.5%, #DF2C27 61.26%, #F5803A 97.55%);
  `}

  border-radius: ${dailyBorderRadiusDesktop};
  padding: 0px;
  align-items: center;
`;

const DailyJackpotMobile = styled(DailyAndHourlyJackpot)`
  padding: ${marginTopDailyJackpotBorderMobile}px 0px 0px 0px;
  align-items: center;
`;

const HourlyJackpotMobile = styled(DailyAndHourlyJackpot)`
  padding: ${marginTopDailyJackpotBorderMobile}px 0px 0px 0px;
  align-items: center;
`;

export default React.memo(TickerComponent);
