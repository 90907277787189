import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authActions } from '../store/actions';
import { logger } from '../services';

export default () => {
  const rsiApiAvailable = rsiApi.isLoaded();

  const dispatch = useDispatch();
  const loadAuth = useCallback((retry = 0) => {
    logger.log('Checking playerStatus, retry:', retry);
    try {
      const isLoggedIn = rsiApi.isLoggedIn();

      dispatch(authActions.setIsLoggedIn(isLoggedIn));
      dispatch(authActions.setIsInternal(isLoggedIn && rsiApi.getPlayer().IS_INTERNAL));

      rsiApi.on(rsiApi.getEvent('STATUS_CHANGE'), () => {
        dispatch(authActions.setIsLoggedIn(rsiApi.isLoggedIn()));
        dispatch(authActions.setIsInternal(rsiApi.isLoggedIn() && rsiApi.getPlayer().IS_INTERNAL));
      });
    } catch (error) {
      if (retry < 10) {
        setTimeout(() => {
          loadAuth(retry + 1);
        }, 500);
      } else {
        logger.error('Failed to init auth ');
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (rsiApiAvailable) {
      loadAuth();
    }
  }, [loadAuth, rsiApiAvailable]);
};
