import React from 'react';
import styled from 'styled-components';

function BubbleComponent({ className }: {className?: string}) {
  return <div className={className} />;
}

BubbleComponent.defaultProps = {
  className: undefined,
};

const BubbleDesktop7 = styled(BubbleComponent)`
  position: absolute;
  width: 30px;
  height: 30px;
  background: radial-gradient(50% 50% at 50% 50%, rgb(255, 140, 57) 0%, rgba(255, 140, 57, 0.58) 38%, rgba(255, 140, 57, 0.27) 68%, rgba(255, 140, 57, 0.07) 89%, rgba(255, 140, 57, 0) 100%);
  background-blend-mode: screen;
  mix-blend-mode: screen;
  opacity: 0.5;
  border-radius: 14px;
  transform: matrix(-0.93, -0.37, -0.37, 0.93, 0, 0);
  top: 110px;
  left: 145px;
`;

export default BubbleDesktop7;
