import React from 'react';
import styled, { css } from 'styled-components';
import useCarousel from '../../../../hooks/useCarousel';
import { GameImage } from '../../../../interfaces';
import CarouselImageDesktop from './CarouselImageDesktop';
import ScrollTrackerDesktop from './ScrollTrackerDesktop';

interface ImageCarouselDesktopProperties {
  className?: string;
  code: string;
  images: GameImage[];
}

// eslint-disable-next-line max-len
function ImageCarouselDesktopComponent({
  className,
  images,
  code,
}: ImageCarouselDesktopProperties) {
  const {
    containerRef,
    setActiveImageIndex,
    scrollNext,
    scrollPrev,
    activeImageIndex,
  } = useCarousel(images.length);

  const hasMultipleImages = images.length > 1;

  return (
    <div className={`${className} ${code}`}>
      <ScrollWrapper>
        <ImagesContainer ref={containerRef} hasMultiple={hasMultipleImages}>
          {images.map((image, i) => (
            <CarouselImageDesktop
              active={i === activeImageIndex}
              image={image}
              key={image.gameCode}
            />
          ))}
        </ImagesContainer>
      </ScrollWrapper>
      {hasMultipleImages && (
        <ScrollTrackerDesktop
          imageCodes={images.map((image) => image.gameCode)}
          activeImageIndex={activeImageIndex}
          setActiveImage={setActiveImageIndex}
          scrollNext={scrollNext}
          scrollPrev={scrollPrev}
        />
      )}
    </div>
  );
}

ImageCarouselDesktopComponent.defaultProps = {
  className: undefined,
};

const ImageCarouselDesktop = styled(ImageCarouselDesktopComponent)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ScrollWrapper = styled.div`
  overflow: auto;
  -ms-overflow-style: none;
  width: 100%;
  scrollbar-width: none;
  scroll-behavior: smooth;
  text-align: center;

  ::-webkit-scrollbar {
    display: none;
  }
`;

interface ImageContainerProperties {
  hasMultiple: boolean;
}

const ImagesContainer = styled.div <ImageContainerProperties>`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  width: max-content;
  max-width: 100%;
  height: 205px;

  ${(props) => props.hasMultiple && css`
    margin: 0 35px;
  `};
`;

export default ImageCarouselDesktop;
