import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

interface GridItemComponentProps {
  column: string;
  row: string;
  children: ReactElement;
  width?: number;
  marginLeft?: number;
}

function GridItem({
  column,
  row,
  width,
  marginLeft,
  children,
}: GridItemComponentProps) {
  return (
    <Wrapper column={column} row={row} width={width} marginLeft={marginLeft}>
      {children}
    </Wrapper>
  );
}

GridItem.defaultProps = {
  width: undefined,
  marginLeft: undefined,
};

const Wrapper = styled.div.attrs((
  props: {
    width: number,
    column: string,
    row: string,
    marginLeft: number;
  },
) => props)`
  ${(props) => props && css`
    grid-column: ${props.column};
    grid-row: ${props.row};
    width: ${props.width}px;
    margin-left: ${props.marginLeft}px;
  `};
`;

export default GridItem;
