import { useDispatch } from 'react-redux';
import { useRef } from 'react';
import useConfigs from './useConfigs';
import { lobbyActions } from '../store/actions';

export default () => {
  const dispatch = useDispatch();
  const confSet = useRef<boolean>(false);
  const { customGameImages } = useConfigs(['customGameImages']);

  if (customGameImages?.images && !confSet.current) {
    dispatch(lobbyActions.setCustomGameImages(customGameImages.images));
    confSet.current = true;
  }
};
