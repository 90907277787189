import React, {
  forwardRef, useCallback, useEffect, useState,
} from 'react';
import styled, { css } from 'styled-components';
import {
  GameCategoryItem, MultiRowTemplate,
} from '../../interfaces';
import { paddings } from '../../constants/style-variables';
import MultiRowGridItem, { GridComponent } from './MultiRowGridItem/MultiRowGridItem';
import MultiRowGridMoreGamesTile from './MultiRowGridItem/MultiRowGridMoreGamesTile';
import useMultiRowTemplate from '../../hooks/useMultiRowTemplate';
import {
  PADDING_Y_GAME_CATEGORY_WITHOUT_JACKPOT,
} from '../../constants/category-variables';
import doesIncludeRecentOrFavoriteSource from '../../utils/doesIncludeRecentOrFavoriteSource';

interface GameCategoryProperties {
  totalGames: number;
  pageSize: number;
  games: GameCategoryItem[];
  className?: string;
  onClick: any;
  isRecentOrFavoritesCategory: boolean;
  withJackpot?: boolean;
  isDesktop: boolean;
  categoryCode: string;
  onClickMoreGames: VoidFunction;
  smallestWidth?: number;
  smallestSize?: Partial<GridComponent>;
  gap?: number;
  template?: MultiRowTemplate;
  onCategoryReady?: (isReady: boolean) => void;
}

/**
 * Game category list is displayed based on CSS Grid Layout,
 * see: https://developer.mozilla.org/es/docs/Web/CSS/CSS_Grid_Layout
 *
 * Although the grid is capable to find the best height for rows by itself, its logic doesn't fit
 * pretty well with our custom way to configure grids for our categories.
 *
 * This percentage allows us to have control over the height of the rows,
 * making our grid look nicer.
 *
 * The percentage must be based on the shape of the image of the games.
 */
const GRID_ROWS_HEIGHT = 0.635;

/**
 * Displays up to pageSize game tiles in a scrollable row.
 * Features:
 * - One row grid layout. In css can define how many game tiles will be visible (even 2.5 is OK)
 * and what is the gap size between tiles.
 * - In mobile the row has to go over the "container" and next to screen edge when scrolled. Just
 * using margins/paddings around or in the row container doesn't work because of grid calculations.
 * Cannot use empty tiles either because the edge padding may be smaller than defined gap between
 * tiles. Therefore, only left (edge) padding is added to the row and the last tile must define
 * right side (edge) margin + padding.
 * - Added "More games" tile that shows the number of available games that aren't shown in a game
 * category line. Click on it shows all games in the category. The tile is situated on the last one
 * in the view line that user sees in game category line
 */
const GameCategoryComponent = forwardRef<
  HTMLDivElement, GameCategoryProperties
>(({
  games,
  className,
  onClick,
  withJackpot,
  isRecentOrFavoritesCategory,
  isDesktop,
  totalGames,
  pageSize,
  categoryCode,
  onClickMoreGames,
  smallestWidth,
  smallestSize,
  gap,
  template,
  onCategoryReady,
}, ref) => {
  const [slicedGames, setSlicedGames] = useState<GameCategoryItem[]>([]);
  const [usedComponents, setUsedComponents] = useState<GridComponent[]>([]);
  const [totalRemainingGames, setTotalRemainingGames] = useState<number>(0);
  const [rowsHeight, setRowsHeight] = useState<number>();
  const { getNeededGridCopies } = useMultiRowTemplate();

  const findMaxGridHorizontalIndex = (components: GridComponent[]) => {
    if (components.length > 0) {
      return (Math.max(
        ...components.map((component) => (component.x + component.width)),
      ));
    }

    return 0;
  };

  const getGridComponents = useCallback((
    componentsTemplateCopies: Array<GridComponent[]>,
  ) => {
    const components: GridComponent[] = [];

    componentsTemplateCopies.forEach(((templateComponents: GridComponent[]) => {
      const maxGridHorizontalIndex = findMaxGridHorizontalIndex(components);
      templateComponents.forEach((component: GridComponent) => {
        components.push({
          ...component,
          x: component.x + maxGridHorizontalIndex,
        });
      });
    }));

    return components;
  }, []);

  const configureGrid = useCallback(() => {
    if (!template) {
      return;
    }

    const totalCopies = getNeededGridCopies(totalGames, pageSize, template);
    const componentsTemplateCopies = Array(totalCopies).fill(template.components);

    const fixedComponents: GridComponent[] = getGridComponents(componentsTemplateCopies);
    const slice = games.slice(0, fixedComponents.length);
    setSlicedGames(slice);

    const used = fixedComponents.slice(0, slice.length);
    setUsedComponents(used);

    const remaining = totalGames - slice.length;
    setTotalRemainingGames(remaining);
  }, [games, getGridComponents, getNeededGridCopies, template, totalGames, pageSize]);

  const categoryIsReady = useCallback(() => {
    if (!onCategoryReady) {
      return;
    }

    onCategoryReady(true);
  }, [onCategoryReady]);

  const configureHeight = useCallback(() => {
    if (!smallestWidth) {
      return;
    }

    const height = smallestWidth * GRID_ROWS_HEIGHT;

    if (doesIncludeRecentOrFavoriteSource(categoryCode)) {
      // Grid category items height + pixels used by the game info button
      const heightWithGameInfo = height + 30.5;
      setRowsHeight(heightWithGameInfo);
      return;
    }

    setRowsHeight(height);
  }, [categoryCode, smallestWidth]);

  useEffect(() => {
    configureHeight();
    configureGrid();
  }, [configureGrid, configureHeight]);

  return (
    <div className={className} ref={ref}>
      <MultiRowGrid
        isDesktop={isDesktop}
        gap={gap}
        rowsHeight={rowsHeight}
        data-testid="multirow-grid"
        className="multirow-grid"
      >
        {usedComponents.map((gridComponent, index) => {
          /**
           * We can consider the grid to be fully rendered once the last grid item is rendered
           */
          if (index === usedComponents.length - 1) {
            categoryIsReady();
          }

          return (
            <MultiRowGridItem
              key={slicedGames[index]?.code}
              game={slicedGames[index] ?? null}
              gridComponent={gridComponent}
              onClick={onClick}
              dataTestId={`game-item-${index}`}
              isRecentOrFavoritesCategory={isRecentOrFavoritesCategory}
              withJackpot={withJackpot}
              smallestWidth={smallestWidth}
            />
          );
        })}

        {totalRemainingGames > 0 && (
          <MultiRowGridMoreGamesTile
            totalRemainingGames={totalRemainingGames}
            onClickMoreGames={onClickMoreGames}
            categoryCode={categoryCode}
            usedComponents={usedComponents}
            smallestWidth={smallestWidth}
            smallestSize={smallestSize}
            withJackpot={withJackpot}
          />
        )}
      </MultiRowGrid>
    </div>
  );
});

GameCategoryComponent.defaultProps = {
  className: undefined,
  smallestWidth: undefined,
  smallestSize: undefined,
  gap: undefined,
  onCategoryReady: undefined,
  template: undefined,
  withJackpot: false,
};

const GameCategory = styled(GameCategoryComponent)`
  ${(props) => props.isDesktop && css`
    margin: 0 ${paddings.pagePadding}px;
  `};

  display: flex;
  padding: ${PADDING_Y_GAME_CATEGORY_WITHOUT_JACKPOT}px 0;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const MultiRowGrid = styled.div.attrs((
  props: {
    isDesktop: boolean,
    gap: number,
    rowsHeight: number,
  },
) => props)`
  display: grid;
  ${({ isDesktop }) => !isDesktop && css`
    margin: 0 ${paddings.pagePadding}px;
  `};
  ${(props) => props && css`
    gap: ${props.gap}px;
    grid-auto-rows: ${props.rowsHeight}px;
    grid-auto-flow: column;
  `};
`;

export default GameCategory;
