import React from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { GameDetails } from '../../../../interfaces';
import { colors } from '../../../../constants/style-variables';
import { Translations } from '../../../../constants/translations';

interface LargestWinSectionProperties {
  className?: string;
  detailedGameInfo: GameDetails | null;
}

function LargestWinSectionComponent({ className, detailedGameInfo }: LargestWinSectionProperties) {
  const intl = useIntl();
  const anonymous = intl.formatMessage({ id: 'ANONYMOUS' });

  if (!detailedGameInfo || !detailedGameInfo.largestWinMultiplier) {
    return null;
  }

  let formattedDate: string;
  if (typeof RSIDateTime === 'undefined') {
    formattedDate = new Date(detailedGameInfo.winDate).toLocaleDateString();
  } else {
    formattedDate = new RSIDateTime(detailedGameInfo.winDate).formatDateTime({
      format: RSIDateTime.formats.largestWinDateTime,
    });
  }

  return (
    <div className={className}>
      <Divider />
      <LargestWinSectionBlock>
        <WonWrapper>
          <WonTop>
            <WonRectangle angle={180} />
            <WonText>
              <FormattedMessage id={Translations.DETAILS_WON} />
            </WonText>
            <WonRectangle />
          </WonTop>
          <WonAmount>
            {`${detailedGameInfo.largestWinMultiplier}X BET`}
          </WonAmount>
        </WonWrapper>
        <LargestWinText>
          <FormattedMessage
            id={Translations.GAME_DETAILS_LARGEST_WIN_INFO}
            values={{
              largestWinMultiplier: detailedGameInfo.largestWinMultiplier,
              winnerName: detailedGameInfo.winnerName || anonymous,
              br: <br />,
              formattedDate,
            }}
          />
        </LargestWinText>
      </LargestWinSectionBlock>
    </div>
  );
}

LargestWinSectionComponent.defaultProps = {
  className: undefined,
};

const LargestWinSectionBlock = styled.div`
  display: flex;
`;

const Divider = styled.div`
  height: 1px;
  margin-bottom: 11px;
  background: linear-gradient(90deg, ${colors.blue} 0%, ${colors.blueDark} 100%);
`;

const LargestWinSection = styled(LargestWinSectionComponent)`
  display: flex;
  flex-direction: column;
`;

const WonWrapper = styled.div`
  margin-right: 24px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-evenly;
  text-align: center;
  background: radial-gradient(50% 50% at 50% 50%, rgba(49, 96, 148, 0.5) 0%, rgba(49, 96, 148, 0) 100%);
`;

const WonTop = styled.div`
  display: inline-flex;
  align-items: baseline;
  justify-content: space-evenly;
  width: 100%;
`;

const WonAmount = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;
  color: ${colors.white};
`;

const WonText = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: ${colors.yellow};
`;

interface WonRectangleProps {
  angle?: number;
}

const WonRectangle = styled.div<WonRectangleProps>`
  height: 2px;
  width: 20%;
  background: linear-gradient(90deg, ${colors.blue} 0%, rgba(49, 96, 148, 0) 100%);
  align-self: flex-start;
  margin-top: 6px;
  ${(props) => props.angle && css`
    transform: rotate(${props.angle}deg);
  `}
`;

const LargestWinText = styled.span`
  font-family: 'Montserrat';
  font-size: 12px;
  line-height: 20px;
  color: ${colors.white};
`;

export default LargestWinSection;
