import React, {
  useCallback, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import MoreGamesTile from '../MoreGamesTile/MoreGamesTile';
import GridItem from './GridItem/GridItem';
import { paddings } from '../../../constants/style-variables';
import { GridComponent } from '../../../interfaces';

interface MultiRowGridMoreGamesTileProps {
  totalRemainingGames: number;
  onClickMoreGames: VoidFunction;
  categoryCode: string;
  usedComponents: GridComponent[];
  smallestWidth?: number;
  smallestSize?: Partial<GridComponent>;
  withJackpot?: boolean;
}

function MultiRowGridMoreGamesTile({
  totalRemainingGames,
  onClickMoreGames,
  categoryCode,
  usedComponents,
  smallestWidth,
  smallestSize,
  withJackpot,
}: MultiRowGridMoreGamesTileProps) {
  const [column, setColumn] = useState('');
  const [row, setRow] = useState('');
  const [itemWidth, setItemWidth] = useState<number>();

  const findLastComponentBy = useCallback((
    coordinate: string,
    spatialProperty: string,
    components: GridComponent[],
  ) => (
    components.reduce(
      (previous, current) => {
        // @ts-ignore
        const previousSpace = previous[coordinate] + previous[spatialProperty];
        // @ts-ignore
        const currentSpace = current[coordinate] + current[spatialProperty];

        return previousSpace > currentSpace ? previous : current;
      },
    )
  ), []);

  const buildGrid = useCallback((gridWidth: number, gridHeight: number) => {
    const grid = Array(gridWidth).fill(false).map(() => Array(gridHeight).fill(false));

    usedComponents.forEach((component) => {
      for (let i = component.x; i < component.x + component.width; i += 1) {
        for (let j = component.y; j < component.y + component.height; j += 1) {
          grid[i][j] = true;
        }
      }
    });

    return grid;
  }, [usedComponents]);

  const calculateGridBoundaries = useCallback(() => {
    let maxWidth = 0;
    let maxHeight = 0;

    usedComponents.forEach((component) => {
      maxWidth = Math.max(maxWidth, component.x + component.width);
      maxHeight = Math.max(maxHeight, component.y + component.height);
    });

    return [maxWidth, maxHeight];
  }, [usedComponents]);

  const calculateTilePosition = useCallback(() => {
    let baseColumn = -1;
    let baseRow = -1;
    const [gridWidth, gridHeight] = calculateGridBoundaries();

    const grid = buildGrid(gridWidth, gridHeight);
    /**
     * Binary search through columns and keep the topmost, leftmost free position found.
     * Finding an open position means we move our search window left.
     * Not finding an open position means we move our search window right.
     * This works because the grid is filled in order: from top to bottom, from left to right
     */
    let left = 0;
    let right = grid.length - 1;
    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      let found = false;
      for (let i = 0; i < grid[mid].length && !found; i += 1) {
        if (!grid[mid][i]) {
          found = true;
          baseColumn = mid;
          baseRow = i;
        }
      }
      if (found) {
        right = mid - 1;
      } else {
        left = mid + 1;
      }
    }

    return baseColumn === -1 ? [gridWidth + 1, 1] : [baseColumn + 1, baseRow + 1];
  }, [buildGrid, calculateGridBoundaries]);

  const getExpandedComponentGapsCount = useCallback((baseColumn: number) => {
    const components = usedComponents.filter(
      (component) => (
        component.width > 1
        && (component.x + component.width) >= baseColumn
      ),
    );

    return components.map((component) => component.width - 1)
      .reduce((partial, current) => partial + current, 0);
  }, [usedComponents]);

  const getTileWidth = useCallback((
    missingWidth: number,
    missingHeight: number,
    lastColumnComponent: GridComponent,
    baseColumn: number,
  ) => {
    const expandedComponentGapsCount = getExpandedComponentGapsCount(baseColumn);
    const isMissingColumns = (missingHeight > 1 && missingWidth !== 1);
    if (smallestWidth && isMissingColumns) {
      /**
       * Gaps are calculated based on the width and height that the tile would use
       * adding the gaps that other components from the same column would use, we also need
       * to reduce the gaps by 1 since this one is already used by the tile.
       */
      const gaps = paddings.multiRowFixedGap * (
        ((missingHeight - 1) + ((missingWidth - 1) + expandedComponentGapsCount - 1))
      );

      const largestMissingSpace = missingHeight > missingWidth ? missingHeight : missingWidth;
      return smallestWidth * largestMissingSpace + gaps;
    }

    if (smallestWidth && missingWidth > 1) {
      const missingGaps = (lastColumnComponent.x + lastColumnComponent.width) - baseColumn;
      /**
       * Here gaps are calculated based on the empty grid components, adding the gaps of
       * components from the same column and beyond
       */
      const gaps = (missingGaps + expandedComponentGapsCount) * paddings.multiRowFixedGap;

      return smallestWidth * missingWidth + gaps;
    }

    /**
     * In this case the gaps are calculated based only on the gaps of other components from the
     * same column
     */
    const gaps = expandedComponentGapsCount * paddings.multiRowFixedGap;

    return smallestWidth ? smallestWidth + gaps : smallestWidth;
  }, [getExpandedComponentGapsCount, smallestWidth]);

  const setCoordinates = useCallback(() => {
    if (usedComponents.length === 0) {
      return;
    }

    const lastColumnComponent = findLastComponentBy(
      'x',
      'width',
      usedComponents,
    );

    /**
     * Calculate the base position of the tile based on the total games to be rendered in the
     * calculated grid.
     */
    const [baseColumn, baseRow] = calculateTilePosition();

    /**
     * We must now calculate the tile column, row and width based on the number of components that
     * are empty on the grid
     */
    const missingWidth = smallestSize?.width ?? 1;
    const missingHeight = smallestSize?.height ?? 1;

    const tileWidth = getTileWidth(missingWidth, missingHeight, lastColumnComponent, baseColumn);
    const tileColumn = `${baseColumn} / ${baseColumn + missingWidth}`;
    const tileRow = `${baseRow} / ${baseRow + missingHeight}`;

    setItemWidth(tileWidth);
    setColumn(tileColumn);
    setRow(tileRow);
  }, [
    usedComponents,
    findLastComponentBy,
    smallestSize?.width,
    smallestSize?.height,
    getTileWidth,
    calculateTilePosition,
  ]);

  useEffect(() => {
    setCoordinates();
  }, [setCoordinates]);

  return (
    <GridItem
      column={column}
      row={row}
      width={itemWidth}
    >
      <MoreGamesWrapper>
        <MoreGamesTile
          gamesNumber={totalRemainingGames}
          onClick={onClickMoreGames}
          dataTestId={`show-more-${categoryCode}`}
          withJackpot={withJackpot}
        />
      </MoreGamesWrapper>
    </GridItem>
  );
}

MultiRowGridMoreGamesTile.defaultProps = {
  smallestWidth: undefined,
  smallestSize: undefined,
  withJackpot: false,
};

const MoreGamesWrapper = styled.div`
  height: 100%;
`;

export default MultiRowGridMoreGamesTile;
