import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../constants/style-variables';

interface ArrowLeftButtonProperties {
  className?: string;
  onClick?: any;
  name?: string;
}

const { Icon } = UiComponentLibrary;

function ArrowLeftButtonComponent({ onClick, className, name }: ArrowLeftButtonProperties) {
  return (
    <button type="button" className={className} onClick={onClick} name={name}>
      <Icon name="caret-left" />
    </button>
  );
}

ArrowLeftButtonComponent.defaultProps = {
  className: undefined,
  onClick: undefined,
  name: 'button-back',
};

const ArrowLeftButton = styled(ArrowLeftButtonComponent)`
  border: 0;
  padding: 0;
  background-color: transparent;
  font-size: 22px;
  line-height: 22px;
  height: 22px;
  color: ${colors.blue};
  stroke: ${colors.blue};

  @media (hover: hover) {

    :hover {
      color: ${colors.blueDarker};
    }
  }
`;

export default ArrowLeftButton;
