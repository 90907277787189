import { AnyAction } from 'redux';
import { LayoutActionTypes } from '../actionTypes';
import { TileSize } from '../../interfaces/TileSize';

export const setDesktopMode = (payload: boolean): AnyAction => ({
  type: LayoutActionTypes.SET_DESKTOP_MODE,
  payload,
});

export const setUseFullscreenDetails = (payload: boolean): AnyAction => ({
  type: LayoutActionTypes.SET_USE_FULLSCREEN_DETAILS,
  payload,
});

export const setMainTileWidth = (payload: Map<TileSize, number>): AnyAction => ({
  type: LayoutActionTypes.SET_MAIN_TILE_SIZE_MAP,
  payload,
});

export const setHasNativeTopBar = (payload: boolean): AnyAction => ({
  type: LayoutActionTypes.SET_NATIVE_TOP_BAR,
  payload,
});

export const setDesktopReadyForInitialization = (payload: boolean): AnyAction => ({
  type: LayoutActionTypes.SET_DESKTOP_READY_FOR_INIT,
  payload,
});
