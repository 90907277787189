import { useSelector } from 'react-redux';
import React from 'react';
import { GameCategoryItem, RootState } from '../../../interfaces';
import { getDoesRenderRibbons, getHasJackpot } from '../../../utils/ribbonsHelper';
import { DetailsRibbons } from '../GameItem/Ribbons';
import { DetailsJackpotTicker } from '../GameItem/JackpotTicker';
import { DetailsHotPayout } from '../GameItem/HotPayout';

interface RibbonsProperties {
  item: GameCategoryItem;
}

/**
 * Component returns array of Ribbons
 * - it returns:
 * 1) DetailsRibbon like PROGRESSIVE, EXCLUSIVE, NEW
 * 2) DetailsJackpotTicker
 * 3) DetailsHotPayout
 * - it uses of both platforms: mobile and desktop
 */
function RibbonsComponent({ item }: RibbonsProperties) {
  const clientType = useSelector((state: RootState) => state.application.clientType);
  const hasRibbons = getDoesRenderRibbons(item, clientType);

  if (!hasRibbons) {
    return null;
  }

  const hasJP = getHasJackpot(item);

  return (
    <>
      <DetailsRibbons flags={item.flags} />
      {
        hasJP && <DetailsJackpotTicker gameCode={item.code} />
      }
      {item.payoutPercentage && <DetailsHotPayout payout={item.payoutPercentage} />}
    </>
  );
}

export default RibbonsComponent;
