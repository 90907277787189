import React from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../interfaces';
import { DeviceType } from '../../../../interfaces/DeviceType';
import BubbleMobile1 from './Bubbles/Mobile/BubbleMobile1';
import BubbleMobile2 from './Bubbles/Mobile/BubbleMobile2';
import BubbleMobile3 from './Bubbles/Mobile/BubbleMobile3';
import BubbleMobile4 from './Bubbles/Mobile/BubbleMobile4';
import BubbleDesktop1 from './Bubbles/Desktop/BubbleDesktop1';
import BubbleDesktop2 from './Bubbles/Desktop/BubbleDesktop2';
import BubbleDesktop3 from './Bubbles/Desktop/BubbleDesktop3';
import BubbleDesktop4 from './Bubbles/Desktop/BubbleDesktop4';
import BubbleDesktop5 from './Bubbles/Desktop/BubbleDesktop5';
import BubbleDesktop6 from './Bubbles/Desktop/BubbleDesktop6';
import BubbleDesktop7 from './Bubbles/Desktop/BubbleDesktop7';
import BubbleDesktop8 from './Bubbles/Desktop/BubbleDesktop8';
import BubbleDesktop9 from './Bubbles/Desktop/BubbleDesktop9';
import BubbleDesktop10 from './Bubbles/Desktop/BubbleDesktop10';
import BubbleDesktop11 from './Bubbles/Desktop/BubbleDesktop11';
import BubbleDesktop12 from './Bubbles/Desktop/BubbleDesktop12';

function BackgroundJackpotCategory() {
  const isMobile = useSelector((state: RootState) => state.application.deviceType)
    === DeviceType.Mobile;
  return (
    <BlurPlaceholder>
      {isMobile && (
        <>
          <BubbleMobile1 />
          <BubbleMobile2 />
          <BubbleMobile3 />
          <BubbleMobile4 />
        </>
      )}
      {!isMobile && (
        <>
          <BubbleDesktop1 />
          <BubbleDesktop2 />
          <BubbleDesktop3 />
          <BubbleDesktop4 />
          <BubbleDesktop5 />
          <BubbleDesktop6 />
          <BubbleDesktop7 />
          <BubbleDesktop8 />
          <BubbleDesktop9 />
          <BubbleDesktop10 />
          <BubbleDesktop11 />
          <BubbleDesktop12 />
        </>
      )}
      <RoundTopBlur />
      <RoundBottomBlur isMobile={isMobile} />
    </BlurPlaceholder>
  );
}

const RoundTopBlur = styled.div`
  position: absolute;
  height: 300px;
  top: -150px;
  top: 40px;
  background: radial-gradient(49.94% 50.16% at 49.14% 53.82%, #FFFFFF 0%, rgba(255, 255, 255, 0.58) 38%, rgba(255, 255, 255, 0.27) 68%, rgba(255, 255, 255, 0.07) 89%, rgba(255, 255, 255, 0) 100%);
  background-blend-mode: overlay;
  mix-blend-mode: overlay;
  opacity: 0.8;
  border-radius: 14px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  pointer-events: none;
`;

const RoundBottomBlur = styled.div.attrs((props: { isMobile: boolean }) => props)`

  ${({ isMobile }) => !isMobile && css`
    position: absolute;
    width: 684px;
    height: 335px;
    background: radial-gradient(50.01% 101.09% at 50% 101.08%, #0000FF 0%, #0012FF 2%, #002FFF 4%, #0026D3 11%, #001DA2 20%, #001576 29%, #000F52 39%, #000934 49%, #00051D 60%, #00020C 71%, #000003 84%, #000000 100%);
    background-blend-mode: screen;
    mix-blend-mode: screen;
    opacity: 0.8;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
  `};

  ${({ isMobile }) => isMobile && css`
    position: absolute;
    width: 420px;
    height: 240px;
    background: radial-gradient(50% 50.4% at 50% 60.07%, rgb(255, 140, 57) 0%, rgb(255, 144, 75) 24%, rgb(255, 145, 77) 25%, rgb(204, 116, 61) 33%, rgb(151, 85, 45) 43%, rgb(104, 59, 31) 53%, rgb(67, 38, 20) 63%, rgb(37, 21, 11) 72%, rgb(16, 9, 5) 82%, rgb(4, 2, 1) 91%, rgb(0, 0, 0) 100%);
    background-blend-mode: screen;
    mix-blend-mode: screen;
    opacity: 0.5;
    border-radius: 14px;
    pointer-events: none;
    right: 50%;
    transform: translateX(50%);
    top: 100px;
  `};
`;

const BlurPlaceholder = styled.div`
  height: 100%;
  width: 100%;
  pointer-events: none;
`;

export default BackgroundJackpotCategory;
