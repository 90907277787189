import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { GameDetails } from '../../../../interfaces';
import { colors } from '../../../../constants/style-variables';
import { Translations } from '../../../../constants/translations';

interface LargestWinSectionProperties {
  className?: string;
  detailedGameInfo: GameDetails | null;
}

function LargestWinSectionComponent({ className, detailedGameInfo }: LargestWinSectionProperties) {
  const intl = useIntl();
  const anonymous = intl.formatMessage({ id: 'ANONYMOUS' });

  if (!detailedGameInfo || !detailedGameInfo.largestWinMultiplier) {
    return null;
  }

  let formattedDate: string;
  if (typeof RSIDateTime === 'undefined') {
    formattedDate = new Date(detailedGameInfo.winDate).toLocaleDateString();
  } else {
    formattedDate = new RSIDateTime(detailedGameInfo.winDate).formatDateTime({
      format: RSIDateTime.formats.largestWinDateTime,
    });
  }

  return (
    <div className={className}>
      <hr />
      <LargestWinSectionBlock>
        <LargestWinText>
          <FormattedMessage
            id={Translations.GAME_DETAILS_LARGEST_WIN_INFO}
            values={{
              largestWinMultiplier: detailedGameInfo.largestWinMultiplier,
              winnerName: detailedGameInfo.winnerName || anonymous,
              br: <br />,
              formattedDate,
            }}
          />
        </LargestWinText>
        <WonWrapper className="won-wrapper">
          <WonTop>
            <WonRectangle />
            <WonText>
              <FormattedMessage id={Translations.DETAILS_WON} />
            </WonText>
            <WonRectangle />
          </WonTop>
          <WonAmount>
            {`${detailedGameInfo.largestWinMultiplier}X BET`}
          </WonAmount>
        </WonWrapper>
      </LargestWinSectionBlock>
    </div>
  );
}

LargestWinSectionComponent.defaultProps = {
  className: undefined,
};

const LargestWinSectionBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const LargestWinSection = styled(LargestWinSectionComponent)`
  display: flex;
  flex-direction: column;

  hr {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 8px;
  }
`;

const WonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  align-items: baseline;
  justify-content: space-evenly;
  text-align: center;
`;

const WonTop = styled.div`
  display: inline-flex;
  align-items: baseline;
  justify-content: space-evenly;
  width: 100%;
`;

const WonAmount = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: space-evenly;
  font-size: 16px;
  font-weight: 500;
  color: ${colors.black};
`;

const WonText = styled.span`
  color: #316094;
  font-size: 11px;
  font-weight: 700;
  text-align: center;
`;

const WonRectangle = styled.div`
  height: 2px;
  width: 20%;
  background-color: #316094;
  align-self: flex-start;
  margin-top: 6px;
`;

const LargestWinText = styled.span`
  line-height: 1.67;
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  white-space: pre-line;
  width: 70%;
`;

export default LargestWinSection;
