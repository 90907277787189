import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../constants/style-variables';

interface FavoriteStarProperties {
  className?: string;
}

const { Icon } = UiComponentLibrary;

function FavoriteStarComponent({ className }: FavoriteStarProperties) {
  return (
    <div className={className}>
      <Icon
        name="star-fill"
        height={15}
        width={15}
      />
      <Background />
    </div>
  );
}

FavoriteStarComponent.defaultProps = {
  className: undefined,
};

const FavoriteStar = styled(FavoriteStarComponent)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
  color: ${colors.gold};
  position: relative;
`;

const Background = styled.div`
  position: absolute;
  background: ${colors.blueDarker};
  box-shadow: 0px 1px 2px ${colors.blueDarker}, 0px 0px 2px rgb(0 0 0 / 10%);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  top: 0px;
  z-index: -1;
`;

export default FavoriteStar;
