import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  GameCategoryUpdateType,
  LobbyGameCategory,
  RootState,
} from '../../interfaces';
import { lobbyActions } from '../../store/actions';
import InfiniteScrollGames from './InfiniteScrollGames';
import { useTypedDispatch } from '../../store';
import CategoryDescription from './CategoryDescription/CategoryDescription';
import CategoryHeader from './CategoryHeader';

interface CategoryGamesProperties {
  category: LobbyGameCategory,
}

function LobbyLastCategoryComponent({ category }: CategoryGamesProperties) {
  const dispatch = useTypedDispatch();
  const clientType = useSelector((state: RootState) => state.application.clientType);

  const categorySelectorHandler = useCallback(
    () => category.data.games,
    [category.data.games],
  );

  const fetchHandler = useCallback((page: number) => {
    if (clientType && category?.code) {
      dispatch(lobbyActions.getExpandedCategoryGames(
        category?.code,
        clientType,
        GameCategoryUpdateType.SingleCategoryInLobby,
        page,
      ));
    }
  }, [dispatch, clientType, category?.code]);

  if (!category.data.games.items.length) {
    return null;
  }

  return (
    <>
      <CategoryHeader
        headerKey={category.code}
        total={category.data.games.paging.totalElements}
        pageSize={category.data.games.paging.pageSize}
        isRecentOrFavoritesCategory={false}
      />
      <CategoryDescription translationCode={category.code} />
      <InfiniteScrollGames
        fetchHandler={fetchHandler}
        selectorHandler={categorySelectorHandler}
        sourceType={category?.code}
        dataTestId={`category-${category.code}`}
        scrollToTop={false}
      />
    </>
  );
}

const LobbyLastCategory = styled(LobbyLastCategoryComponent)`
  padding: 0px;
`;

export default LobbyLastCategory;
