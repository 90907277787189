import { Paging } from './Paging';
import { KeyValuePair } from './KeyValuePair';
import { StyleTypeJackpotCategory } from '../components/JackpotCategory/JackpotTickerType';

export type Style = {
  template: MultiRowTemplate,
  numberOfGames?: number,
}

export type MultiRowTemplate = {
  dimensions: GridDimensions,
  components: GridComponent[],
}

export type GridDimensions = {
  width: number,
  height: number,
}

export type GridComponent = {
  type: string,
  width: number,
  height: number,
  x: number,
  y: number,
}

export type GameCategoryItem = {
  code: string,
  name: string,
  gameProvider: string,
  rgsProvider: string,
  integrationProvider:string,
  flags: [string],
  portrait: boolean,
  landscape: boolean,
  desktopRatio?: string,
  mobileRatio?: string,
  payoutPercentage?: number,
  iosExternal?: boolean,
  androidExternal?: boolean,
}

export type GameItemsList = {
  paging: Paging,
  items: GameCategoryItem[],
};

export type GameCategoryData = {
  code: string,
  games: GameItemsList,
  sourceType: string,
  styleType?: StyleTypeJackpotCategory,
}

export type DetailedGame = {
  categoryId: string,
  item: GameCategoryItem,
  logData: KeyValuePair[],
}

export type ExternalLobbyComponent = {
  type: LobbyComponentType,
  code: string,
  data: any,
}

export type LobbyGameCategory = {
  type: LobbyComponentType,
  code: string,
  style: Style,
  data: GameCategoryData,
}

export type LobbyComponent = LobbyGameCategory | ExternalLobbyComponent;

export enum GameCategoryUpdateType {
  OnlyLobby,
  OnlyExpCategory,
  LobbyAndExpandedCategory,
  SingleCategoryInLobby,
}

export enum LobbyComponentType {
  General = 'GENERAL',
  Banner = 'BANNER',
  GameCategory = 'GAME_CATEGORY',
  LiveCategory = 'LIVE_CATEGORY',
}

export type GameCategoryChangedMessage = {
  code: string;
}

export type BannerConfig = {
  id: number;
};

export type RSIBannerConfig = {
  props: BannerInitialStateData
}

export type BannerInitialStateData = {
  initialState: BannerConf
}
export type BannerConf = {
  bannerConf: BannerConfig
}
