// @ts-ignore
import { BehaviorSubject, Observable } from 'rxjs';

export type JackpotValue = {
  amount: number,
  potType: JackpotPotType,
  hot: boolean,
  lastUpdated: string,
}

export type JackpotGameData = {
  jackpotId: string,
  gameCodes: string[],
  pots: JackpotValue[];
}

export type JackpotsServerData = {
  jackpots: JackpotGameData[],
  lastUpdated: string
}

export type JackpotGameValue = {
  gameCode: string,
  jackpotValue: number,
}

export type JackpotTickerData = {
  id: string,
  gameCodes: string[],
  potType: JackpotPotType,
  isHot: boolean;
  realAmount: number,
  currentAmount: number,
  tickingActivated: boolean,
  animationRange: number,
  animationTime: number;
  animationInterval: number;
  animationStartAmount: number;
  animationStartTime: number;
  subject: BehaviorSubject<number>;
}

export type JackpotsState = {
  supportedGames: string[];
  tickerObservers: Map<string, Observable<number>>;
}

export enum JackpotPotType {
  PROGRESSIVE = 'PROGRESSIVE',
  DAILY = 'DAILY',
  HOURLY = 'HOURLY',
}
