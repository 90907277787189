import { useDispatch, useSelector, useStore } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { LocationObject, NavigationState, RootState } from '../interfaces';
import useNavigation from './useNavigation';
import { lobbyActions, searchActions } from '../store/actions';
import NavigationMethods from '../constants/navigation-methods';
import validateCategoryCode from '../utils/validateCategoryCode';
import eventBus, { eventTypes } from '../services/eventBus';

enum NavigationActionType {
  BACK_TO_PREVIOUSLY_OPENED_CASINO_CATEGORY,
  OTHER_PAGE_TO_CASINO_CATEGORY,
  CATEGORY_ADDED,
  CATEGORY_REMOVED,
  CATEGORY_SWITCHED,
  NO_ACTIONS
}
const URL_CATEGORY_TYPE = 'categoryType';
const URL_LICENCE = 'licence';

const isCategoryRemoved = (
  from?: LocationObject,
  to?: LocationObject,
) => from?.params[URL_CATEGORY_TYPE] && to?.params[URL_CATEGORY_TYPE] === undefined;

const isCategoryAdded = (
  from?: LocationObject,
  to?: LocationObject,
) => from?.params[URL_CATEGORY_TYPE] === undefined && to?.params[URL_CATEGORY_TYPE];

const isCategorySwitched = (
  from?: LocationObject,
  to?: LocationObject,
) => from?.params[URL_CATEGORY_TYPE] && to?.params[URL_CATEGORY_TYPE];

const isLicenceAdded = (
  from?: LocationObject,
  to?: LocationObject,
) => from?.params[URL_LICENCE] === undefined && to?.params[URL_LICENCE];

export default (): void => {
  const dispatch = useDispatch();
  const [initial, setInitial] = useState<boolean>(true);
  const store = useStore<RootState>();
  const expandedComponentId = useSelector((state: RootState) => state.lobby.expandedComponentId);
  const { addParamToUrl, removeParamFromUrl } = useNavigation();

  /**
   * Opens/expands lobby category, action was requested by external source (banners, widgetbar,
   * browser back button). If game search was opened then previously opened category needs to be
   * cleared, otherwise some URL parameter blinking is visible (WB search closed -> triggers
   * clearing category parameter from the url -> category code parameter is put back to url).
   * Requested category code needs to be validated because it came from external source and thus
   * cannot be trusted.
   */
  const handleExternalCategoryOpenRequest = useCallback((categoryCode: string, fromWB: boolean) => {
    if (store.getState().search.searchVisible) {
      dispatch(searchActions.setPreviousExpandedCategory(null));
      rsiApi.trigger(rsiApi.getEvent('CLOSE_WIDGET_BAR_SEARCH'));
    }

    const toCategory = validateCategoryCode(categoryCode) ? categoryCode : null;

    dispatch(lobbyActions.setExpandedCategory(toCategory, fromWB));
  }, [dispatch, store]);

  /**
   * Based on navigation URL changes there might be some actions that must be performed.
   */
  const getNavigationActionType = useCallback((
    from?: LocationObject,
    to?: LocationObject,
  ): NavigationActionType => {
    if (from && from.page !== 'all-games' && to?.page === 'all-games') {
      if (to?.params[URL_CATEGORY_TYPE]) {
        return NavigationActionType.OTHER_PAGE_TO_CASINO_CATEGORY;
      }
      return NavigationActionType.BACK_TO_PREVIOUSLY_OPENED_CASINO_CATEGORY;
    }

    if (to?.page === 'all-games') {
      if (isCategoryRemoved(from, to)) {
        return NavigationActionType.CATEGORY_REMOVED;
      }
      if (isCategoryAdded(from, to)) {
        return NavigationActionType.CATEGORY_ADDED;
      }
      if (isCategorySwitched(from, to)) {
        return NavigationActionType.CATEGORY_SWITCHED;
      }
      /*
        Triggered on PA cages, in this case current function is triggered only on param change,
        that happens AFTER switching between another page and casino.
      */
      if (isLicenceAdded(from, to)) {
        return NavigationActionType.BACK_TO_PREVIOUSLY_OPENED_CASINO_CATEGORY;
      }
    }

    return NavigationActionType.NO_ACTIONS;
  }, []);

  /**
   * Actions are based on the URL parameters and expandedComponentId info from the store.
   */
  const performNavigationAction = useCallback(({ from, to }: NavigationState) => {
    const actionType = getNavigationActionType(from, to);
    const toCategory = to?.params[URL_CATEGORY_TYPE];

    switch (actionType) {
      case NavigationActionType.CATEGORY_REMOVED: {
        dispatch(lobbyActions.setExpandedCategory(null));
        break;
      }
      case NavigationActionType.OTHER_PAGE_TO_CASINO_CATEGORY:
      case NavigationActionType.CATEGORY_ADDED:
      case NavigationActionType.CATEGORY_SWITCHED: {
        if (toCategory !== store.getState().lobby.expandedComponentId) {
          // external navigation (banner click), category changed
          handleExternalCategoryOpenRequest(toCategory, false);
        }
        break;
      }
      case NavigationActionType.BACK_TO_PREVIOUSLY_OPENED_CASINO_CATEGORY: {
        const expCategory = store.getState().lobby.expandedComponentId;
        if (expCategory) {
          addParamToUrl(URL_CATEGORY_TYPE, expCategory, NavigationMethods.REPLACE);
        }
        break;
      }
      default: {
        // no actions required
      }
    }
  }, [dispatch, store, addParamToUrl, getNavigationActionType, handleExternalCategoryOpenRequest]);

  /**
   * Adds or removes category type parameter in URL. Actions are ignored on initial page load
   * (store's initial value), otherwise page refresh will not work (specific category not opened).
   */
  useEffect((): void => {
    if (initial) {
      setInitial(false);
      return;
    }

    if (!expandedComponentId) {
      removeParamFromUrl(URL_CATEGORY_TYPE);
    } else {
      addParamToUrl(URL_CATEGORY_TYPE, expandedComponentId);
    }
  }, [dispatch, addParamToUrl, removeParamFromUrl, expandedComponentId, initial]);

  // widgetbar integration
  useEffect(() => {
    const subscription = eventBus.subscribe(
      eventTypes.CASINO_SET_EXPANDED_CATEGORY,
      (categoryKey: string) => {
        handleExternalCategoryOpenRequest(categoryKey, true);
      },
    );
    return ((): void => subscription.unsubscribe());
  }, [dispatch, handleExternalCategoryOpenRequest]);

  // general URL navigations
  useEffect(() => {
    const unsubscribe = RSINavigationHandler.subscribe(performNavigationAction);
    return ((): void => unsubscribe());
  }, [performNavigationAction]);
};
