/* eslint-disable import/prefer-default-export */
import { TileSize } from '../interfaces/TileSize';

export const colors = {
  black: '#000',
  lightBlue: '#2A64C5',
  blue: '#316094',
  blue2: '#274a77',
  blue3: '#270ee8',
  blueDark: '#091a34',
  blueDarker: '#1d355b',
  blueLight: '#00aeff',
  blueLight2: '#D6FDFD',
  blueGradientStart: '#2e66b1',
  blueGradientEnd: '#183562',
  gray: '#717881',
  gray2: '#f1f2f4',
  gray3: '#eee9dc',
  gray4: '#969ca2',
  grayBlue: '#eaeff5',
  grayBlueDark: '#e8eff7',
  grayBlueHover: 'rgba(49, 96, 148, .07)',
  grayBlueHoverHalf: 'rgba(49, 96, 148, .45)',
  grayDark: '#717881',
  grayDisabled: 'rgba(113, 120, 129, .3)',
  grayLoader: '#D3D3D3',
  grayLoaderHighlight: '#dedede',
  grayOpacity: 'rgba(232, 239, 247, .8)',
  gold: '#fdb61b',
  yellow: '#FFD000',
  green: '#51b100',
  orange: '#ff8800',
  red: '#F04040',
  purple: '#a638df',
  white: '#fff',
};

export const breakpoints = {
  'screen-xs-min': 480,
  'screen-sm-min': 768,
  'screen-md-min': 992,
  'screen-lg-min': 1200,

  'screen-xxs-max': 679,
  'screen-xs-max': 767,
  'screen-sm-max': 991,
  'screen-md-max': 1199,
};

export const verticalBreakpoints = {
  'screen-xs-max': 360,
};

export const fixedSizes = {
  bottomNavHeight: '65px',
  contentMaxWidth: 1620,
  headerHeight: '105px',
  headerHeightLowerOnly: '60px',
  smallThumbnailWidth: 141,
};

export const paddings = {
  pagePadding: 16,
  expCategoryGameTileGap: 16,
  gameTileGapWidth: new Map<TileSize, number>([
    [TileSize.S, 12],
    [TileSize.M, 16],
    [TileSize.L, 20],
  ]),
  // This fixed gap will be temporal until cx team defines how should we handle it
  multiRowFixedGap: 12,
};
