import useRsiApi from '../hooks/useRsiApi';
import useSearch from '../hooks/useSearch';
import useAuth from '../hooks/useAuth';
import useLayout from '../hooks/useLayout';
import useWidth from '../hooks/useWidth';
import useQuickLaunch from '../hooks/useQuickLaunch';
import useGameListUpdate from '../hooks/useGameListUpdate';
import useJackpotsManager from '../hooks/useJackpotsManager';
import useCustomImagesConf from '../hooks/useCustomImagesConf';
import useCategoryUrlParam from '../hooks/useCategoryUrlParam';
import useGamingMode from '../hooks/useGamingMode';

interface GlobalHooksProperties {
  lobbyId: string;
}

/**
 * Most of the custom hooks are functional that just dispatch some events and some use useSelect
 * redux hook (easy to use). That useSelect triggers re-rendering. All these hooks are gathered
 * here with 0 visual component to avoid unnecessary rendering.
 */
function GlobalHooksContainer({ lobbyId }: GlobalHooksProperties) {
  useRsiApi();
  useSearch();
  useAuth();
  useLayout();
  useGamingMode();
  useJackpotsManager();
  useCategoryUrlParam();
  useGameListUpdate(lobbyId);
  useQuickLaunch();
  useWidth();
  useCustomImagesConf();

  return null;
}

export default GlobalHooksContainer;
