import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

interface PictureTagProperties {
  className?: string;
  skeletonHeight?: number;
  hasSkeleton: boolean;
  handleImageLoaded: VoidFunction;
}

function PictureTag({
  children,
  className,
  skeletonHeight,
  hasSkeleton,
  handleImageLoaded,
}: PropsWithChildren<PictureTagProperties>) {
  return (
    <Picture
      className={className}
      onLoad={handleImageLoaded}
      hasSkeleton={hasSkeleton}
      skeletonHeight={skeletonHeight}
    >
      {children}
    </Picture>
  );
}

PictureTag.defaultProps = {
  className: '',
  skeletonHeight: undefined,
};

const Picture = styled.picture.attrs((props: {
  skeletonHeight: number; hasSkeleton: boolean;
}) => props)`
  ${({ skeletonHeight, hasSkeleton }) => skeletonHeight && hasSkeleton && css`
    :last-child:not(:first-child) {
      height: ${skeletonHeight}px;
    }
  `};
`;

export default PictureTag;
