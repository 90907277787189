import React from 'react';
import styled from 'styled-components';
import { GameImage } from '../../../../interfaces';
import Picture from '../../../rsi/Image/Picture';
import useClientRect from '../../../../hooks/useClientRect';

interface ImageCarouselMobileProperties {
  className?: string;
  code: string;
  images: GameImage[];
}

// eslint-disable-next-line max-len
function ImageCarouselMobileComponent({
  className,
  images,
  code,
}: ImageCarouselMobileProperties) {
  const [rect, ref] = useClientRect();
  return (
    <div ref={ref} className={`${className} ${code}`}>
      <ImagesContainer>
        {images.map((image) => (
          <Picture
            className={className}
            gameCode={image.gameCode}
            containerRect={rect}
            isFavoriteOrRecentRolledUp={false}
          >
            <Image
              loading="lazy"
              alt={image.gameName}
            />
          </Picture>
        ))}
      </ImagesContainer>
    </div>
  );
}

ImageCarouselMobileComponent.defaultProps = {
  className: undefined,
};

const ImageCarouselMobile = styled(ImageCarouselMobileComponent)`
  display: flex;
  justify-content: center;
  -ms-overflow-style: none;
  width: 100%;
  height: 100%;
  scrollbar-width: none;
  scroll-behavior: smooth;
  text-align: center;

  @media only screen and (orientation: landscape) {
    padding: 0;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: max-content;
  max-width: 100%;
  height: 100%;
  margin: 0;
`;

const Image = styled.img`
  border-radius: 14px;
  max-width: 100%;
  margin: 0;
`;

export default ImageCarouselMobile;
