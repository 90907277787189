export type CustomGameImage = {
  gameCode: string,
  imageName: string,
  source: ImageSource
}

export type GameImage = {
  gameName: string;
  gameCode: string;
}

export enum ImageSource {
  S3 = 'S3',
  CMS = 'CMS',
}
