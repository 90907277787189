import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import axios from 'axios';
// @ts-ignore
import { BehaviorSubject } from 'rxjs';
import {
  JackpotGameData,
  JackpotsServerData,
  JackpotTickerData,
  JackpotPotType,
  JackpotValue,
  NavigationState,
  RootState,
} from '../interfaces';
import { jackpotsActions } from '../store/actions';
import { logger } from '../services';
import { DeviceType } from '../interfaces/DeviceType';

const jpOptions = {
  enabled: true,
  pollInterval: 30000,
  interval: {
    desktop: 400,
    mobile: 400,
  },
};
const animDuration: number = jpOptions.pollInterval + 5000; // 5s buffer for new WSS to come in
const tickersMap: Map<string, JackpotTickerData> = new Map();
let lastJpUpdateTime: number;
let pollTickerId: any;
let wssConnected: boolean;
let pageHidden: boolean = document.hidden;
let isCasinoPage = RSINavigationHandler.getCurrentPage() === 'all-games';

const createTickerId = (
  jackpotData: JackpotGameData,
  pot: JackpotValue,
) => jackpotData.jackpotId + pot.potType;

export const findTickerByGameCode = (
  gameCode: string,
  potType: JackpotPotType,
) => {
  const tickersArray = Array.from(tickersMap.values());
  const ticker = tickersArray.find(
    (jackpotTickerData) => jackpotTickerData.gameCodes
      .includes(gameCode) && jackpotTickerData.potType === potType,
  );
  return ticker;
};

/**
 * Jackpot ticker manager. The first JP data is fetched from server on initialization. The rest
 * come in either via WSS or by polling server (if WSS is down). Ticking animation starts when the
 * second JP info comes in. There's no ticking animation while game is opened or browser hidden.
 *
 * Future improvements:
 * - jp options configurable from CMS/BO
 * - start ticking animation only for those tickers that have registered observers
 * - start ticking animation from the first event (subtract 100 cents or 1% from the real amount)
 * - try to use IntersectionObserver for detecting if ticking must be running instead of listening
 * api events about game opened/closed.
 */
/* eslint no-param-reassign: "error" */

export default (): void => {
  const dispatch = useDispatch();
  const tickerObservers = useSelector((state: RootState) => (state.jackpots.tickerObservers));
  const deviceType = useSelector((state: RootState) => (state.application.deviceType));
  const gameOpened = useSelector((state: RootState) => (state.gamingMode.gameOpened));
  const tickInterval: number = (deviceType === DeviceType.Desktop)
    ? jpOptions.interval.desktop : jpOptions.interval.mobile;

  /**
   * Checks the JP data time, if older than the latest saved then the update will be ignored.
   */
  const checkAndUpdateJpTime = (time: string): boolean => {
    if (!time) {
      return true;
    }
    const timeMS: number = new Date(time).getTime();
    if (!lastJpUpdateTime) {
      lastJpUpdateTime = timeMS;
      return true;
    }

    return lastJpUpdateTime < timeMS;
  };

  // ticking animation: slows down when getting close to target amount
  // for linear: change * (time / duration) + begin
  const easeOutSine = (time: number, begin: number, change: number, duration: number): number => (
    change * Math.sin((time / duration) * (Math.PI / 2)) + begin
  );

  const handleJpTick = useCallback((jackpotData: JackpotTickerData): void => {
    if (!jackpotData.tickingActivated) {
      return;
    }
    jackpotData.animationTime += jackpotData.animationInterval;
    if (jackpotData.animationTime >= animDuration) {
      jackpotData.currentAmount = jackpotData.realAmount;
    } else {
      jackpotData.currentAmount = Math.round(
        easeOutSine(
          jackpotData.animationTime,
          jackpotData.animationStartAmount,
          jackpotData.animationRange,
          animDuration,
        ),
      );
    }

    if (jackpotData.currentAmount < jackpotData.realAmount) {
      setTimeout(() => handleJpTick(jackpotData), jackpotData.animationInterval);
    } else {
      // real amount reached, no visible ticking until new JP update comes in
      jackpotData.currentAmount = jackpotData.realAmount;
      jackpotData.tickingActivated = false;
    }

    if (jackpotData.subject.value !== jackpotData.currentAmount) {
      jackpotData.subject.next(jackpotData.currentAmount);
    }
  }, []);

  /**
   * Starts/resumes JP ticker animation based on elapsed time passed since last JP update (start
   * of last animation cycle) but only if
   *  - page is not hidden or game is not opened
   *  - ticker animation is not already running
   */
  const activateJpTicking = useCallback((): void => {
    if (pageHidden || gameOpened || !isCasinoPage) {
      return;
    }
    tickersMap.forEach((jpData) => {
      if (jpData.tickingActivated) {
        return;
      }
      jpData.animationTime = (Date.now() - jpData.animationStartTime);
      jpData.tickingActivated = true;
      handleJpTick(jpData);
    });
  }, [handleJpTick, gameOpened]);

  const restartAnimationCycle = (jpTicker: JackpotTickerData): void => {
    jpTicker.animationRange = Math.floor(jpTicker.realAmount - jpTicker.currentAmount);
    jpTicker.animationTime = 0;
    jpTicker.animationStartAmount = jpTicker.currentAmount;
    jpTicker.animationStartTime = Date.now();
  };

  const createTickersData = useCallback((
    jackpotData: JackpotGameData,
  ): JackpotTickerData[] => {
    const jackpotTickers: JackpotTickerData[] = jackpotData.pots.map((pot) => ({
      id: createTickerId(jackpotData, pot),
      gameCodes: jackpotData.gameCodes,
      potType: pot.potType,
      realAmount: pot.amount,
      currentAmount: pot.amount,
      isHot: pot.hot,
      tickingActivated: false,
      animationInterval: tickInterval,
      animationTime: 0,
      animationStartAmount: pot.amount,
      animationRange: 0,
      animationStartTime: 0,
      subject: new BehaviorSubject<number>(pot.amount),
    }));

    jackpotTickers.forEach((ticker) => {
      tickersMap.set(ticker.id, ticker);
      tickerObservers.set(ticker.id, ticker.subject.asObservable());
    });

    return jackpotTickers;
  }, [tickInterval, tickerObservers]);

  const updateGameJpTickerData = useCallback((
    jackpotData: JackpotGameData,
    tickerId: string,
  ): void => {
    const jpTicker: JackpotTickerData | undefined = tickersMap.get(tickerId);
    const updateTicker = (ticker: JackpotTickerData) => {
      const jackpotDataPotTypeIndex = jackpotData.pots.findIndex(
        (pot) => pot.potType === ticker.potType,
      );
      if (jackpotDataPotTypeIndex !== -1) {
        ticker.realAmount = jackpotData.pots[jackpotDataPotTypeIndex].amount;
        if (ticker.currentAmount > ticker.realAmount) {
          ticker.currentAmount = ticker.realAmount;
        }
        restartAnimationCycle(ticker);
      }
    };

    if (!jpTicker) {
      const tickers = createTickersData(jackpotData);
      tickers.forEach((ticker) => {
        updateTicker(ticker);
      });
      return;
    }
    updateTicker(jpTicker);
  }, [createTickersData]);

  /**
   * Handles JP update messages.
   * - new JP real amount (may-be increased or decreased)
   * - update of supported games
   */
  const jackpotPushMessage = useCallback((data: JackpotsServerData): void => {
    if (!checkAndUpdateJpTime(data.lastUpdated)) {
      return;
    }

    data.jackpots.forEach((jackpotData) => {
      jackpotData.pots.forEach((pot) => {
        updateGameJpTickerData(jackpotData, createTickerId(jackpotData, pot));
      });
    });

    activateJpTicking();

    const supportedGames: string[] = [];

    data.jackpots.forEach((jackpot) => {
      supportedGames.push(...jackpot.gameCodes);
    });

    dispatch((jackpotsActions.setSupportedGames(supportedGames)));
  }, [activateJpTicking, dispatch, updateGameJpTickerData]);

  const stopJpTicking = useCallback((): void => {
    tickersMap.forEach((jpData) => {
      jpData.tickingActivated = false;
    });
  }, []);

  const handleVisibilityChange = useCallback(() => {
    if (document.hidden) {
      pageHidden = true;
      stopJpTicking();
    } else {
      pageHidden = false;
      activateJpTicking();
    }
  }, [activateJpTicking, stopJpTicking]);

  const clearPollTicker = (): void => {
    if (pollTickerId) {
      clearTimeout(pollTickerId);
      pollTickerId = null;
    }
  };

  const wssStatusCheck = useCallback((timeOutFunction?: any): void => {
    wssConnected = rsiApi.getPushService().isConnected();
    if (!wssConnected) {
      if (pollTickerId || !timeOutFunction) {
        return;
      }
      pollTickerId = setTimeout(timeOutFunction, jpOptions.pollInterval);
    } else {
      clearPollTicker();
    }
  }, []);

  /**
   * Fetches JP info from server, used when initializing JP manager and also when WSS is not
   * available. After a fetch another poll is scheduled if WSS is still down.
   */
  const fetchJpData = useCallback(async () => {
    try {
      const { apiUrl, cageCode } = RSIConfigHandler.getConfigs().api.data;
      const response = await axios.get(`${apiUrl}service/jackpot/v2/info`, {
        params: { cageCode },
      });
      jackpotPushMessage(response.data);
    } catch (err) {
      let errorMsg = '';
      if (err instanceof Error) {
        errorMsg = err.message;
      }
      logger.error('Failed to receive jackpots', { error: errorMsg });
    } finally {
      clearPollTicker();
      wssStatusCheck(fetchJpData);
    }
  }, [jackpotPushMessage, wssStatusCheck]);

  const initializeListeners = useCallback(
    (): void => {
      rsiApi.getPushService().on('JACKPOTS_UPDATE', jackpotPushMessage);
      rsiApi.on([
        rsiApi.getEvent('WSS_SERVICE_DOWN'),
        rsiApi.getEvent('WSS_SERVICE_UP'),
      ].join(' '), wssStatusCheck(fetchJpData));

      document.addEventListener('visibilitychange', handleVisibilityChange, false);
    },
    [fetchJpData, handleVisibilityChange, jackpotPushMessage, wssStatusCheck],
  );

  const clearListeners = useCallback(
    (): void => {
      rsiApi.getPushService().off('JACKPOTS_UPDATE', jackpotPushMessage);
      rsiApi.off([
        rsiApi.getEvent('WSS_SERVICE_DOWN'),
        rsiApi.getEvent('WSS_SERVICE_UP'),
      ].join(' '), wssStatusCheck(fetchJpData));
      document.removeEventListener('visibilitychange', handleVisibilityChange, false);
    },
    [fetchJpData, handleVisibilityChange, jackpotPushMessage, wssStatusCheck],
  );

  useEffect(() => {
    if (gameOpened) {
      stopJpTicking();
    } else {
      activateJpTicking();
    }
  }, [gameOpened, stopJpTicking, activateJpTicking]);

  const performRedirect = useCallback(
    ({ to }: NavigationState) => {
      if (to?.page !== 'all-games') {
        isCasinoPage = false;
        stopJpTicking();
      } else {
        isCasinoPage = true;
        activateJpTicking();
      }
    },
    [activateJpTicking, stopJpTicking],
  );

  useEffect(() => {
    const unsubscribe = RSINavigationHandler.subscribe(performRedirect);
    return ((): void => unsubscribe());
  }, [performRedirect]);

  useEffect(() => {
    if (!jpOptions.enabled || !tickerObservers || !deviceType) {
      return () => {};
    }
    initializeListeners();
    fetchJpData();

    return () => {
      clearListeners();
      clearPollTicker();
      stopJpTicking();
    };
  }, [
    tickerObservers,
    deviceType,
    initializeListeners,
    fetchJpData,
    clearListeners,
    stopJpTicking,
  ]);
};
