import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { GameItemsList, RootState } from '../../interfaces';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import ExpandedCategoryGamesContainer from './ExpandedGameCategory/ExpandedCategoryGamesContainer';
import useGameListUpdateThrottle from '../../hooks/useGameListUpdateThrottle';

interface InfScrollProperties {
  fetchHandler: Function;
  selectorHandler: Function;
  sourceType?: string;
  dataTestId?: string;
  scrollToTop?: boolean;
}

/**
 * Component for infinitely scrolling games as long all have been fetched. The page will be scrolled
 * to top when this component comes visible.
 * @param fetchHandler callback function for fetching another set of games, must accept one
 * {page: number} parameter
 * @param selectorHandler callback function used in useSelector for watching games list changes
 * @param sourceType source type of the expanded category
 */
function InfiniteScrollGames({
  fetchHandler,
  selectorHandler,
  sourceType,
  dataTestId,
  scrollToTop,
}: InfScrollProperties) {
  const games: GameItemsList = useSelector((state: RootState) => selectorHandler(state));
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const allFetched = useRef<boolean>(false);
  const currentPage = useRef<number>(games ? 1 : 0);
  const ref = useRef<HTMLDivElement | null>(null);
  const isIntersectingRef = useRef<boolean>(false);
  const checkAllFetched = useCallback((gamesList: GameItemsList) => (
    gamesList !== null && gamesList.items.length >= gamesList.paging.totalElements
  ), []);
  const intersectionHandler = useCallback((isIntersecting: boolean) => {
    isIntersectingRef.current = isIntersecting;
    if (isIntersecting && !checkAllFetched(games)) {
      currentPage.current += 1;
      fetchHandler(currentPage.current);
    }
  }, [fetchHandler, games, checkAllFetched]);

  const resetRefValues = useCallback(() => {
    allFetched.current = false;
    currentPage.current = games?.paging?.page ?? 1;
  }, [games?.paging?.page]);

  useGameListUpdateThrottle(resetRefValues);

  useIntersectionObserver(ref, intersectionHandler);

  useEffect(() => {
    if (scrollToTop) {
      window.scrollTo(0, 0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    rsiApi.getPushService().on('GAME_CATEGORY_CHANGED', resetRefValues);
    return () => {
      rsiApi.getPushService().off('GAME_CATEGORY_CHANGED', resetRefValues);
    };
  }, [resetRefValues]);

  useEffect(() => {
    resetRefValues();
  }, [resetRefValues, isLoggedIn]);

  useEffect(() => {
    if (games === null) {
      allFetched.current = false;
      currentPage.current = 0;
      return () => {
      };
    }

    if (checkAllFetched(games)) {
      allFetched.current = true;
      return () => {
      };
    }
    const timeoutId = setTimeout(() => {
      if (isIntersectingRef.current) {
        intersectionHandler(true);
      }
    }, 300);
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [games, checkAllFetched, intersectionHandler]);

  return (
    <>
      <ExpandedCategoryGamesContainer
        expandedCategoryGames={games}
        sourceType={sourceType}
        dataTestId={dataTestId}
      />
      {!checkAllFetched(games) && <div ref={ref} />}
    </>
  );
}

InfiniteScrollGames.defaultProps = {
  sourceType: undefined,
  dataTestId: 'infinite-scroll-games',
  scrollToTop: undefined,
};

export default InfiniteScrollGames;
