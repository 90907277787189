import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

interface IconButtonProperties {
  className?: string;
  translationKey?: string;
  onClick?: any;
  disabled?: boolean;
}

function IconButtonComponent({
  onClick, className, translationKey, disabled,
}: IconButtonProperties) {
  return (
    <button type="button" disabled={disabled} className={className} onClick={onClick}>
      <FormattedMessage id={translationKey} />
    </button>
  );
}

IconButtonComponent.defaultProps = {
  className: 'icon-button',
  translationKey: '',
  disabled: false,
  onClick: () => {},
};

const TextButton = styled(IconButtonComponent)`
  padding: 0;
`;

export default TextButton;
